import { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Box, Grid, Button } from '@mui/material';
import { useSelector } from 'react-redux';

import { selectUser } from '../../features/auth/authSlice';
import { IProjectCardDetails } from '../../types/project';
import ProjectCard from './ProjectCard';
import { FlexBetween } from '../../commonStyles';
import { useAppDispatch } from '../../app/hooks';
import { reset } from '../../features/project/projectSlice';

function Portal(props: any) {
  const storeDispatch = useAppDispatch();
  const [projects, setProjects] = useState<any[]>([]);
  const user = useSelector(selectUser);

  useEffect(() => {
    storeDispatch(reset());
  }, [storeDispatch]);

  useQuery(PROJECTS, {
    skip: !user,
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      let sort = [...data.getWorksheetCount].sort(
        (a: any, b: any) =>
          b.acceptedCount - a.acceptedCount ||
          b.pendingCount - a.pendingCount ||
          b.taskCount - a.taskCount ||
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      );
      setProjects(sort);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  return (
    <Box
      sx={{
        backgroundColor: '#00142a',
        padding: '10px',
      }}
    >
      <Box
        py={5}
        sx={{
          flexGrow: 1,
          maxWidth: '1140px',
          margin: '0 auto',
          minHeight: '100vh',
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            margin: 0,
            width: '100%',
          }}
        >
          {projects.length > 0
            ? projects.map((project: IProjectCardDetails, index: number) => {
                return <ProjectCard project={project} key={index} />;
              })
            : null}
        </Grid>
      </Box>
      <FlexBetween sx={{ mb: 2 }}>
        {user?.fullAccess ? (
          <Box>
            <Link
              style={{
                color: '#fff',
                textDecoration: 'none',
              }}
              to="/manager"
            >
              <Button color="secondary" variant={'contained'}>
                Manager Access
              </Button>
            </Link>
          </Box>
        ) : null}
        {user?.type !== 'Developer' ? (
          <Box>
            <Link
              style={{
                color: '#fff',
                textDecoration: 'none',
              }}
              to="/create-project"
            >
              <Button color="secondary" variant={'contained'}>
                Create Project
              </Button>
            </Link>
          </Box>
        ) : null}
      </FlexBetween>
    </Box>
  );
}

const PROJECTS = gql`
  query getWorksheetCount {
    getWorksheetCount {
      _id
      name
      imageGetUrl
      acceptedCount
      pendingCount
      taskCount
      createdAt
    }
  }
`;

export default Portal;
