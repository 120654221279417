import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { Box, Grid, Divider, Typography, Button } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import Dropzone, { useDropzone } from 'react-dropzone';
import PdfCard from '../common/PdfCard';
import { Container, Flex, FlexEnd } from '../../commonStyles';
import { useAppDispatch } from '../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import Dropdown from '../common/formControls/Dropdown';
import TextInput from '../common/formControls/TextInput';
import { convertAllDates, numToCurrency } from '../../utils/Functions';
import { baseStyle, activeStyle, acceptStyle, rejectStyle, soldStatuses } from '../../utils/Constants';
import { selectUser } from '../../features/auth/authSlice';
import { selectProject } from '../../features/project/projectSlice';
import { IUnit } from '../../types/unit';
import { IWorksheet, IWorksheetById } from '../../types/worksheet';
import LoadingWrapper from '../common/LoadingWrapper';
import { IDealOption } from '../../types/CreateDealForm';
import { IOption } from '../../types/project';
import { ICoopRate } from '../../types/project';
import Commission from '../createDeal/createDealSteps/realtor/Commission';

const WorksheetDetails = () => {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  const navigate = useNavigate();
  const { worksheetid } = useParams();
  const [selectedWorksheet, setSelectedWorksheet] = useState<IWorksheet | null>(null);
  const [worksheets, setWorksheets] = useState<IWorksheet[]>([]);
  const [previousUnit, setPreviousUnit] = useState<IUnit | null>(null);
  const [availableUnits, setAvailableUnits] = useState<IUnit[]>([]);
  const [coopRates, setCoopRates] = useState<ICoopRate[]>(selectedWorksheet ? selectedWorksheet.coop : []);

  const { loading } = useQuery<IWorksheetById>(GETWORKSHEET, {
    variables: { _id: worksheetid },
    onCompleted: (data) => {
      setCoopRates(data.worksheetById.coop);
      setSelectedWorksheet(data.worksheetById);
    },
  });

  useQuery<IWorksheetById>(GETWORKSHEET, {
    variables: { search: '', project: project._id, unit: selectedWorksheet?.unit, page: 1, perPage: 10000 },
    onCompleted: (data) => {},
  });

  const [getUnits] = useLazyQuery(UNITS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAvailableUnits(data.unitMany);
    },
  });

  const [uploadCheque] = useMutation(UPLOADCHEQUE, {
    onCompleted: (data) => {},
    onError: (error) => {
      console.log(error);
    },
  });

  const [updateArchive] = useMutation(UPDATEWORKSHEET, {
    onCompleted: (data) => {
      if (data.worksheetUpdateById.record.status === 'archived') {
        storeDispatch(showSuccessSnackbar(`Worksheet has been archived`));
      } else {
        storeDispatch(showSuccessSnackbar(`Worksheet has been restored`));
      }
      navigate(-1);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateUnit] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateWorksheet] = useMutation(UPDATEWORKSHEET, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    if (selectedWorksheet?.realtor) {
      getUnits({
        variables: {
          filter: {
            project: project._id,
            OR: [
              { status: 'SHL' },
              { status: 'HL' },
              { status: 'HL2' },
              { status: 'HL3' },
              { status: 'A' },
              { status: 'CH' },
              { status: 'M' },
              { status: 'PL' },
              {
                AND: [
                  {
                    allocation: selectedWorksheet?.realtor._id,
                  },
                  { status: 'D' },
                ],
              },
            ],
          },
        },
      });
    } else {
      getUnits({
        variables: {
          filter: {
            project: project._id,
            OR: [
              { status: 'SHL' },
              { status: 'HL' },
              { status: 'HL2' },
              { status: 'HL3' },
              { status: 'A' },
              { status: 'CH' },
              { status: 'M' },
              { status: 'PL' },
            ],
          },
        },
      });
    }
  }, [selectedWorksheet]);

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const file = acceptedFiles[0];
    const fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file);
    }
    fileReader.onloadend = async () => {
      setSelectedWorksheet({
        ...selectedWorksheet!,
        chequeImages: [
          ...selectedWorksheet?.chequeImages!,
          {
            getUrl: fileReader.result,
            file: file,
            name: file.name,
          },
        ],
      });
    };
  };

  const handleTextInput = (event: any) => {
    if (selectedWorksheet) {
      let updateWorksheetPrice = {
        ...selectedWorksheet,
        [event.target.name]: event.target.name === 'worksheetBasePrice' ? parseFloat(event.target.value) : event.target.value,
      };
      setSelectedWorksheet(updateWorksheetPrice);
    }
  };

  const handleDelete = (numIndex: number, file: any) => {
    if (file) {
      let chequeImages = selectedWorksheet?.chequeImages!.filter((chequeImages: any, index: number) => numIndex !== index);
      setSelectedWorksheet({
        ...selectedWorksheet!,
        chequeImages: chequeImages!,
      });
    } else {
      storeDispatch(showErrorSnackbar(`Cannot Delete`));
    }
  };

  const availableSuiteNames = () => {
    let suite = availableUnits.map((unit: IUnit, index: number) => {
      return `${unit.suite} - ${unit.status}`;
    });
    return suite;
  };

  const handleUnitDropdown = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    let selectedUnit: any = availableUnits.find((selectedUnit: IUnit) => {
      return `${selectedUnit.suite} - ${selectedUnit.status}` === event.target.value;
    });

    if (selectedWorksheet && selectedUnit) {
      let updateWorksheetUnit = {
        ...selectedWorksheet,
        unit: selectedUnit,
        worksheetBasePrice: selectedUnit.basePrice,
        notes: `${selectedWorksheet.notes} ++ Purchaser has switched from ${
          selectedWorksheet.unit ? selectedWorksheet.unit.suite : 'Wishlist'
        } to ${selectedUnit.suite}. ${
          selectedWorksheet.unit ? numToCurrency.format(selectedWorksheet.unit.basePrice) : 'Wishlist'
        } to ${numToCurrency.format(selectedUnit.basePrice)}`,
      };

      if (selectedWorksheet.unit) {
        setPreviousUnit(selectedWorksheet.unit);
      }
      setSelectedWorksheet(updateWorksheetUnit);
    }
  };

  const optionValue = (name: string, type: string) => {
    let filteredOption: any = selectedWorksheet?.options!.filter((option: IDealOption, index: number) => option.name === name);
    if (filteredOption.length > 0) {
      return filteredOption[0][type];
    } else {
      return 0;
    }
  };

  const handleOptionDropdown = (event: any, numIndex: number) => {
    if (selectedWorksheet) {
      let filteredOption: any = project.options.filter((option: IDealOption, index: number) => option.name === event.target.name);
      if (selectedWorksheet.options.some((option: IDealOption) => option.name === event.target.name)) {
        if (event.target.value == 0) {
          let removeOption = selectedWorksheet.options.filter((data: IDealOption, index: number) => data.name !== event.target.name);
          setSelectedWorksheet({
            ...selectedWorksheet,
            options: removeOption,
          });
        } else {
          let optionsArray = selectedWorksheet.options.map((option: IDealOption, index: number) => {
            if (option.name === event.target.name) {
              return { ...option, purchaseAmount: parseFloat(event.target.value), amount: event.target.value * filteredOption[0].price };
            } else return option;
          });
          setSelectedWorksheet({
            ...selectedWorksheet,
            options: optionsArray,
          });
        }
      } else {
        if (project.options) {
          setSelectedWorksheet({
            ...selectedWorksheet,
            options: [
              ...selectedWorksheet.options,
              {
                name: event.target.name,
                purchaseAmount: parseFloat(event.target.value),
                amount: event.target.value * filteredOption[0].price,
              },
            ],
          });
        } else return;
      }
    }
  };

  const handleOptionInput = (event: any, optionName: string) => {
    if (selectedWorksheet) {
      let optionsArray = selectedWorksheet.options.map((option: IDealOption, index: number) => {
        if (option.name === optionName) {
          return {
            ...option,
            amount: parseFloat(event.target.value),
          };
        } else {
          return option;
        }
      });
      setSelectedWorksheet({
        ...selectedWorksheet,
        options: optionsArray,
      });
    }
  };

  const showSuccess = () => {
    if (selectedWorksheet! && user.type === 'Manager') {
      if (selectedWorksheet.managerApproval && selectedWorksheet.unit._id && selectedWorksheet.status === 'accepted') {
        return 'Unapprove Worksheet';
      } else {
        if (selectedWorksheet.status === 'archived') {
          return 'Unarchive';
        } else if (!selectedWorksheet.managerApproval && selectedWorksheet.unit && user.type === 'Manager') {
          return 'Save and Approve Worksheet';
        } else return null;
      }
    } else return null;
  };

  // Buttons
  const handleCloseSuccess = () => {
    if (!selectedWorksheet?.managerApproval) {
      if (selectedWorksheet?.unit) {
        let approvedWorksheet = worksheets.find(
          (worksheet: IWorksheet) => worksheet.unit.suite === selectedWorksheet?.unit.suite && worksheet.managerApproval
        );
        if (approvedWorksheet) {
          storeDispatch(showErrorSnackbar(`Another worksheet has already been approved for this unit`));
          return;
        }
        updateWorksheet({
          variables: {
            _id: selectedWorksheet?._id,
            record: {
              unit: selectedWorksheet?.unit._id,
              managerApproval: { user: user._id },
              worksheetBasePrice: selectedWorksheet?.worksheetBasePrice,
              notes: selectedWorksheet?.notes,
              status: 'accepted',
              options: selectedWorksheet.options,
              deal: null,
              coop: selectedWorksheet.coop,
            },
          },
        }).then((res) => {
          if (res.data.worksheetUpdateById.record.status === 'accepted') {
            if (res.data.worksheetUpdateById.record.unit.worksheets.length === 0) {
              updateUnit({
                variables: {
                  _id: res.data.worksheetUpdateById.record.unit._id,
                  record: {
                    status: soldStatuses.includes(selectedWorksheet?.unit?.status!) ? selectedWorksheet?.unit.status : 'WA',
                    worksheets: [res.data.worksheetUpdateById.record._id],
                  },
                },
              });
            } else {
              updateUnit({ variables: { _id: res.data.worksheetUpdateById.record.unit._id, record: { status: 'WA' } } });
            }
          }
          navigate(-1);
          storeDispatch(showSuccessSnackbar('Worksheet Approved!'));
        });
      } else {
        storeDispatch(showErrorSnackbar(`Select a Unit to Approve`));
        return;
      }
    } else {
      updateWorksheet({
        variables: { _id: selectedWorksheet?._id, record: { managerApproval: null, status: 'pending' } },
      }).then((res) => {
        if (res.data.worksheetUpdateById.record.status === 'pending') {
          updateUnit({
            variables: {
              _id: res.data.worksheetUpdateById.record.unit._id,
              record: { status: soldStatuses.includes(selectedWorksheet?.unit?.status!) ? selectedWorksheet?.unit.status : 'W' },
            },
          });
        }
        navigate(-1);
        storeDispatch(showSuccessSnackbar('Worksheet Unapproved!'));
      });
    }
  };

  const handleCloseSecond = () => {
    updateWorksheet({
      variables: {
        _id: selectedWorksheet?._id,
        record: {
          notes: selectedWorksheet?.notes,
          worksheetBasePrice: selectedWorksheet?.worksheetBasePrice,
          unit: selectedWorksheet?.unit ? selectedWorksheet?.unit._id : null,
          options: selectedWorksheet?.options,
          coop: coopRates,
        },
      },
    }).then((res) => {
      // Check if previous unit turning from has any worksheets. Change it to M
      if (previousUnit) {
        updateUnit({
          variables: {
            _id: previousUnit._id,
            record: {
              status: 'A',
              worksheets: previousUnit?.worksheets
                .map((worksheet: IWorksheet) => worksheet._id)
                .filter((worksheet: any) => worksheet !== selectedWorksheet?._id),
            },
          },
        });
      }
      if (selectedWorksheet?.chequeImages.length) {
        selectedWorksheet?.chequeImages.forEach(async (file: any) => {
          if (file.file) {
            await uploadCheque({ variables: { id: selectedWorksheet?._id, name: file.name, file: file.file } });
          }
        });
      }
      let updatedWorksheets = res.data.worksheetUpdateById.record.unit.worksheets.map((worksheet: any) => worksheet._id);
      updateUnit({
        variables: {
          _id: res.data.worksheetUpdateById.record.unit._id,
          record: { worksheets: [...updatedWorksheets, selectedWorksheet?._id] },
        },
      });
      storeDispatch(showSuccessSnackbar('Worksheet Updated!'));
      navigate(-1);
    });
  };

  const handleCloseThird = () => {
    updateArchive({
      variables: {
        _id: selectedWorksheet?._id,
        record: { status: 'archived', unit: selectedWorksheet?.unit ? selectedWorksheet?.unit._id : null },
      },
    }).then((res) => {
      if (res.data.worksheetUpdateById.record.status === 'archived') {
        let removeWorksheetFromUnit = selectedWorksheet?.unit.worksheets
          .filter((worksheet: any) => worksheet._id.toString() !== selectedWorksheet._id.toString())
          .map((worksheet: any) => worksheet._id);
        if (res.data.worksheetUpdateById.record.unit.allocation && res.data.worksheetUpdateById.record.managerApproval) {
          updateUnit({
            variables: {
              _id: res.data.worksheetUpdateById.record.unit._id,
              record: {
                worksheets: removeWorksheetFromUnit,
                status: soldStatuses.includes(selectedWorksheet?.unit?.status!)
                  ? selectedWorksheet?.unit.status
                  : res.data.worksheetUpdateById.record.unit.allocation
                  ? 'D'
                  : 'A',
              },
            },
          });
        } else if (res.data.worksheetUpdateById.record.unit.allocation && !res.data.worksheetUpdateById.record.managerApproval) {
          updateUnit({
            variables: {
              _id: res.data.worksheetUpdateById.record.unit._id,
              record: {
                worksheets: removeWorksheetFromUnit,
                status: soldStatuses.includes(selectedWorksheet?.unit?.status!) ? selectedWorksheet?.unit.status : 'D',
              },
            },
          });
        } else {
          updateUnit({ variables: { _id: res.data.worksheetUpdateById.record.unit._id, record: { worksheets: removeWorksheetFromUnit } } });
        }
      }
    });
  };

  const handleCloseFourth = () => {
    if (selectedWorksheet) {
      if (selectedWorksheet.managerApproval && selectedWorksheet.status === 'accepted' && selectedWorksheet.unit) {
        updateWorksheet({
          variables: {
            _id: selectedWorksheet?._id,
            record: {
              worksheetBasePrice: selectedWorksheet?.worksheetBasePrice,
              notes: selectedWorksheet?.notes,
              options: selectedWorksheet.options,
            },
          },
        }).then((res) => {
          navigate(`/${project._id}/dashboard/create-deal/${selectedWorksheet.unit._id}/form/${selectedWorksheet._id}`);
        });
      }
    }
  };

  return loading ? (
    <LoadingWrapper title="Loading..." modal={false} />
  ) : selectedWorksheet ? (
    <Container>
      <Typography sx={{ mb: 2 }} variant="h5">
        <ArrowBack sx={{ cursor: 'pointer', mr: 1, alignSelf: 'center' }} onClick={() => navigate(-1)} />
        {selectedWorksheet.unit ? (
          <strong>
            Suite {selectedWorksheet.unit.suite}{' '}
            {selectedWorksheet.realtor ? `- Worksheet by ${selectedWorksheet.realtor.fullName}` : '- Worksheet by Public'}
          </strong>
        ) : (
          <strong>Wishlist Worksheet by {selectedWorksheet.realtor.fullName}</strong>
        )}
      </Typography>
      <Box
        sx={{
          mb: 3,
        }}
      >
        {selectedWorksheet?.purchasers.map((purchaser: any, index: number) => {
          return (
            <Box sx={{ p: 2, border: '1px solid #000', mb: 2 }} key={index}>
              <Typography variant={'h2'} gutterBottom>
                <strong>Purchaser {index + 1}</strong>
              </Typography>
              <div key={index}>
                <Grid container spacing={2}>
                  {purchaser.identifications.length > 0
                    ? purchaser.identifications.map((attachment: any, index: number) => {
                        return (
                          <Grid key={index} item md={4} sm={6} xs={12}>
                            <PdfCard
                              file={attachment.getUrl ? attachment.getUrl : attachment.url}
                              title={attachment.name ? attachment.name : attachment.file.name}
                              id={index}
                              handleDelete={() => storeDispatch(showErrorSnackbar(`Cannot Delete`))}
                              download={true}
                              index={index}
                            />
                          </Grid>
                        );
                      })
                    : null}
                </Grid>
                <Flex>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mr: 1,
                    }}
                  >
                    <Box>
                      Full Name:{' '}
                      <strong>
                        {purchaser.firstName} {purchaser.lastName}
                      </strong>
                    </Box>
                    <Box>
                      Address:{' '}
                      <strong>
                        {purchaser.streetAddress}, {purchaser.city}, {purchaser.province}, {purchaser.postalCode}
                      </strong>
                    </Box>
                    <Box>
                      SIN: <strong>{purchaser.sin ? <strong>{purchaser.sin}</strong> : 'N/A'}</strong>
                    </Box>
                    <Box>
                      Date of Birth: <strong>{convertAllDates(purchaser.dob, 'PPP')}</strong>
                    </Box>
                    <Box>
                      Phone: <strong>{purchaser.primaryPhone}</strong>
                    </Box>
                    <Box>
                      Email: <strong>{purchaser.email}</strong>
                    </Box>
                    <Box>
                      ID Type: <strong>{purchaser.idType}</strong>
                    </Box>
                    <Box>
                      ID Jurisdiction: <strong>{purchaser.idJurisdiction}</strong>
                    </Box>
                    <Box>
                      ID Purchaser Type: <strong>{purchaser.purchaserType}</strong>
                    </Box>
                    <Box>
                      Occupation: <strong>{purchaser.occupation}</strong>
                    </Box>
                    <Box>
                      Employer: <strong>{purchaser.employer}</strong>
                    </Box>
                  </Box>
                </Flex>
              </div>
            </Box>
          );
        })}
      </Box>
      <Box sx={{ p: 2, border: '1px solid #000', mb: 2 }}>
        <Typography variant="h2">
          <strong>Bank Draft / Cheque Image</strong>
        </Typography>
        <Dropzone onDrop={(files) => handleDrop(files)} accept="image/jpg, image/jpeg, image/png, application/pdf">
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag and Drop or Upload Images</p>
              </div>
            </section>
          )}
        </Dropzone>
        {selectedWorksheet.chequeImages.length > 0 ? (
          <div>
            <Divider style={{ margin: '10px 0' }} />
            <strong>Bank Drafts / Cheques</strong>
            <Grid container spacing={2}>
              {selectedWorksheet.chequeImages.map((attachment: any, index: number) => {
                return (
                  <Grid sx={{ mt: 2 }} key={index} item md={3} xs={12}>
                    <PdfCard
                      file={attachment.getUrl ? attachment.getUrl : attachment.url}
                      title={attachment.name ? attachment.name : attachment.file.name}
                      id={index}
                      handleDelete={() => handleDelete(index, attachment.file!)}
                      download={true}
                      index={index}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        ) : null}
      </Box>
      <Box sx={{ p: 2, border: '1px solid #000', mb: 2 }}>
        <Typography variant="h2">
          <strong>Worksheet Information</strong>
        </Typography>
        <Grid sx={{ mt: 1 }} container spacing={2}>
          <Grid item sm={6}>
            {selectedWorksheet.unit || user.type === 'Manager' ? (
              <Box>
                <Box>Switch to Suite:</Box>
                {!selectedWorksheet.deal ? (
                  <Dropdown
                    title={''}
                    menuList={availableSuiteNames()}
                    name={'suite'}
                    handleSelect={(event: any) => handleUnitDropdown(event)}
                    value={`${selectedWorksheet.unit ? `${selectedWorksheet.unit.suite} - ${selectedWorksheet.unit.status}` : ''}`}
                  />
                ) : (
                  <strong>{selectedWorksheet.unit.suite}</strong>
                )}
              </Box>
            ) : null}
          </Grid>
          {selectedWorksheet.unit ? (
            <Grid item sm={6}>
              <Box>Worksheet Submitted Price:</Box>
              {!selectedWorksheet.deal ? (
                <TextInput
                  title={''}
                  name={'worksheetBasePrice'}
                  handleTextInput={(e: any) => handleTextInput(e)}
                  value={selectedWorksheet.worksheetBasePrice}
                  adornment={'$'}
                />
              ) : (
                <strong>{numToCurrency.format(selectedWorksheet.worksheetBasePrice)}</strong>
              )}
            </Grid>
          ) : null}
        </Grid>
        {selectedWorksheet.unit ? (
          <Box sx={{ my: 1 }}>
            <div>
              Level: <strong>{selectedWorksheet.unit.level}</strong>
            </div>
            <div>
              Unit Number: <strong>{selectedWorksheet.unit.unit}</strong>
            </div>
            <div>
              Unit Type: <strong>{selectedWorksheet.unit.unitType}</strong>
            </div>
            <div>
              Model Type: <strong>{selectedWorksheet.unit.modelType}</strong>
            </div>
            <div>
              Bathroom: <strong>{selectedWorksheet.unit.bathroom}</strong>
            </div>
            <div>
              Size: <strong>{selectedWorksheet.unit.size}</strong>
            </div>
            <div>
              Outdoor Size: <strong>{selectedWorksheet.unit.outdoorSize ? selectedWorksheet.unit.outdoorSize : '0'}</strong>
            </div>
          </Box>
        ) : null}
      </Box>
      <Box sx={{ p: 2, border: '1px solid #000', mb: 2 }}>
        <Typography variant="h2">
          <strong>Option Information</strong>
        </Typography>
        <Grid container spacing={2}>
          {project.options
            .filter((option: any) => {
              if (selectedWorksheet.unit) {
                return option.type === selectedWorksheet.unit.type;
              } else return option.type === 'residential';
            })
            .map((option: IOption, index: number) => {
              return (
                <React.Fragment key={index}>
                  <Grid
                    sx={{
                      alignSelf: 'center',
                      fontSize: '20px',
                    }}
                    item
                    xs={12}
                    sm={3}
                  >
                    <div>
                      <strong>{option.name}</strong>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <Dropdown
                      title={`Quantity`}
                      id={'purchaseAmount'}
                      menuList={['0', '1', '2', '3']}
                      name={option.name}
                      handleSelect={(e: any) => handleOptionDropdown(e, index)}
                      value={optionValue(option.name, 'purchaseAmount')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput
                      title={`${option.name} Total Amount`}
                      type="number"
                      id={'totalAmount'}
                      handleTextInput={(e: any) => handleOptionInput(e, option.name)}
                      value={optionValue(option.name, 'amount')}
                      min={'0'}
                      adornment={'$'}
                      required={false}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
        </Grid>
      </Box>
      {project.coopStructures ? (
        <Box sx={{ p: 2, border: '1px solid #000', mb: 2 }}>
          <Typography variant="h2">
            <strong>Commission</strong>
          </Typography>
          <Commission coopRates={coopRates} setCoopRates={setCoopRates} />
        </Box>
      ) : null}
      <Box sx={{ p: 2, border: '1px solid #000', mb: 2 }}>
        <Typography variant="h2">
          <strong>Pricing Information</strong>
        </Typography>
        {selectedWorksheet.unit ? (
          <Box>
            <Box
              sx={{
                my: 1,
                color: selectedWorksheet.unit.basePrice !== selectedWorksheet.worksheetBasePrice ? 'error.main' : 'success.main',
              }}
            >
              Current Unit Price: <strong>{numToCurrency.format(selectedWorksheet.unit.basePrice)}</strong>
            </Box>
            <Box
              sx={{
                my: 1,
                color: selectedWorksheet.unit.basePrice !== selectedWorksheet.worksheetBasePrice ? 'error.main' : 'success.main',
              }}
            >
              Worksheet Submitted Price: <strong>{numToCurrency.format(selectedWorksheet.worksheetBasePrice)}</strong>
            </Box>
            <Divider />
          </Box>
        ) : (
          <Box sx={{ mt: 2 }}>No unit has been assigned to this worksheet</Box>
        )}
        <Box>
          {selectedWorksheet.unit ? (
            <Box sx={{ my: 1 }}>
              Price: <strong>{numToCurrency.format(selectedWorksheet.worksheetBasePrice)}</strong>
            </Box>
          ) : null}
          {selectedWorksheet.options.map((option: IDealOption, index: number) => {
            return (
              <Box sx={{ mb: 1 }} key={index}>
                {option.name}:{' '}
                <strong>
                  x{option.purchaseAmount} at {numToCurrency.format(option.amount!)}
                </strong>
              </Box>
            );
          })}
        </Box>
        {selectedWorksheet.unit ? (
          <Box>
            <Divider />
            <Box sx={{ my: 1 }}>
              Total Price:{' '}
              <strong style={{ textDecoration: 'underline', textDecorationStyle: 'double' }}>
                {numToCurrency.format(
                  selectedWorksheet.options.reduce((a: any, b: any) => a + b.amount, selectedWorksheet.worksheetBasePrice)
                )}
              </strong>
            </Box>
          </Box>
        ) : null}
      </Box>
      <Box sx={{ p: 2, border: '1px solid #000', mb: 2 }}>
        <Typography variant="h2">
          <strong>Worksheet Notes</strong>
        </Typography>
        <Box sx={{ mt: 2 }}>
          <TextInput
            name="notes"
            id="comments"
            multiline
            variant={'outlined'}
            rows={4}
            required={true}
            value={selectedWorksheet.notes}
            rowsMax={Infinity}
            handleTextInput={(e: any) => handleTextInput(e)}
          />
        </Box>
      </Box>
      <Box>
        <FlexEnd>
          {selectedWorksheet?.unit && user.type === 'Manager' && !soldStatuses.includes(selectedWorksheet.unit.status) ? (
            <Button onClick={() => handleCloseSuccess()} sx={{ mr: 1 }} variant="contained">
              {showSuccess()}
            </Button>
          ) : null}
          {!selectedWorksheet?.deal ? (
            <Button onClick={() => handleCloseSecond()} sx={{ mr: 1 }} variant="contained">
              Save Changes
            </Button>
          ) : null}
          {selectedWorksheet?.deal && selectedWorksheet.status === 'accepted' ? null : (
            <Button onClick={() => handleCloseThird()} sx={{ mr: 1 }} variant="contained">
              Archive
            </Button>
          )}
          {selectedWorksheet?.managerApproval && selectedWorksheet?.unit._id && selectedWorksheet.status === 'accepted' ? (
            <Button onClick={() => handleCloseFourth()} variant="contained" color="success">
              Create Deal
            </Button>
          ) : null}
        </FlexEnd>
      </Box>
    </Container>
  ) : null;
};

const UNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      _id
      suite
      basePrice
      unit
      level
      modelType
      unitType
      exposure
      bathroom
      size
      status
      worksheets {
        _id
      }
      allocation {
        _id
        fullName
      }
      type
    }
  }
`;

const GETWORKSHEET = gql`
  query worksheetById($_id: MongoID!) {
    worksheetById(_id: $_id) {
      _id
      project {
        _id
      }
      deal {
        _id
        createdAt
      }
      status
      unit {
        _id
        suite
        basePrice
        unit
        level
        modelType
        unitType
        exposure
        bathroom
        size
        status
        worksheets {
          _id
        }
        allocation {
          _id
          fullName
        }
        type
      }
      salesRep {
        fullName
      }
      realtor {
        _id
        fullName
        email
        directPhone
      }
      coop {
        type
        days
        date
        description
        percentage
        fixedAmount
      }
      purchasers {
        _id
        project {
          _id
        }
        firstName
        lastName
        email
        streetAddress
        city
        province
        country
        postalCode
        purchaserType
        employer
        occupation
        primaryPhone
        dob
        sin
        idType
        unit
        idJurisdiction
        identifications {
          name
          getUrl
        }
      }
      wishlist
      joinWaitlist
      wishlistChoices {
        modelTypes
        unitType
        level
        exposure
      }
      options {
        name
        amount
        purchaseAmount
      }
      notes
      managerApproval {
        user {
          fullName
        }
        timestamp
      }
      chequeImages {
        name
        _id
        getUrl
      }
      totalPrice
      reason
      worksheetBasePrice
      notes
      createdAt
    }
  }
`;

const UPDATEWORKSHEET = gql`
  mutation worksheetUpdateById($_id: MongoID!, $record: UpdateByIdWorksheetInput!) {
    worksheetUpdateById(_id: $_id, record: $record) {
      record {
        _id
        project {
          _id
        }
        status
        unit {
          _id
          suite
          basePrice
          unit
          level
          modelType
          unitType
          bathroom
          size
          status
          worksheets {
            _id
          }
          allocation {
            _id
            fullName
          }
        }
        salesRep {
          fullName
        }
        realtor {
          _id
          fullName
          directPhone
          email
        }
        coop {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
        purchasers {
          _id
          project {
            _id
          }
          firstName
          lastName
          email
          streetAddress
          city
          province
          country
          postalCode
          purchaserType
          employer
          occupation
          primaryPhone
          dob
          sin
          idType
          unit
          idJurisdiction
          identifications {
            name
            getUrl
          }
        }
        wishlist
        joinWaitlist
        wishlistChoices {
          modelTypes
          unitType
          level
          exposure
        }
        options {
          name
          amount
          purchaseAmount
        }
        notes
        managerApproval {
          user {
            fullName
          }
          timestamp
        }
        chequeImages {
          name
          _id
          getUrl
        }
        totalPrice
        reason
        worksheetBasePrice
        notes
        createdAt
      }
    }
  }
`;

const UPDATEUNIT = gql`
  mutation unitUpdateById($_id: MongoID!, $record: UpdateByIdUnitInput!) {
    unitUpdateById(_id: $_id, record: $record) {
      record {
        _id
        status
      }
    }
  }
`;

const UPLOADCHEQUE = gql`
  mutation uploadCheque($id: MongoID!, $name: String!, $file: Upload!) {
    uploadCheque(id: $id, name: $name, file: $file) {
      chequeImages {
        _id
        name
        getUrl
      }
    }
  }
`;

export default WorksheetDetails;
