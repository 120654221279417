import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { DragDropContext } from '@hello-pangea/dnd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid, Tabs, Tab, FormControl, Autocomplete, TextField } from '@mui/material';

import TaskCard from './TaskCard';
import { FlexBetween } from '../../commonStyles';
import { selectUser } from '../../features/auth/authSlice';
import { IProject } from '../../types/project';
import { ITask } from '../../types/task';
import { normalToCamel } from '../../utils/Functions';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Tasks = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [newTasks, setNewTasks] = useState<ITask[]>([]);
  const [inProgress, setInProgress] = useState<ITask[]>([]);
  const [completed, setCompleted] = useState<ITask[]>([]);
  const [archived, setArchived] = useState<ITask[]>([]);

  const [viewedTask] = useMutation(VIEWEDTASKS, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteAttachment] = useMutation(DELETEATTACHMENT, {
    onCompleted: (data) => {
      if (data.deleteAttachment) {
        if (data.deleteAttachment.status === 'newTasks') {
          let task = newTasks.map((task: ITask) => {
            if (task._id === data.deleteAttachment._id) {
              return data.deleteAttachment;
            } else return task;
          });
          setNewTasks(task);
        }
        if (data.deleteAttachment.status === 'inProgress') {
          let task = inProgress.map((task: ITask) => {
            if (task._id === data.deleteAttachment._id) {
              return data.deleteAttachment;
            } else return task;
          });
          setInProgress(task);
        }
        if (data.deleteAttachment.status === 'completed') {
          let task = completed.map((task: ITask) => {
            if (task._id === data.deleteAttachment._id) {
              return data.deleteAttachment;
            } else return task;
          });
          setCompleted(task);
        }
        if (data.deleteAttachment.status === 'archived') {
          let task = archived.map((task: ITask) => {
            if (task._id === data.deleteAttachment._id) {
              return data.deleteAttachment;
            } else return task;
          });
          setArchived(task);
        }
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [createTask] = useMutation(CREATETASK, {
    onCompleted: (data) => {
      if (data.createTask) {
        if (data.createTask.status === 'newTasks') {
          setNewTasks(
            [...newTasks, data.createTask].sort((a: any, b: any) => {
              return new Date(a.dueDate).valueOf() - new Date(b.dueDate).valueOf();
            })
          );
        }
        if (data.createTask.status === 'inProgress') {
          setInProgress(
            [...inProgress, data.createTask].sort((a: any, b: any) => {
              return new Date(a.dueDate).valueOf() - new Date(b.dueDate).valueOf();
            })
          );
        }
        if (data.createTask.status === 'completed') {
          setCompleted(
            [...inProgress, data.createTask].sort((a: any, b: any) => {
              return new Date(a.dueDate).valueOf() - new Date(b.dueDate).valueOf();
            })
          );
        }
        if (data.createTask.status === 'archived') {
          setArchived(
            [...inProgress, data.createTask].sort((a: any, b: any) => {
              return new Date(a.dueDate).valueOf() - new Date(b.dueDate).valueOf();
            })
          );
        }
      }
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateTask] = useMutation(UPDATETASK, {
    onCompleted: (data) => {
      if (data.taskUpdateById) {
        if (data.taskUpdateById.record.status === 'newTasks') {
          let task = newTasks.map((task: ITask) => {
            if (task._id === data.taskUpdateById.record._id) {
              return data.taskUpdateById.record;
            } else return task;
          });
          if (value === 2) {
            setArchived(archived.filter((task: ITask) => task._id !== data.taskUpdateById.record._id));
          }
          setNewTasks(task);
        }
        if (data.taskUpdateById.record.status === 'inProgress') {
          let task = inProgress.map((task: ITask) => {
            if (task._id === data.taskUpdateById.record._id) {
              return data.taskUpdateById.record;
            } else return task;
          });
          if (value === 2) {
            setArchived(archived.filter((task: ITask) => task._id !== data.taskUpdateById.record._id));
          }
          setInProgress(task);
        }
        if (data.taskUpdateById.record.status === 'completed') {
          let task = completed.map((task: ITask) => {
            if (task._id === data.taskUpdateById.record._id) {
              return data.taskUpdateById.record;
            } else return task;
          });
          if (value === 2) {
            setArchived(archived.filter((task: ITask) => task._id !== data.taskUpdateById.record._id));
          }
          setCompleted(task);
        }
        if (data.taskUpdateById.record.status === 'archived') {
          let task = archived.map((task: ITask) => {
            if (task._id === data.taskUpdateById.record._id) {
              return data.taskUpdateById.record;
            } else return task;
          });
          if (value !== 2) {
            setCompleted(completed.filter((task: ITask) => task._id !== data.taskUpdateById.record._id));
            setInProgress(inProgress.filter((task: ITask) => task._id !== data.taskUpdateById.record._id));
            setNewTasks(newTasks.filter((task: ITask) => task._id !== data.taskUpdateById.record._id));
          }
          setArchived(task);
        }
      }
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [getTasks] = useLazyQuery(TASKS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const distantFuture = new Date(86400000000000000000000);
      let newTasks = [];
      let inProgress = [];
      let completed = [];
      let archived = [];
      for (const task of data.taskMany) {
        if (task.status === 'newTasks') {
          newTasks.push(task);
        }
        if (task.status === 'inProgress') {
          inProgress.push(task);
        }
        if (task.status === 'completed') {
          completed.push(task);
        }
        if (task.status === 'archived') {
          archived.push(task);
        }
      }
      setNewTasks(
        newTasks.sort((a: any, b: any) => {
          let dateA = a.dueDate ? new Date(a.dueDate) : distantFuture;
          let dateB = b.dueDate ? new Date(b.dueDate) : distantFuture;
          return dateA.getTime() - dateB.getTime();
        })
      );
      setInProgress(
        inProgress.sort((a: any, b: any) => {
          let dateA = a.dueDate ? new Date(a.dueDate) : distantFuture;
          let dateB = b.dueDate ? new Date(b.dueDate) : distantFuture;
          return dateA.getTime() - dateB.getTime();
        })
      );
      setCompleted(
        completed.sort((a: any, b: any) => {
          let dateA = a.dueDate ? new Date(a.dueDate) : distantFuture;
          let dateB = b.dueDate ? new Date(b.dueDate) : distantFuture;
          return dateA.getTime() - dateB.getTime();
        })
      );
      setArchived(
        archived.sort((a: any, b: any) => {
          let dateA = a.dueDate ? new Date(a.dueDate) : distantFuture;
          let dateB = b.dueDate ? new Date(b.dueDate) : distantFuture;
          return dateA.getTime() - dateB.getTime();
        })
      );
    },
  });

  useEffect(() => {
    if (value === 0) {
      getTasks({
        variables: { filter: { toUsers: user._id, projects: projects.map((project: IProject) => project._id) } },
      });
    } else if (value === 1) {
      getTasks({
        variables: { filter: { from: user._id, projects: projects.map((project: IProject) => project._id) } },
      });
    } else if (value === 2) {
      getTasks({
        variables: { filter: { user: user._id, projects: projects.map((project: IProject) => project._id), status: 'archived' } },
      });
    } else if (value === 3 && user.type === 'Manager') {
      getTasks({
        variables: { filter: { projects: projects.map((project: IProject) => project._id) } },
      });
    }
  }, [value, projects]);

  useEffect(() => {
    viewedTask({ variables: { user: user._id } });
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const move = (source: any, destination: any, droppableSource: any, droppableDestination: any) => {
    let removed: any;
    if (source === 'New Tasks' && destination === 'New Tasks') {
      const sourceClone = Array.from(newTasks);
      let temp = sourceClone[droppableSource.index];
      sourceClone[droppableSource.index] = sourceClone[droppableDestination.index];
      sourceClone[droppableDestination.index] = temp;
      setNewTasks(sourceClone);
      return;
    }
    if (source === 'In Progress' && destination === 'In Progress') {
      const sourceClone = Array.from(inProgress);
      let temp = sourceClone[droppableSource.index];
      sourceClone[droppableSource.index] = sourceClone[droppableDestination.index];
      sourceClone[droppableDestination.index] = temp;
      setInProgress(sourceClone);
      return;
    }
    if (source === 'Completed' && destination === 'Completed') {
      const sourceClone = Array.from(completed);
      let temp = sourceClone[droppableSource.index];
      sourceClone[droppableSource.index] = sourceClone[droppableDestination.index];
      sourceClone[droppableDestination.index] = temp;
      setCompleted(sourceClone);
      return;
    }
    if (source === 'New Tasks') {
      const sourceClone = Array.from(newTasks);
      [removed] = sourceClone.splice(droppableSource.index, 1);
      setNewTasks(sourceClone);
    }
    if (source === 'In Progress') {
      const sourceClone = Array.from(inProgress);
      [removed] = sourceClone.splice(droppableSource.index, 1);
      setInProgress(sourceClone);
    }
    if (source === 'Completed') {
      const sourceClone = Array.from(completed);
      [removed] = sourceClone.splice(droppableSource.index, 1);
      setCompleted(sourceClone);
    }
    if (destination === 'New Tasks') {
      const destClone = Array.from(newTasks);
      destClone.splice(droppableDestination.index, 0, removed);
      let clone = destClone.map((clone: any, index: number) => {
        if (index === droppableDestination.index) {
          return {
            ...clone,
            status: 'newTasks',
          };
        } else return clone;
      });
      setNewTasks(clone);
    }
    if (destination === 'In Progress') {
      const destClone = Array.from(inProgress);
      destClone.splice(droppableDestination.index, 0, removed);
      let clone = destClone.map((clone: any, index: number) => {
        if (index === droppableDestination.index) {
          return {
            ...clone,
            status: 'inProgress',
          };
        } else return clone;
      });
      setInProgress(clone);
    }
    if (destination === 'Completed') {
      const destClone = Array.from(completed);
      destClone.splice(droppableDestination.index, 0, removed);
      let clone = destClone.map((clone: any, index: number) => {
        if (index === droppableDestination.index) {
          return {
            ...clone,
            status: 'completed',
          };
        } else return clone;
      });
      setCompleted(clone);
    }
  };

  const handleDragEnd = (result: any) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;
    move(source.droppableId, destination.droppableId, source, destination);
    updateTask({ variables: { _id: draggableId, record: { status: normalToCamel(destination.droppableId) } } });
  };

  return (
    <Box sx={{ backgroundColor: '#00142a', minHeight: '100vh' }}>
      <Box sx={{ p: 2 }}>
        <FlexBetween sx={{ mb: 1, flexWrap: 'wrap' }}>
          <Box sx={{ alignSelf: 'center', display: 'flex' }}>
            <Box sx={{ alignSelf: 'center' }}>
              <ArrowBackIcon sx={{ cursor: 'pointer', mr: 1, alignSelf: 'center', color: '#fff' }} onClick={() => navigate(-1)} />
            </Box>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
              <Tab label="Tasks For You" {...a11yProps(0)} />
              <Tab label="Task For Them" {...a11yProps(1)} />
              <Tab label="Archived" {...a11yProps(2)} />
              {user.type === 'Manager' ? <Tab label="All Tasks" {...a11yProps(3)} /> : null}
            </Tabs>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box>
              <FormControl
                sx={{
                  width: '300px',
                }}
              >
                <Autocomplete
                  id={'search'}
                  multiple
                  disableClearable
                  freeSolo={false}
                  options={user?.projectAccess ? [...user?.projectAccess.map((pa: any) => pa.project)] : []}
                  getOptionLabel={(option: any) => option.name}
                  onChange={(e, value) => setProjects(value!)}
                  renderInput={(params) => (
                    <TextField
                      required={false}
                      {...params}
                      sx={{ backgroundColor: '#fff' }}
                      size="small"
                      placeholder={'Projects'}
                      margin="normal"
                    />
                  )}
                />
              </FormControl>
            </Box>
          </Box>
        </FlexBetween>
        {value !== 2 ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TaskCard
                  title="New Tasks"
                  data={newTasks}
                  updateTask={updateTask}
                  deleteAttachment={deleteAttachment}
                  createTask={createTask}
                  value={value}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TaskCard
                  title="In Progress"
                  data={inProgress}
                  updateTask={updateTask}
                  deleteAttachment={deleteAttachment}
                  createTask={createTask}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TaskCard
                  title="Completed"
                  data={completed}
                  updateTask={updateTask}
                  deleteAttachment={deleteAttachment}
                  createTask={createTask}
                />
              </Grid>
            </Grid>
          </DragDropContext>
        ) : (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <TaskCard
                  title="Archived"
                  data={archived}
                  updateTask={updateTask}
                  deleteAttachment={deleteAttachment}
                  createTask={createTask}
                />
              </Grid>
            </Grid>
          </DragDropContext>
        )}
      </Box>
    </Box>
  );
};

const TASKS = gql`
  query taskMany($filter: FilterFindManyTaskInput) {
    taskMany(filter: $filter, limit: 10000) {
      _id
      project {
        _id
        name
        portal {
          primaryColor
        }
      }
      title
      status
      type
      description
      from {
        _id
        fullName
      }
      to {
        _id
        fullName
      }
      labels
      dueDate
      attachments {
        _id
        name
        getUrl
      }
      comments {
        _id
        type
        pinned
        comment
        updatedAt
        createdAt
        user {
          fullName
        }
      }
      reason
      createdAt
    }
  }
`;

const UPDATETASK = gql`
  mutation taskUpdateById($_id: MongoID!, $record: UpdateByIdTaskInput!) {
    taskUpdateById(_id: $_id, record: $record) {
      record {
        _id
        project {
          _id
          name
          portal {
            primaryColor
          }
        }
        title
        status
        type
        description
        from {
          _id
          fullName
        }
        to {
          _id
          fullName
        }
        labels
        dueDate
        attachments {
          _id
          name
          getUrl
        }
        comments {
          _id
          type
          pinned
          comment
          updatedAt
          createdAt
          user {
            fullName
          }
        }
        reason
        createdAt
      }
    }
  }
`;

const DELETEATTACHMENT = gql`
  mutation deleteAttachment($_id: MongoID!, $attachmentId: MongoID!, $deleteFile: Boolean!) {
    deleteAttachment(_id: $_id, attachmentId: $attachmentId, deleteFile: $deleteFile) {
      _id
      project {
        _id
        name
        portal {
          primaryColor
        }
      }
      title
      status
      type
      description
      from {
        _id
        fullName
      }
      to {
        _id
        fullName
      }
      labels
      dueDate
      attachments {
        _id
        name
        getUrl
      }
      comments {
        _id
        type
        pinned
        comment
        updatedAt
        createdAt
        user {
          fullName
        }
      }
      reason
      createdAt
    }
  }
`;

const CREATETASK = gql`
  mutation createTask($record: CreateOneTaskInput!, $files: [Upload]) {
    createTask(record: $record, files: $files) {
      _id
      project {
        _id
        name
        portal {
          primaryColor
        }
      }
      title
      status
      type
      description
      from {
        _id
        fullName
      }
      to {
        _id
        fullName
      }
      labels
      dueDate
      attachments {
        _id
        name
        getUrl
      }
      comments {
        _id
        type
        pinned
        comment
        updatedAt
        createdAt
        user {
          fullName
        }
      }
      reason
      createdAt
    }
  }
`;

const VIEWEDTASKS = gql`
  mutation updateTasks($user: MongoID!) {
    updateTasks(user: $user)
  }
`;

export default Tasks;
