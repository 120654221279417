import { Outlet } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
//@ts-ignore
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

/* Project Imports */
import env from '../../config/env';

const PublicAuth = () => {
  /* Redux */

  /* Router */
  const publicClient = new ApolloClient({
    link: createUploadLink({
      uri: `${env.basePath}/public`,
      credentials: 'include',
      headers: { 'Apollo-Require-Preflight': 'true' },
    }),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    credentials: 'include',
  });

  return (
    <ApolloProvider client={publicClient}>
      <Outlet />
    </ApolloProvider>
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
  );
};

export default PublicAuth;
