import { SetStateAction, Dispatch, useState, useReducer, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { addSeconds, addHours } from 'date-fns';
import {
  Box,
  Typography,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Grid,
  CircularProgress,
} from '@mui/material';

import { accessTypes } from '../../../utils/Constants';
import { ISchedule } from '../../../types/schedule';
import { Flex } from '../../../commonStyles';
import { selectProject } from '../../../features/project/projectSlice';
import Location from './Location';
import Calendar from './Calendar';
import Questions from './Questions';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar, showSuccessSnackbar } from '../../../features/snackbar/snackbarSlice';
import { capitalizeFirstLetter, urlName, timeZoneDate } from '../../../utils/Functions';
import { IQuestion } from '../../../types/question';
import { selectUser } from '../../../features/auth/authSlice';
import { IUser } from '../../../types/user';

export const datesReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD':
      return [
        ...state,
        {
          date: new Date(),
          length: 60,
          timeBetweenAppointments: 30,
          timeStart: 0,
          timeEnd: 0,
          numberOfAppointments: 0,
        },
      ];
    case 'COPY':
      return [...state, action.payload];
    case 'UPDATE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case 'UPDATEALL':
      return action.payload;
    case 'DELETE':
      return state.filter((state: any, index: number) => index !== action.payload.index);
    default:
      throw new Error();
  }
};

export const questionsReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADDNEW':
      return [...state, { name: '', question: '', type: '', subQuestion: false, display: '', choices: [] }];
    case 'ADD':
      return [...state, action.payload];
    case 'UPDATE':
      return state.map((state: any, index: number) => {
        if (index === action.payload.index) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case 'UPDATEALL':
      return action.payload;
    case 'DELETE':
      return state.filter((state: any, index: number) => index !== action.payload.index);
    default:
      throw new Error();
  }
};

const CreateSchedule = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const { schedule, setScheduleView, tabValue, schedules, setSchedules } = props;
  const storeDispatch = useAppDispatch();

  const [name, setName] = useState<string>('');
  const [mainText, setMainText] = useState<string>('Please click continue to book an appointment');
  const [endText, setEndText] = useState<string>(
    'Thank you for booking an appointment! An email will be sent to you confirming your appointment details.'
  );
  const [emailSubject, setEmailSubject] = useState<string>(`${project.name} - Thank you for RSVPing`);
  const [emailMainText, setEmailMainText] = useState<string>(
    'Your reservation has been received. Please see below the details of your reservation.'
  );
  const [emailEndText, setEmailEndText] = useState<string>(
    `If you have any questions or if there are any issues regarding your reservation, please contact ${project.email}. We hope to see you soon!`
  );
  const [cancelSubject, setCancelSubject] = useState<string>(`${project.name} - Appointment Cancelled`);
  const [cancelMainText, setCancelMainText] = useState<string>(
    'Your appointment has been cancelled. Please see below the details of your cancelled appointment.'
  );
  const [cancelEndText, setCancelEndText] = useState<string>(
    `If you have any questions, please contact ${project.email}. We hope to see you soon!`
  );
  const [confirmSubject, setConfirmSubject] = useState<string>(`${project.name} - Appointment Confirmed`);
  const [confirmMainText, setConfirmMainText] = useState<string>(
    'Your reservation has been confirmed. Please see below the details of your reservation.'
  );
  const [confirmEndText, setConfirmEndText] = useState<string>(
    `If you have any questions or if there are any issues regarding your reservation, please contact ${project.email}. We hope to see you soon!`
  );
  const [locations, setLocations] = useState<string[]>([project.salesOffice]);
  const [datesState, datesDispatch] = useReducer(datesReducer, [
    {
      date: new Date(),
      length: 60,
      timeBetweenAppointments: 30,
      timeStart: 0,
      timeEnd: 0,
      numberOfAppointments: 0,
    },
  ]);
  const [parentQuestions, setParentQuestions] = useState<IQuestion[]>([]);
  const [questionsState, questionsDispatch] = useReducer(questionsReducer, []);
  const [emails, setEmails] = useState<string>('');
  const [activeSchedule, setActiveSchedule] = useState<boolean>(true);
  const [allowGuests, setAllowGuests] = useState<boolean>(true);
  const [maxGuests, setMaxGuests] = useState<string>('5');
  const [notification, setNotification] = useState<boolean>(false);
  const [publicSchedule, setPublicSchedule] = useState<boolean>(true);
  const [access, setAccess] = useState<string>('regular');
  const [users, setUsers] = useState<IUser[]>([]);
  const [hideLocation, setHideLocation] = useState<boolean>(false);
  const [shortUrl, setShortUrl] = useState<string>('');
  const [virtual, setVirtual] = useState<boolean>(false);
  const [urlLink, setUrlLink] = useState<string>('');

  useQuery(GETPROJECTSETTINGS, {
    variables: { filter: { project: project._id } },
    onCompleted: (data) => {
      setParentQuestions([...data.crmProjectSettingOne.questions].filter((question: IQuestion) => !question.subQuestion));
      setUsers([...data.crmProjectSettingOne.sales, ...data.crmProjectSettingOne.admins, ...data.crmProjectSettingOne.online]);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [editCalendar] = useMutation(EDITCALENDAR, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [scheduleCreate, { loading: createLoading }] = useMutation(CREATESCHEDULE, {
    onCompleted: (data) => {
      setSchedules([...schedules, data.createSchedule]);
      setLocations(data.createSchedule.locations);
      setHideLocation(data.createSchedule.hideLocation);
      setUrlLink('');
      if (data.createSchedule.schedules.length < 3) {
        for (const schedule of data.createSchedule.schedules) {
          let date = new Date(timeZoneDate(addSeconds(new Date(schedule.date).setHours(0, 0, 0, 0), schedule.timeStart)));
          let endDate = new Date(timeZoneDate(addSeconds(new Date(schedule.date).setHours(0, 0, 0, 0), schedule.timeEnd)));

          let attendees: any[] = [];

          if (process.env.NODE_ENV === 'production') {
            attendees = [...users].map((user: any) => {
              return {
                email: user.email,
                organizer: null,
                responseStatus: 'needsAction',
                self: false,
              };
            });
            for (const email of [
              'charlesj@rdsre.ca',
              'brians@rdsre.ca',
              'matthewl@rdsre.ca',
              'richarda@rdsre.ca',
              'lorrainea@rdsbrokerage.com',
            ]) {
              attendees.push({
                email: email,
                organizer: null,
                responseStatus: 'needsAction',
                self: false,
              });
            }
          }

          editCalendar({
            variables: {
              calendarId: user.email,
              eventId: null,
              summary: data.createSchedule.name,
              start: date,
              end: endDate,
              location: data.createSchedule.virtual ? '' : data.createSchedule.locations[0],
              attendees: [
                ...attendees,
                {
                  email: user.email,
                  organizer: null,
                  responseStatus: 'needsAction',
                  self: true,
                },
              ],
              googleMeetsLink: false,
              type: 'edit',
            },
          });
        }
      }
      storeDispatch(showSuccessSnackbar('Schedule Created!'));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [scheduleUpdate, { loading: updateLoading }] = useMutation(UPDATESCHEDULE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Schedule Updated!'));
      let updatedSchedule = schedules.map((schedule: ISchedule) => {
        if (schedule._id === data.scheduleUpdateById.record._id) {
          return data.scheduleUpdateById.record;
        } else return schedule;
      });
      setSchedules(updatedSchedule);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useEffect(() => {
    if (schedule) {
      setName(schedule.name!);
      setMainText(schedule.mainText!);
      setEndText(schedule.endText!);
      setMaxGuests(schedule.maxGuests ? schedule.maxGuests.toString() : '5');
      setEmailMainText(schedule.emailMainText!);
      setEmailEndText(schedule.emailEndText!);
      setEmailSubject(schedule.emailSubject);
      setCancelMainText(schedule.cancelMainText!);
      setCancelEndText(schedule.cancelEndText!);
      setCancelSubject(schedule.cancelSubject);
      setConfirmMainText(schedule.confirmMainText!);
      setConfirmEndText(schedule.confirmEndText!);
      setConfirmSubject(schedule.confirmSubject);
      setLocations(schedule.locations!);
      setPublicSchedule(schedule.public);
      setActiveSchedule(schedule.active);
      setAllowGuests(schedule.allowGuests);
      setNotification(schedule.notification);
      setEmails(schedule.emails!);
      setAccess(schedule.access);
      datesDispatch({ type: 'UPDATEALL', payload: schedule.schedules });
      questionsDispatch({ type: 'UPDATEALL', payload: schedule.questions });
      setHideLocation(schedule.hideLocation);
      setShortUrl(schedule.shortUrl);
      setVirtual(schedule.virtual);
    } else {
      setName('');
      setEmails('');
      setLocations([project.salesOffice]);
      setPublicSchedule(true);
      setActiveSchedule(true);
      setAccess('regular');
      datesDispatch({
        type: 'UPDATEALL',
        payload: [
          {
            date: new Date(),
            length: 60,
            timeBetweenAppointments: 30,
            timeStart: 0,
            timeEnd: 0,
            numberOfAppointments: 0,
          },
        ],
      });
      questionsDispatch({ type: 'UPDATEALL', payload: [] });
    }
  }, [schedule]);

  const createSchedule = (e: any) => {
    e.preventDefault();
    // Calendar Validation
    if (!datesState.length) return storeDispatch(showErrorSnackbar('No Dates Created'));
    let dateFormat = datesState.map((date: IDate) => {
      let dateLength = date.length;
      let dateAppointments = date.numberOfAppointments;
      let dateTimeslot = date.timeBetweenAppointments;
      if (typeof date.length === 'string') {
        dateLength = parseInt(date.length, 10);
      }
      if (typeof date.timeBetweenAppointments === 'string') {
        dateTimeslot = parseInt(date.timeBetweenAppointments, 10);
      }
      if (typeof date.numberOfAppointments === 'string') {
        dateAppointments = parseInt(date.numberOfAppointments, 10);
      }

      return {
        ...date,
        length: dateLength,
        timeBetweenAppointments: dateTimeslot,
        numberOfAppointments: dateAppointments,
      };
    });

    let appointmentTime = dateFormat.every((date: IDate) => date.timeEnd > date.timeStart);
    if (!appointmentTime) return storeDispatch(showErrorSnackbar('End time has to be greater than start time'));
    let validDate = dateFormat.every((date: any) => date.date != 'Invalid Date');
    if (!validDate) return storeDispatch(showErrorSnackbar('Invalid Date'));
    let appointmentCheck = dateFormat.every((date: IDate) => date.numberOfAppointments);
    if (!appointmentCheck) return storeDispatch(showErrorSnackbar('Number per Timeslot cannot be 0'));
    let appointmentLength = dateFormat.every((date: IDate) => date.length);
    if (!appointmentLength) return storeDispatch(showErrorSnackbar('Appointment length cannot be 0'));

    let checkOverlap = (schedule1: IDate, schedule2: IDate) => {
      if (new Date(schedule1.date).valueOf() === new Date(schedule2.date).valueOf()) {
        return schedule1 !== schedule2 && schedule1.timeEnd - schedule2.timeStart > 0 && schedule2.timeEnd - schedule1.timeStart > 0
          ? true
          : false;
      } else return false;
    };

    for (let i = 0; i < dateFormat.length; i++) {
      for (let j = 0; j < dateFormat.length; j++) {
        if (checkOverlap(dateFormat[i], dateFormat[j])) {
          return storeDispatch(showErrorSnackbar('Two or more schedules times overlap on the same day'));
        }
      }
    }

    // Only allow one public active schedule
    if (activeSchedule && publicSchedule) {
      let activePublicCheck = schedules.filter((schedule: ISchedule) => schedule.public && schedule.active);
      if (activePublicCheck.length) {
        if (activePublicCheck[0]._id !== schedule?._id) {
          return storeDispatch(showErrorSnackbar('Cannot have more than 1 public schedule'));
        }
      }
    }

    if (schedule) {
      scheduleUpdate({
        variables: {
          _id: schedule?._id,
          record: {
            name: name,
            active: activeSchedule,
            allowGuests: allowGuests,
            maxGuests: parseInt(maxGuests, 10),
            notification: notification,
            project: project._id,
            schedules: dateFormat,
            mainText: mainText,
            endText: endText,
            emailMainText: emailMainText,
            emailEndText: emailEndText,
            emailSubject: emailSubject,
            cancelMainText: cancelMainText,
            cancelEndText: cancelEndText,
            cancelSubject: cancelSubject,
            confirmMainText: confirmMainText,
            confirmEndText: confirmEndText,
            confirmSubject: confirmSubject,
            type: tabValue,
            access: access,
            public: publicSchedule,
            locations: locations,
            questions: questionsState.map((question: IQuestion) => question._id),
            emails: emails,
            hideLocation: virtual ? true : hideLocation,
            shortUrl,
            virtual,
          },
        },
      });
    } else {
      scheduleCreate({
        variables: {
          record: {
            name: name,
            active: activeSchedule,
            allowGuests: allowGuests,
            maxGuests: parseInt(maxGuests, 10),
            notification: notification,
            project: project._id,
            schedules: dateFormat,
            mainText: mainText,
            endText: endText,
            emailMainText: emailMainText,
            emailEndText: emailEndText,
            emailSubject: emailSubject,
            cancelMainText: cancelMainText,
            cancelEndText: cancelEndText,
            cancelSubject: cancelSubject,
            confirmMainText: confirmMainText,
            confirmEndText: confirmEndText,
            confirmSubject: confirmSubject,
            type: tabValue,
            access: access,
            public: publicSchedule,
            locations: locations,
            questions: questionsState.map((question: IQuestion) => question._id),
            emails: emails,
            hideLocation: hideLocation,
            shortUrl,
            virtual,
          },
          urlLink: urlLink,
        },
      });
    }
  };

  const handleActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveSchedule(event.target.checked);
  };

  const handlePublic = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPublicSchedule(event.target.checked);
  };

  const handleAccess = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccess((event.target as HTMLInputElement).value);
  };

  return (
    <Box>
      <Flex>
        <ArrowBackIcon sx={{ cursor: 'pointer', mr: 1, alignSelf: 'center' }} onClick={() => setScheduleView('list')} />
        <Typography sx={{ alignSelf: 'center', mb: 0 }} variant={'h5'} gutterBottom>
          <strong>{schedule ? schedule.name : `Create ${tabValue === 'colourSelection' ? 'Decor' : 'Regular'} Schedule`}</strong>
        </Typography>
      </Flex>
      <form onSubmit={createSchedule}>
        <Box sx={{ mt: 2 }}>
          <TextField
            name={'name'}
            onChange={(e: any) => setName(e.target.value)}
            value={name}
            required={true}
            label={'Schedule Name'}
            placeholder={'Enter Schedule Name'}
          />
        </Box>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Typography variant={'h4'}>
              <strong>Registration</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              multiline
              rows={3}
              name={'mainText'}
              onChange={(e: any) => setMainText(e.target.value)}
              value={mainText}
              required={true}
              label={'Front Page Text'}
              placeholder={'Enter Text'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              multiline
              rows={3}
              name={'endText'}
              onChange={(e: any) => setEndText(e.target.value)}
              value={endText}
              required={true}
              label={'Thank you Text'}
              placeholder={'Enter Text'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              multiline
              rows={3}
              name={'emailSubject'}
              onChange={(e: any) => setEmailSubject(e.target.value)}
              value={emailSubject}
              required={true}
              label={'Email Subject Line'}
              placeholder={'Enter Text'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              multiline
              rows={3}
              name={'emailMainText'}
              onChange={(e: any) => setEmailMainText(e.target.value)}
              value={emailMainText}
              required={true}
              label={'Email Main Text'}
              placeholder={'Enter Text'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              multiline
              rows={3}
              name={'emailEndText'}
              onChange={(e: any) => setEmailEndText(e.target.value)}
              value={emailEndText}
              required={true}
              label={'Email End Text'}
              placeholder={'Enter Text'}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h4'}>
              <strong>Cancellation</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              multiline
              rows={3}
              name={'cancelSubject'}
              onChange={(e: any) => setCancelSubject(e.target.value)}
              value={cancelSubject}
              required={true}
              label={'Cancel Email Subject Line'}
              placeholder={'Enter Text'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              multiline
              rows={3}
              name={'cancelMainText'}
              onChange={(e: any) => setCancelMainText(e.target.value)}
              value={cancelMainText}
              required={true}
              label={'Cancel Email Main Text'}
              placeholder={'Enter Text'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              multiline
              rows={3}
              name={'cancelEndText'}
              onChange={(e: any) => setCancelEndText(e.target.value)}
              value={cancelEndText}
              required={true}
              label={'Cancel Email End Text'}
              placeholder={'Enter Text'}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h4'}>
              <strong>Confirmation</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              multiline
              rows={3}
              name={'confirmSubject'}
              onChange={(e: any) => setConfirmSubject(e.target.value)}
              value={confirmSubject}
              required={true}
              label={'Confirm Email Subject Line'}
              placeholder={'Enter Text'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              multiline
              rows={3}
              name={'confirmMainText'}
              onChange={(e: any) => setConfirmMainText(e.target.value)}
              value={confirmMainText}
              required={true}
              label={'Confirm Email Main Text'}
              placeholder={'Enter Text'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              multiline
              rows={3}
              name={'confirmEndText'}
              onChange={(e: any) => setConfirmEndText(e.target.value)}
              value={confirmEndText}
              required={true}
              label={'Confirm Email End Text'}
              placeholder={'Enter Text'}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 2 }}>
          <FormControlLabel
            control={<Switch checked={activeSchedule} onChange={handleActive} inputProps={{ 'aria-label': 'controlled' }} />}
            label="Active"
          />
          <FormControlLabel
            control={
              <Switch checked={allowGuests} onChange={() => setAllowGuests(!allowGuests)} inputProps={{ 'aria-label': 'controlled' }} />
            }
            label="Allow Guests"
          />
          <FormControlLabel
            control={
              <Switch checked={notification} onChange={() => setNotification(!notification)} inputProps={{ 'aria-label': 'controlled' }} />
            }
            label="Notify if Appointments are full"
          />
          <FormControlLabel
            control={<Switch checked={publicSchedule} onChange={handlePublic} inputProps={{ 'aria-label': 'controlled' }} />}
            label="Public"
          />
          {!publicSchedule ? (
            <FormControl style={{ flexDirection: 'row', alignItems: 'center' }}>
              <RadioGroup row name="access" value={access} onChange={handleAccess}>
                {accessTypes.map((access, index) => (
                  <FormControlLabel value={access} key={index} control={<Radio color="primary" />} label={capitalizeFirstLetter(access)} />
                ))}
              </RadioGroup>
            </FormControl>
          ) : null}
        </Box>
        {schedule && !publicSchedule ? (
          <Box>
            <Box>
              <strong>
                URL:{' '}
                {process.env.REACT_APP_ENV === 'local'
                  ? `localhost:3001/${urlName(project.developerName)}/${urlName(project.name)}/public/book-an-appointment/${schedule._id}`
                  : `https://portal.rdsre.ca/${urlName(project.developerName)}/${urlName(project.name)}/public/book-an-appointment/${
                      schedule._id
                    }`}
              </strong>
            </Box>
            {schedule && schedule.shortUrl ? (
              <Box>
                <strong>
                  Short URL:{' '}
                  {process.env.REACT_APP_ENV === 'local'
                    ? `localhost:3001/book/${schedule.shortUrl}`
                    : `https://portal.rdsre.ca/book/${schedule.shortUrl}`}
                </strong>
              </Box>
            ) : null}
          </Box>
        ) : null}
        <Grid sx={{ mt: 1 }} container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              name={'shortUrl'}
              onChange={(e: any) => setShortUrl(e.target.value)}
              value={shortUrl}
              label={'Short Url'}
              fullWidth
              placeholder={'Enter Short Url'}
            />
          </Grid>
          {allowGuests ? (
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label={'Max Number of Guests Allowed'}
                name={'maxGuests'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMaxGuests(e.target.value)}
                value={maxGuests}
              />
            </Grid>
          ) : null}
        </Grid>
        <Box sx={{ mt: 2, p: 2, backgroundColor: '#fff', border: '1px solid #000', borderRadius: '8px' }}>
          <Location
            setLocations={setLocations}
            locations={locations}
            hideLocation={hideLocation}
            setHideLocation={setHideLocation}
            virtual={virtual}
            setVirtual={setVirtual}
            urlLink={urlLink}
            setUrlLink={setUrlLink}
          />
        </Box>
        <Box sx={{ mt: 2, p: 2, backgroundColor: '#fff', border: '1px solid #000', borderRadius: '8px' }}>
          <Calendar dates={datesState} datesDispatch={datesDispatch} />
        </Box>
        <Box sx={{ mt: 2, p: 2, backgroundColor: '#fff', border: '1px solid #000', borderRadius: '8px' }}>
          <Questions questions={questionsState} parentQuestions={parentQuestions} questionsDispatch={questionsDispatch} />
        </Box>
        <Box sx={{ mt: 2, p: 2, backgroundColor: '#fff', border: '1px solid #000', borderRadius: '8px' }}>
          <Typography variant={'h5'} gutterBottom>
            <strong>Emails</strong>
          </Typography>
          <p>Emails below will receive an email when a user has booked an appointment. </p>
          <TextField
            fullWidth
            label={'Emails (Ex. johndoe123@gmail.com, matthewl@rdsre.ca'}
            name={'emails'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmails(e.target.value)}
            value={emails}
          />
        </Box>
        <Button sx={{ mt: 2 }} type="submit" variant="contained" color="success">
          {createLoading || updateLoading ? <CircularProgress size={24} /> : schedule ? 'Update Schedule' : 'Create Schedule'}
        </Button>
      </form>
    </Box>
  );
};

interface ChildProps {
  tabValue: string;
  schedule: ISchedule | null;
  setScheduleView: Dispatch<SetStateAction<string>>;
  schedules: ISchedule[];
  setSchedules: Dispatch<SetStateAction<ISchedule[]>>;
}

export interface IDate {
  date: Date;
  length: number;
  timeBetweenAppointments: number;
  timeStart: number;
  timeEnd: number;
  numberOfAppointments: number;
}

const CREATESCHEDULE = gql`
  mutation createSchedule($record: CreateOneScheduleInput, $urlLink: String) {
    createSchedule(record: $record, urlLink: $urlLink) {
      _id
      active
      allowGuests
      maxGuests
      notification
      public
      schedules {
        date
        length
        timeBetweenAppointments
        timeStart
        timeEnd
        numberOfAppointments
      }
      questions {
        _id
        name
        question
        type
        subQuestion
        display
        choices {
          choice
          followUp {
            question
            _id
          }
        }
      }
      locations
      type
      access
      mainText
      endText
      emailEndText
      emailMainText
      emailSubject
      cancelEndText
      cancelMainText
      cancelSubject
      confirmEndText
      confirmMainText
      confirmSubject
      name
      emails
      hideLocation
      shortUrl
      virtual
    }
  }
`;

const UPDATESCHEDULE = gql`
  mutation scheduleUpdateById($_id: MongoID!, $record: UpdateByIdScheduleInput!) {
    scheduleUpdateById(_id: $_id, record: $record) {
      record {
        _id
        active
        allowGuests
        maxGuests
        notification
        public
        schedules {
          date
          length
          timeBetweenAppointments
          timeStart
          timeEnd
          numberOfAppointments
        }
        questions {
          _id
          name
          question
          type
          subQuestion
          display
          choices {
            choice
            followUp {
              question
              _id
            }
          }
        }
        locations
        type
        access
        mainText
        endText
        emailEndText
        emailMainText
        emailSubject
        cancelEndText
        cancelMainText
        cancelSubject
        confirmEndText
        confirmMainText
        confirmSubject
        name
        emails
        hideLocation
        shortUrl
        virtual
      }
    }
  }
`;

const EDITCALENDAR = gql`
  mutation editCalendar(
    $calendarId: String
    $eventId: String
    $summary: String!
    $start: Date!
    $end: Date!
    $location: String
    $attendees: [NewAttendeesInput]
    $googleMeetsLink: Boolean!
    $type: String!
  ) {
    editCalendar(
      calendarId: $calendarId
      eventId: $eventId
      summary: $summary
      start: $start
      end: $end
      location: $location
      attendees: $attendees
      googleMeetsLink: $googleMeetsLink
      type: $type
    ) {
      id
    }
  }
`;

const GETPROJECTSETTINGS = gql`
  query crmProjectSettingOne($filter: FilterFindOneCrmProjectSettingInput) {
    crmProjectSettingOne(filter: $filter) {
      questions {
        _id
        name
        question
        type
        subQuestion
        display
        choices {
          choice
          followUp {
            question
            _id
          }
        }
      }
      sales {
        _id
        fullName
        email
      }
      admins {
        _id
        fullName
        email
      }
      online {
        _id
        fullName
        email
      }
    }
  }
`;

export default CreateSchedule;
