import { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { gql, useQuery } from '@apollo/client';
import { Box, Button, TextField, InputAdornment, FormControlLabel, Checkbox } from '@mui/material';

import DealDeposits from '../../DealDeposits';
import Dropdown from '../../../common/formControls/Dropdown';
import { useAppDispatch } from '../../../../app/hooks';
import { showErrorSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { UnitContext } from '../../../../context/UnitContext';
import { DealContext } from '../../../../context/DealContext';
import { IDealDeposit } from '../../../../types/CreateDealForm';
import { selectProject } from '../../../../features/project/projectSlice';
import { IMerge, IMergeData } from '../../../../types/merge';
import { createPdf } from '../../../../utils/Functions';
import { FlexBetween } from '../../../../commonStyles';

const PendingDeposits = (props: ChildProps) => {
  const { setErrors, errors } = props;
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  const { unit, filteredDeal } = useContext(UnitContext);
  const {
    pendingDeposits,
    setPendingDeposits,
    pendingBasePrice,
    setPendingBasePrice,
    selectedDeposit,
    setSelectedDeposit,
    createDepositAmendment,
    depositList,
    addPendingDeposits,
  } = useContext(DealContext);

  const [depositMerges, setDepositMerges] = useState<any[]>([]);
  const [selectedDepositMerge, setSelectedDepositMerge] = useState('');
  const [changeBasePrice, setChangeBasePrice] = useState<boolean>(false);

  useQuery<IMergeData>(MERGETEMPLATES, {
    variables: { filter: { project: project._id, type: 'Deposit' } },
    onCompleted: (data) => {
      setDepositMerges(data.mergeTemplateMany);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handlePendingSubmit = async (e: any) => {
    e.preventDefault();
    if (errors) {
      storeDispatch(showErrorSnackbar(`Invalid Date`));
      return;
    }
    if (!selectedDeposit) {
      storeDispatch(showErrorSnackbar(`No Deposit Structure Selected`));
      return;
    }
    if (pendingDeposits.length === 0) {
      storeDispatch(showErrorSnackbar(`No Deposits Set`));
      return;
    }

    let pending = pendingDeposits.map((pendingDeposit: IDealDeposit, index: number) => {
      let chequeType =
        pendingDeposit.dateType === '90 Days Before Firm Occupancy' ||
        pendingDeposit.dateType === '120 Days Before Firm Occupancy' ||
        pendingDeposit.dateType === '180 Days Before Firm Occupancy' ||
        pendingDeposit.dateType === 'Floating Date' ||
        (pendingDeposits.length < 15 &&
          filteredDeal.deposit[index] &&
          filteredDeal.deposit[index].name === pendingDeposit.name &&
          filteredDeal.deposit[index].chequeType === 'floating')
          ? 'floating'
          : '';
      if (
        filteredDeal.deposit[index] &&
        pendingDeposit.amount === filteredDeal.deposit[index].amount &&
        pendingDeposit.name === filteredDeal.deposit[index].name &&
        new Date(pendingDeposit.dueDate!).setHours(0, 0, 0, 0) === new Date(filteredDeal.deposit[index].dueDate).setHours(0, 0, 0, 0)
      ) {
        return {
          accountNumber: filteredDeal.deposit[index].accountNumber,
          chequeAmount: filteredDeal.deposit[index].chequeAmount,
          chequeDate: filteredDeal.deposit[index].chequeDate,
          chequeNumber: filteredDeal.deposit[index].chequeNumber,
          chequeType: filteredDeal.deposit[index].chequeType ? filteredDeal.deposit[index].chequeType : chequeType,
          amount: filteredDeal.deposit[index].amount,
          deal: filteredDeal._id,
          dueDate: filteredDeal.deposit[index].dueDate,
          dateType: pendingDeposit.dateType ? pendingDeposit.dateType : null,
          name: filteredDeal.deposit[index].name,
        };
      } else {
        return {
          accountNumber: pendingDeposit.accountNumber,
          amount: pendingDeposit.amount,
          deal: filteredDeal._id,
          dueDate: pendingDeposit.dueDate,
          name: pendingDeposit.name,
          chequeType: chequeType,
          dateType: pendingDeposit.dateType ? pendingDeposit.dateType : null,
        };
      }
    });

    let depositAmendment = depositMerges.find((merge: IMerge) => {
      return merge.name === selectedDepositMerge;
    });

    if (!depositAmendment) {
      storeDispatch(showErrorSnackbar(`No Deposit Amendment found`));
      return;
    }

    let pdf = await createPdf(
      depositAmendment,
      project,
      unit,
      filteredDeal.purchasers,
      filteredDeal.deposit,
      filteredDeal.options,
      null,
      null,
      filteredDeal.options,
      pendingDeposits,
      { ...filteredDeal, pendingBasePrice: pendingBasePrice }
    );

    let sign = depositAmendment?.signFields.map((sign: any) => {
      return {
        index: sign.index,
        key: sign.key,
        pageNumber: sign.pageNumber,
        x: sign.x,
        y: sign.y,
      };
    });

    const args = {
      status: 'Prepared',
      name: depositAmendment?.name,
      project: project._id,
      deal: filteredDeal._id,
      signFields: sign,
      // signers,
      type: 'Deposit',
      isAPS: false,
      mergeTemplate: depositAmendment?._id,
    };

    await createDepositAmendment({
      variables: {
        type: 'deposit',
        dealId: filteredDeal._id,
        records: pending,
        record: args,
        file: pdf,
        pendingBasePrice: pendingBasePrice ? parseInt(pendingBasePrice, 10) : null,
        depositName: selectedDeposit,
      },
    });

    // await createDepositAmendment({ variables: { record: args, file: pdf } });
    // await createDeposits({ variables: { records: pending } });
  };

  const handlePendingDepositsArray = (array: IDealDeposit[]) => {
    setPendingDeposits(array);
  };

  return (
    <form onSubmit={handlePendingSubmit}>
      <h2>Pending Deposits</h2>
      <FormControlLabel
        sx={{ mb: 2 }}
        control={
          <Checkbox
            name="changeBasePrice"
            color="primary"
            checked={changeBasePrice}
            onClick={(e) => setChangeBasePrice(!changeBasePrice)}
          />
        }
        label={'Change Deal Base Price'}
      />
      {changeBasePrice ? (
        <TextField
          sx={{ mb: 2 }}
          title={'Change Base Price'}
          name={'basePrice'}
          fullWidth
          type="number"
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          value={pendingBasePrice}
          label={'Change Base Price'}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPendingBasePrice(e.target.value);
          }}
        />
      ) : null}
      <Dropdown
        title={'Deposit Type'}
        menuList={depositList()}
        name={'depositType'}
        handleSelect={(event: any) => setSelectedDeposit(event.target.value)}
        value={selectedDeposit}
      />
      <DealDeposits
        deposit={pendingDeposits}
        basePrice={
          pendingBasePrice
            ? filteredDeal.options.reduce((a: any, b: any) => {
                return a + b.amount;
              }, parseInt(pendingBasePrice, 10))
            : filteredDeal!.totalPrice!
        }
        handleDepositsArray={handlePendingDepositsArray}
        disable={false}
        setErrors={setErrors}
      />
      <Box sx={{ mt: 2 }}>
        <Dropdown
          title={'Select Deposit Amendment'}
          menuList={depositMerges.map((deposit: IMerge) => deposit.name)}
          name={'deposit'}
          handleSelect={(e: any) => setSelectedDepositMerge(e.target.value)}
          value={selectedDepositMerge ? selectedDepositMerge : ''}
        />
      </Box>
      <FlexBetween sx={{ flexWrap: 'wrap', mt: 2 }}>
        <div>
          <Button onClick={() => addPendingDeposits()} name={'update'} color="primary" variant="contained">
            Add Pending Deposit
          </Button>
        </div>
        <Box sx={{ flexWrap: 'wrap' }}>
          <Button type="submit" name={'update'} color="success" variant="contained">
            Create Deposit Amendment
          </Button>
        </Box>
      </FlexBetween>
    </form>
  );
};

interface ChildProps {
  errors: any;
  setErrors: any;
}

const MERGETEMPLATES = gql`
  query mergeTemplateMany($filter: FilterFindManyMergeTemplateInput) {
    mergeTemplateMany(filter: $filter) {
      _id
      project {
        _id
      }
      name
      mergeFields {
        key
        index
        pageNumber
        x
        y
        fontSize
        format
        wrap
      }
      signFields {
        key
        index
        pageNumber
        x
        y
        name
      }
      default
      totalPages
      type
      getUrl
      putUrl
    }
  }
`;

export default PendingDeposits;
