import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ProjectsSummary from './ProjectsSummary';
import BasicTabs from '../common/BasicTabs';
import Summaries from './summary/Summaries';
import Project from './Project';
import { Flex } from '../../commonStyles';
import { useNavigate } from 'react-router-dom';
import Realtor from './realtor/Realtor';
import Envelopes from '../adminDashboard/envelopes/Envelopes';
import Deposits from '../adminDashboard/deposits/Deposits';

const AllProjects = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const tabs = [
    {
      label: 'Summaries',
      component: <Summaries />,
    },
    {
      label: 'Overall Report',
      component: <ProjectsSummary />,
    },
    {
      label: 'Realtor Summary',
      component: <Realtor />,
    },
    {
      label: 'Project Details',
      component: <Project />,
    },
    {
      label: 'Envelopes',
      component: <Envelopes/>,
    },
    {
      label: 'Deposits',
      component: <Deposits />,
    },
  ];

  return (
    <Box
      sx={{
        p: 2,
        height: '100vh',
        position: 'relative',
      }}
    >
      <Flex sx={{ mb: 2 }}>
        <Box sx={{ alignSelf: 'center' }}>
          <ArrowBackIcon sx={{ cursor: 'pointer', mr: 1, alignSelf: 'center', color: '#00142A' }} onClick={() => navigate(-1)} />
        </Box>
        <Typography variant="h2" sx={{ alignSelf: 'center' }}>
          <strong>Manager Report</strong>
        </Typography>
      </Flex>
      <BasicTabs tabs={tabs} sub={true} value={value} setValue={setValue} color={'#fff'} />
    </Box>
  );
};

export default AllProjects;
