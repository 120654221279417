import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Button,
  Divider,
  Card,
  CardActionArea,
  CardContent,
  Box,
  Grid,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useMergeQuery } from '../../features/merge/mergeHooks';
import { useAppDispatch } from '../../app/hooks';
import { useCreateActivity } from '../../features/activity/activityHooks';
import { selectProject, setMergeTemplate } from '../../features/project/projectSlice';
import { selectUser } from '../../features/auth/authSlice';
import { selectMerges, setMerges, setMerge, selectCount } from '../../features/merge/mergeSlice';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { IMerge } from '../../types/merge';
import { IMergeTemplates } from '../../types/project';
import PdfCard from '../common/PdfCard';
import { FlexBetween } from '../../commonStyles';
import { mergeType } from '../../utils/Constants';
import { Flex } from '../../commonStyles';

const CreateMerges = () => {
  const storeDispatch = useAppDispatch();
  const createActivity = useCreateActivity();
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const merges = useSelector(selectMerges);
  const count = useSelector(selectCount);
  const [selectedMergeType, setSelectedMergeType] = useState<string>('APS');
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  useMergeQuery(project._id, [selectedMergeType], pageNumber, 11, search);

  const [deleteMergeTemplate] = useMutation(DELETEMERGETEMPLATE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`${data.mergeTemplateDeleteById.name} has been deleted!`));
      let filteredMerge = merges.filter((merge: IMerge, index: number) => merge._id !== data.mergeTemplateDeleteById._id);
      storeDispatch(setMerges({ items: filteredMerge, count: filteredMerge.length }));
      createActivity({
        project: project._id,
        user: user._id,
        deal: null,
        title: `Delete Template`,
        content: `Template ${data.mergeTemplateDeleteById.name} has been deleted`,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [copyMerge] = useMutation(COPYMERGE, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`${data.copyMergeTemplate.name} has been created`));
      storeDispatch(setMerges({ items: [...merges, data.copyMergeTemplate], count: [...merges, data.copyMergeTemplate].length }));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [archiveMerge] = useMutation(UPDATEMERGETEMPLATE, {
    onCompleted: (data) => {
      let filteredMerges = merges.filter((merge: IMerge) => merge._id !== data.mergeTemplateUpdateById.record._id);
      storeDispatch(showSuccessSnackbar('Merge Template has been archived!'));
      storeDispatch(setMerges({ items: filteredMerges, count: filteredMerges.length }));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const setNewMerge = () => {
    storeDispatch(
      setMerge({
        _id: 0,
        projectId: 0,
        name: '',
        mergeFields: [],
        signFields: [],
        type: '',
        default: false,
      })
    );
  };

  const handleDelete = (id: string, name: string) => {
    let filteredMerge = project.mergeTemplates.filter((merge: IMergeTemplates, index: number) => merge.mergeTemplate?._id !== id);
    deleteMergeTemplate({ variables: { _id: id, deleteFile: true } });
    let selectedMerge = merges.find((merge: IMerge) => {
      return name === merge.name;
    });
    if (selectedMerge?.type === 'APS') {
      let filteredAPSTemplate = filteredMerge.map((filtered: IMergeTemplates) => {
        let apsTemplate = filtered.apsTemplates.filter((aps: any) => name !== aps.name);
        return {
          ...filtered,
          apsTemplates: apsTemplate,
        };
      });
      storeDispatch(setMergeTemplate(filteredAPSTemplate));
    } else {
      storeDispatch(setMergeTemplate(filteredMerge));
    }
  };

  const handleCopy = (id: string, title: string) => {
    copyMerge({ variables: { _id: id } });
  };

  const handleArchive = (id: string, title: string) => {
    archiveMerge({ variables: { _id: id, record: { type: 'Archive' } } });
  };

  return (
    <Box sx={{ p: 3 }}>
      <FlexBetween sx={{ flexWrap: 'wrap' }}>
        <Typography sx={{ mb: 2 }} variant={'h5'}>
          <strong>Merges</strong>
        </Typography>
        <Link
          style={{
            color: '#00142a',
            textDecoration: 'none',
          }}
          state={{
            floorplanLink: true,
          }}
          to={`/${project._id}/dashboard/editmerges/floorplans`}
        >
          <Button onClick={() => setNewMerge()} variant="contained" color="primary">
            Floorplan Merges
          </Button>
        </Link>
      </FlexBetween>
      <Flex sx={{ mb: 2, wrap: 'flexWrap' }}>
        <FormControl sx={{ width: '200px', mr: 1, alignSelf: 'center' }}>
          <InputLabel id="merge-template-search">Merge Template Type</InputLabel>
          <Select
            labelId="merge-template-search"
            value={selectedMergeType}
            label="Merge Template Type"
            onChange={(e) => {
              setPageNumber(0);
              setSelectedMergeType(e.target.value);
            }}
          >
            {mergeType.map((value: string, index: number) => {
              return (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ width: '300px', alignSelf: 'center' }}>
          <TextField
            title={'Search By Name'}
            name={'search'}
            fullWidth
            value={search}
            label={'Search By Name'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          />
        </FormControl>
      </Flex>
      <Grid container spacing={2}>
        <Grid item md={4} sm={6} xs={12}>
          <Card
            sx={{
              width: '100%',
              border: '1px solid #000',
            }}
          >
            <CardActionArea onClick={() => setNewMerge()}>
              <Link
                style={{
                  color: '#00142a',
                  textDecoration: 'none',
                }}
                state={{
                  floorplanLink: false,
                }}
                to={`/${project._id}/dashboard/create-merges/new`}
              >
                <Box
                  sx={{
                    height: '200px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AddCircleOutlineIcon fontSize={'large'} />
                </Box>
                <Divider />
                <CardContent
                  sx={{
                    padding: '10px',
                    '&:last-child': {
                      paddingBottom: '10px',
                    },
                  }}
                >
                  <Typography style={{ textAlign: 'center' }} component="h4">
                    Add A Merge
                  </Typography>
                </CardContent>
              </Link>
            </CardActionArea>
          </Card>
        </Grid>
        {merges
          ? merges.map((merge: IMerge, index: number) => {
              return (
                <Grid key={index} item md={4} sm={6} xs={12}>
                  <PdfCard
                    title={merge.name}
                    file={merge.getUrl}
                    id={merge._id}
                    handleDelete={handleDelete}
                    handleImage={setNewMerge}
                    handleCopy={handleCopy}
                    handleArchive={handleArchive}
                    download={false}
                    downloadIcon={true}
                    archiveIcon={merge.type !== 'Archive' ? true : false}
                  />
                </Grid>
              );
            })
          : null}
      </Grid>
      <Box sx={{ textAlign: 'center', mt: 2 }}>
        <Button disabled={!pageNumber} onClick={() => setPageNumber(pageNumber - 1)}>
          {'<'}
        </Button>
        <span>{pageNumber + 1}</span>
        <Button disabled={pageNumber + 1 === Math.ceil(count / 11)} onClick={() => setPageNumber(pageNumber + 1)}>
          {'>'}
        </Button>
      </Box>
    </Box>
  );
};

const UPDATEMERGETEMPLATE = gql`
  mutation mergeTemplateUpdateById($_id: MongoID!, $record: UpdateByIdMergeTemplateInput!) {
    mergeTemplateUpdateById(_id: $_id, record: $record) {
      record {
        _id
        name
        mergeFields {
          pageNumber
          index
          key
          fontSize
          format
          x
          y
          wrap
        }
        signFields {
          key
          index
          pageNumber
          x
          y
          name
        }
        default
        totalPages
        getUrl
        putUrl
        type
      }
    }
  }
`;

const DELETEMERGETEMPLATE = gql`
  mutation mergeTemplateDeleteById($_id: MongoID!, $deleteFile: Boolean!) {
    mergeTemplateDeleteById(_id: $_id, deleteFile: $deleteFile) {
      _id
      name
    }
  }
`;

const COPYMERGE = gql`
  mutation copyMergeTemplate($_id: MongoID!) {
    copyMergeTemplate(_id: $_id) {
      _id
      project {
        _id
      }
      name
      mergeFields {
        key
        index
        pageNumber
        x
        y
        fontSize
        format
        wrap
      }
      signFields {
        key
        index
        pageNumber
        x
        y
        name
      }
      totalPages
      type
      getUrl
      putUrl
    }
  }
`;

export default CreateMerges;
