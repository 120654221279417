import { IUnit } from '../types/unit';

export const soldStatuses = ['F', 'C', 'O', 'S'];

export const unitStatusTitles = [
  {
    name: 'Available',
    code: 'A',
    color: '#ffffff',
  },
  {
    name: 'Designated',
    code: 'D',
    color: '#9b5f5f',
  },
  {
    name: 'Corporate Hold',
    code: 'CH',
    color: '#CCC0DA',
  },
  {
    name: 'Marketing Hold',
    code: 'M',
    color: '#D9D9D9',
  },
  {
    name: 'Unit Transfer',
    code: 'UT',
    color: '#5077d0',
  },
  {
    name: 'Worksheet',
    code: 'W',
    color: '#fb00ff',
  },
  {
    name: 'Worksheet Approved',
    code: 'WA',
    color: '#fd73ff',
  },
  {
    name: 'Prepping',
    code: 'P',
    color: '#ffaeae',
  },
  {
    name: 'Out for Signing',
    code: 'O',
    color: '#E6B8B7',
  },
  {
    name: 'In Person Signing',
    code: 'IP',
    color: '#6e7ff9',
  },
  {
    name: 'Signed',
    code: 'S',
    color: '#ac71dd',
  },
  {
    name: 'Conditional',
    code: 'C',
    color: '#92D050',
  },
  {
    name: 'Firm',
    code: 'F',
    color: '#00B0F0',
  },
];

export const unitTypes = [
  'Studio',
  'JR1BR',
  '1BR',
  '1BR(I)',
  '1BR+M',
  '1BR+F',
  '1BR+D',
  '1BR+D(I)',
  '1BR+M(I)',
  '2BR',
  '2BR(I)',
  '2BR+M',
  '2BR+F',
  'JR2',
  '2BR+D',
  '2BR+D(I)',
  '2BR+M(I)',
  '3BR',
  '3BR(I)',
  '3BR+M',
  '3BR+F',
  '3BR+D',
  '4BR',
  'TH',
  'C',
];

export const adjustmentTypes = ['Charge', 'Credit', 'ChargePerDeposit'];

export const adjustmentNameTypes = ['FreeMaintenance'];

export const constructionTypes = ['Floor Plan Change', 'Decor Request'];

export const menus = [
  {
    title: 'Price Grid',
    icon: 'GridOnIcon',
    link: 'dashboard/price-grid',
    subMenu: false,
    permission: 'priceGrid',
    color: '#33d10a',
  },
  {
    title: 'Worksheets',
    icon: 'WorksheetIcon',
    link: 'dashboard/worksheets',
    subMenu: false,
    permission: '',
    color: '#f44848',
  },
  {
    title: 'Reports',
    icon: 'InsertChartIcon',
    link: 'dashboard/reports',
    subMenu: true,
    color: '#00b0f0',
    subMenuList: [
      {
        title: 'Closing Report',
        link: 'closing-report',
        icon: 'SummarizeIcon',
      },
      {
        title: 'Unit Report',
        link: 'unit-report',
        icon: 'AssessmentIcon',
      },
      {
        title: 'Daily Summary Report',
        link: 'daily-report',
        icon: 'AnalyticsIcon',
      },
      {
        title: 'Deposits Report',
        link: 'deposit-report',
        icon: 'AssignmentIcon',
      },
      {
        title: 'Realtors Report',
        link: 'realtors-report',
        icon: 'QueryStatsIcon',
      },
      {
        title: 'Purchasers Report',
        link: 'purchasers-report',
        icon: 'InsightsIcon',
      },
      {
        title: 'Worksheets Report',
        link: 'worksheet-report',
        icon: 'LeaderboardIcon',
      },
      {
        title: 'Construction Report',
        link: 'construction-report',
        icon: 'ConstructionIcon',
      },
      {
        title: 'Decor Report',
        link: 'decor-report',
        icon: 'HouseIcon',
      },
      {
        title: 'Combined Report',
        link: 'combined-report',
        icon: 'TableIcon',
      },
    ],
    permission: 'viewReports',
  },
  {
    title: 'Outstandings',
    icon: 'ReceiptIcon',
    link: 'dashboard/outstandings',
    subMenu: false,
    permission: 'viewOutstandings',
    color: '#f6ce26',
  },
  {
    title: 'Distribution',
    icon: 'DistributionIcon',
    link: 'dashboard/distribution',
    subMenu: false,
    permission: '',
    color: '#ac71dd',
  },
  {
    title: 'Activity',
    icon: 'CommentIcon',
    link: 'dashboard/activity',
    subMenu: false,
    permission: 'viewActivities',
    color: '#6e7ff9',
  },
  {
    title: 'In Person Signing',
    icon: 'TabletIcon',
    link: 'signing-portal',
    subMenu: false,
    permission: '',
    color: '#000',
  },
  {
    title: 'Create Realtor',
    icon: 'PeopleIcon',
    link: 'dashboard/add-realtor',
    subMenu: false,
    permission: '',
    color: '#9b5f5f',
  },
  {
    title: 'Create Purchaser',
    icon: 'PeopleIcon',
    link: 'dashboard/add-purchaser',
    subMenu: false,
    permission: '',
    color: '#9b5f5f',
  },
  {
    title: 'Create/Edit Merges',
    icon: 'InsertChartIcon',
    link: 'dashboard/create-merges',
    subMenu: false,
    permission: 'merges',
    color: '#fd73ff',
  },
  {
    title: 'Project Settings',
    icon: 'SettingsIcon',
    link: 'dashboard/project-settings',
    subMenu: false,
    permission: 'projectSettings',
  },
  {
    title: 'Resources',
    icon: 'BackupIcon',
    link: 'dashboard/resources',
    subMenu: false,
    permission: '',
    color: '#06384a',
  },
  {
    title: 'Faqs',
    icon: 'Faqs',
    link: 'dashboard/faqs',
    subMenu: false,
    permission: '',
    color: '#fb00ff',
  },
];

export const signItems = [
  {
    name: 'P1Sign',
    index: 0,
    type: 'signature',
  },
  {
    name: 'P1CorpSign',
    index: 0,
    type: 'corpSignature',
  },
  {
    name: 'P1NonCorpSign',
    index: 0,
    type: 'nonCorpSignature',
  },
  {
    name: 'P1CorpI',
    index: 0,
    type: 'corpSignature',
  },
  {
    name: 'P1NonCorpI',
    index: 0,
    type: 'nonCorpSignature',
  },
  {
    name: 'P1CorpDate',
    index: 0,
    type: 'date',
  },
  {
    name: 'P1NonCorpDate',
    index: 0,
    type: 'nonCorpSignature',
  },
  {
    name: 'P1I',
    index: 0,
    type: 'initial',
  },
  {
    name: 'P1Date',
    index: 0,
    type: 'date',
  },
  {
    name: 'P2Sign',
    index: 1,
    type: 'signature',
  },
  {
    name: 'P2CorpSign',
    index: 1,
    type: 'corpSignature',
  },
  {
    name: 'P2NonCorpSign',
    index: 1,
    type: 'nonCorpSignature',
  },
  {
    name: 'P2CorpI',
    index: 1,
    type: 'corpInitial',
  },
  {
    name: 'P2NonCorpI',
    index: 1,
    type: 'nonCorpInitial',
  },
  {
    name: 'P2CorpDate',
    index: 1,
    type: 'date',
  },
  {
    name: 'P2NonCorpDate',
    index: 1,
    type: 'date',
  },
  {
    name: 'P2I',
    index: 1,
    type: 'initial',
  },
  {
    name: 'P2Date',
    index: 1,
    type: 'date',
  },
  {
    name: 'P3Sign',
    index: 2,
    type: 'signature',
  },
  {
    name: 'P3CorpSign',
    index: 2,
    type: 'corpSignature',
  },
  {
    name: 'P3NonCorpSign',
    index: 2,
    type: 'nonCorpSignature',
  },
  {
    name: 'P3I',
    index: 2,
    type: 'initial',
  },
  {
    name: 'P3Date',
    index: 2,
    type: 'date',
  },
  {
    name: 'P4Sign',
    index: 3,
    type: 'signature',
  },
  {
    name: 'P4I',
    index: 3,
    type: 'initial',
  },
  {
    name: 'P4CorpSign',
    index: 3,
    type: 'corpSignature',
  },
  {
    name: 'P4NonCorpSign',
    index: 3,
    type: 'nonCorpSignature',
  },
  {
    name: 'P4Date',
    index: 3,
    type: 'date',
  },
  {
    name: 'ExSign',
    index: -1,
    type: 'signature',
  },
  {
    name: 'ExI',
    index: -1,
    type: 'initial',
  },
  {
    name: 'ExDate',
    index: -1,
    type: 'date',
  },
  {
    name: 'RealSign',
    index: -2,
    type: 'signature',
  },
  {
    name: 'RealI',
    index: -2,
    type: 'initial',
  },
  {
    name: 'RealDate',
    index: -2,
    type: 'date',
  },
  {
    name: 'ExName',
    index: -1,
    type: 'name',
  },
  {
    name: 'SO1Sign',
    index: -3,
    type: 'signature',
  },
  {
    name: 'SO2Sign',
    index: -4,
    type: 'signature',
  },
  {
    name: 'SO1I',
    index: -3,
    type: 'initial',
  },
  {
    name: 'SO2I',
    index: -4,
    type: 'initial',
  },
  {
    name: 'WitnessSign',
    index: -6,
    type: 'signature',
  },
  {
    name: 'WitnessI',
    index: -6,
    type: 'initial',
  },
  {
    name: 'WitnessDate',
    index: -6,
    type: 'date',
  },
  {
    name: 'WitnessName',
    index: -6,
    type: 'name',
  },
  {
    name: 'WitnessSign2',
    index: -7,
    type: 'signature',
  },
  {
    name: 'WitnessI2',
    index: -7,
    type: 'initial',
  },
  {
    name: 'WitnessDate2',
    index: -7,
    type: 'date',
  },
  {
    name: 'WitnessName2',
    index: -7,
    type: 'name',
  },
  {
    name: 'WitnessSign3',
    index: -8,
    type: 'signature',
  },
  {
    name: 'WitnessI3',
    index: -8,
    type: 'initial',
  },
  {
    name: 'WitnessDate3',
    index: -8,
    type: 'date',
  },
  {
    name: 'WitnessName3',
    index: -8,
    type: 'name',
  },
  {
    name: 'WitnessSign4',
    index: -9,
    type: 'signature',
  },
  {
    name: 'WitnessI4',
    index: -9,
    type: 'initial',
  },
  {
    name: 'WitnessDate4',
    index: -9,
    type: 'date',
  },
  {
    name: 'WitnessName4',
    index: -9,
    type: 'name',
  },
  {
    name: 'AccountHolderName',
    index: -10,
    type: 'name',
  },
  {
    name: 'AccountHolderSign',
    index: -10,
    type: 'signature',
  },
  {
    name: 'AccountHolderI',
    index: -10,
    type: 'initial',
  },
  {
    name: 'AccountHolderDate',
    index: -10,
    type: 'date',
  },
  {
    name: 'AccountHolderName2',
    index: -11,
    type: 'name',
  },
  {
    name: 'AccountHolderSign2',
    index: -11,
    type: 'signature',
  },
  {
    name: 'AccountHolderI2',
    index: -11,
    type: 'initial',
  },
  {
    name: 'AccountHolderDate2',
    index: -11,
    type: 'date',
  },
  {
    name: 'AcknowledgementP1Sign',
    index: 0,
    type: 'acknowledgementSignature',
  },
  {
    name: 'AcknowledgementP1Date',
    index: 0,
    type: 'acknowledgementDate',
  },
  {
    name: 'AcknowledgementP2Sign',
    index: 1,
    type: 'acknowledgementSignature',
  },
  {
    name: 'AcknowledgementP2Date',
    index: 0,
    type: 'acknowledgementDate',
  },
  {
    name: 'AcknowledgementExSign',
    index: -1,
    type: 'acknowledgementSignature',
  },
  {
    name: 'AcknowledgementCorpP1Sign',
    index: 0,
    type: 'acknowledgementCorpSignature',
  },
  {
    name: 'AcknowledgementCorpP2Sign',
    index: 1,
    type: 'acknowledgementCorpSignature',
  },
];

export const mergeItems = [
  {
    name: 'Suite',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Unit',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Level',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'ModelType',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'ModelName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Tier',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1P2PfullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1P2PfullNameLCaps',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1P3PfullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '2P3PfullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '3P4PfullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1P2P3PfullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'AllPfullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'AllPfullNameLCaps',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PFN',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PLN',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PLNCaps',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PfullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PfullNameLCaps',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '&',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'FullAddress',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Address',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'City',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Province',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'PCode',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Country',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Email',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'SIN',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Dob',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'SignDate',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'ExecuteDate',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Phone',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Occupation',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Company',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'IDType',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'IDJurisdiction',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'IDNumber',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'IDExpiry',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: '2PFN',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PLN',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PLNCaps',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PfullName',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PfullNameLCaps',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PDob',
    fontSize: 9,
    index: 1,
    type: 'merge',
    format: 'P',
  },
  {
    name: '2PSIN',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PPhone',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PFullAddress',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PAddress',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PCity',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PProvince',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PPCode',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PCountry',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PEmail',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PIDNumber',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '1PNonCorpFN',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpLN',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpLNCaps',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpfullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PfullNameNonCorpLCaps',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpDob',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: '1PNonCorpSIN',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpPhone',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpFullAddress',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpAddress',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpCity',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpProvince',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpPCode',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpCountry',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpEmail',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },

  {
    name: '1PNonCorpFN',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpLN',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpLNCaps',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpfullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PfullNameNonCorpLCaps',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpDob',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: '1PNonCorpSIN',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpPhone',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpFullAddress',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpAddress',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpCity',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpProvince',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpPCode',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpCountry',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '1PNonCorpEmail',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '2PNonCorpFN',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PNonCorpLN',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PNonCorpLNCaps',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PNonCorpfullName',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PfullNameNonCorpLCaps',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PNonCorpDob',
    fontSize: 9,
    index: 1,
    type: 'merge',
    format: 'P',
  },
  {
    name: '2PNonCorpSIN',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PNonCorpPhone',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PNonCorpFullAddress',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PNonCorpAddress',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PNonCorpCity',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PNonCorpProvince',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PNonCorpPCode',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PNonCorpCountry',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '2PNonCorpEmail',
    fontSize: 9,
    index: 1,
    type: 'merge',
  },
  {
    name: '3PFN',
    fontSize: 9,
    index: 2,
    type: 'merge',
  },
  {
    name: '3PLN',
    fontSize: 9,
    index: 2,
    type: 'merge',
  },
  {
    name: '3PLNCaps',
    fontSize: 9,
    index: 2,
    type: 'merge',
  },
  {
    name: '3PfullName',
    fontSize: 9,
    index: 2,
    type: 'merge',
  },
  {
    name: '3PfullNameLCaps',
    fontSize: 9,
    index: 2,
    type: 'merge',
  },
  {
    name: '3PDob',
    fontSize: 9,
    index: 2,
    type: 'merge',
    format: 'P',
  },
  {
    name: '3PSIN',
    fontSize: 9,
    index: 2,
    type: 'merge',
  },
  {
    name: '3PPhone',
    fontSize: 9,
    index: 2,
    type: 'merge',
  },
  {
    name: '3PFullAddress',
    fontSize: 9,
    index: 2,
    type: 'merge',
  },
  {
    name: '3PAddress',
    fontSize: 9,
    index: 2,
    type: 'merge',
  },
  {
    name: '3PCity',
    fontSize: 9,
    index: 2,
    type: 'merge',
  },
  {
    name: '3PProvince',
    fontSize: 9,
    index: 2,
    type: 'merge',
  },
  {
    name: '3PPCode',
    fontSize: 9,
    index: 2,
    type: 'merge',
  },
  {
    name: '3PCountry',
    fontSize: 9,
    index: 2,
    type: 'merge',
  },
  {
    name: '3PEmail',
    fontSize: 9,
    index: 2,
    type: 'merge',
  },
  {
    name: '3PIDNumber',
    fontSize: 9,
    index: 2,
    type: 'merge',
  },
  {
    name: '4PFN',
    fontSize: 9,
    index: 3,
    type: 'merge',
  },
  {
    name: '4PLN',
    fontSize: 9,
    index: 3,
    type: 'merge',
  },
  {
    name: '4PLNCaps',
    fontSize: 9,
    index: 3,
    type: 'merge',
  },
  {
    name: '4PfullName',
    fontSize: 9,
    index: 3,
    type: 'merge',
  },
  {
    name: '4PfullNameLCaps',
    fontSize: 9,
    index: 3,
    type: 'merge',
  },
  {
    name: '4PDob',
    fontSize: 9,
    index: 3,
    type: 'merge',
    format: 'P',
  },
  {
    name: '4PSIN',
    fontSize: 9,
    index: 3,
    type: 'merge',
  },
  {
    name: '4PPhone',
    fontSize: 9,
    index: 3,
    type: 'merge',
  },
  {
    name: '4PFullAddress',
    fontSize: 9,
    index: 3,
    type: 'merge',
  },
  {
    name: '4PAddress',
    fontSize: 9,
    index: 3,
    type: 'merge',
  },
  {
    name: '4PCity',
    fontSize: 9,
    index: 3,
    type: 'merge',
  },
  {
    name: '4PProvince',
    fontSize: 9,
    index: 3,
    type: 'merge',
  },
  {
    name: '4PPCode',
    fontSize: 9,
    index: 3,
    type: 'merge',
  },
  {
    name: '4PCountry',
    fontSize: 9,
    index: 3,
    type: 'merge',
  },
  {
    name: '4PEmail',
    fontSize: 9,
    index: 3,
    type: 'merge',
  },
  {
    name: '4PIDNumber',
    fontSize: 9,
    index: 3,
    type: 'merge',
  },
  {
    name: 'SalesRep',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'ProjectAddress',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'ProjectAddressSuite',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Directors',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'SO1fullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'SO1FullAddress',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'SO1Dob',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'SO1Email',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'SO1SIN',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'SO1Phone',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'SO2fullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'SO2FullAddress',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'SO2Dob',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'SO2Email',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'SO2SIN',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'SO2Phone',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'SO3FullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'SO4FullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'SO1SO2fullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'MinMortgage',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'ConditionalP1CorpOrPurchaserFullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'ConditionalP2CorpOrPurchaserFullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'ConditionalCorpName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'ConditionalCorpDob',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'ConditionalCorpPhone',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'ConditionalCorpAddress',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'ConditionalCorpEmail',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'ConditionalCorpNumber',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'RealFN',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'RealLN',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'RealFullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'RealEmail',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'RealFullAddress',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'RealAddress',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'RealCity',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'RealProvince',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'RealPostCode',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'RealPhone',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'BrokeragePhone',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Brokerage',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'BrokerageFax',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionPercent1',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionPercent2',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionPercent3',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionPercent4',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionPercent5',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionPercent6',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionPercentAmount1',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionPercentAmount2',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionPercentAmount3',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionPercentAmount4',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionPercentAmount5',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionPercentAmount6',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionFixed1',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionFixed2',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionFixed3',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionFixed4',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionFixed5',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'CommissionFixed6',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit1W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit1',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit1Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit1%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit2W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit2',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit2Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit2%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit3W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit3',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit3Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit3%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit4W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit4',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit4Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit4%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit5W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit5',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit5Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit5%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit6W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit6',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit6Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit6%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit7W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit7',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit7Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit7%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit8W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit8',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit8Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit8%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit9W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit9',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit9Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit9%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit10W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit10',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit10Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit10%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit11W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit11',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit11Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit11%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit12W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit12',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit12Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit12%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit13W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit13',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit13Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit13%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit14W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit14',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit14Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit14%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit15W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit15',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit15Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit15%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit16W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit16',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit16Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit16%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit17W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit17',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit17Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit17%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit18W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit18',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit18Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit18%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit19W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit19',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit19Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit19%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit20W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit20',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit20Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit20%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit21W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit21',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit21Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit21%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit22W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit22',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit22Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit22%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit23W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit23',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit23Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit23%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit24W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit24',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit24Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit24%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit25W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit25',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit25Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit25%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit26W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit26',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit26Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit26%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit27W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit27',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit27Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit27%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit28W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit28',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit28Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit28%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit29W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit29',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit29Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit29%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit30W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit30',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit30Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit30%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit31W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit31',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit31Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit31%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit32W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit32',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit32Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit32%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit33W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit33',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit33Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit33%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit34W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit34',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit34Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit34%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit35W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit35',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit35Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit35%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit36W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit36',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit36Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit36%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit37W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit37',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit37Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit37%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit38W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit38',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit38Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit38%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit39W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit39',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit39Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit39%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit40W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit40',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit40Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit40%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit41W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit41',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit41Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit41%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit42W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit42',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Deposit42Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'Deposit42%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '4thLastDepositW',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: '4thLastDeposit',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '4thLastDepositDate',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: '4thLastDeposit%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '3rdLastDepositW',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: '3rdLastDeposit',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '3rdLastDepositDate',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: '3rdLastDeposit%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '2ndLastDepositW',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: '2ndLastDeposit',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '2ndLastDepositDate',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: '2ndLastDeposit%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'LastDepositW',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'LastDeposit',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'LastDepositDate',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'LastDeposit%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'NewDepositBasePrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'NewDepositTotalPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'UPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'UPriceW',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'NetUPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'TPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'TPriceW',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'NetTPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP1',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP1Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP1Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP2',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP2Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP2Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP3',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP3Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP3Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP4',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP4Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP4Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP5',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP5Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP5Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP6',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP6Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP6Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP7',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP7Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP7Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP8',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP8Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP8Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP9',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP9Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP9Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP10',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP10Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'OP10Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'totalParkingPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'totalParkingNum',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'totalParkingAmount',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'totalLockerPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'totalLockerNum',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'totalLockerAmount',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Rental',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Schedule A',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'H',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'J',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'K',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'P1Check',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'P2Check',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'P3Check',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'P4Check',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '3rdPartyName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '3rdPartyAddress',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '3rdPartyPhone',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '3rdPartyDob',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: '3rdPartyOccupation',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '3rdPartyCorp',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: '3rdPartyRelationship',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment1%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment1%Words',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment1$',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment1$Words',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment1$WordsAndNumber',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment2%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment2%Words',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment2$',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment2$Words',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment2$WordsAndNumber',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment3%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment3%Words',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment3$',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment3$Words',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment3$WordsAndNumber',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment4%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment4%Words',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment4$',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment4$Words',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Adjustment4$WordsAndNumber',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'P1Passport#',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'P1PermanentResidence#',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'P1BirthCertificate#',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'P1IndianStatus#',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'P1WorkPermit#',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'P1WorkPermitExpiry',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'P2Passport#',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'P2PermanentResidence#',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'P2BirthCertificate#',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'P2IndianStatus#',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'P2WorkPermit#',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'P2WorkPermitExpiry',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'PadName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'PadInstitution',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'PadBranch',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'PadAccount',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'MaintenanceFeeByMonths',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'MaintenanceFeeByMonthsWords',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'MaintenanceTermByMonths',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'MaintenanceTermByMonthsWords',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'MaintenanceFeeByAdjustment',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'MaintenanceFeeByAdjustmentWords',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'firstOccupancy',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'firstOccupancyDay',
    fontSize: 9,
    index: 0,
    format: 'do',
    type: 'merge',
  },
  {
    name: 'firstOccupancyMonth',
    fontSize: 9,
    index: 0,
    format: 'MMMM',
    type: 'merge',
  },
  {
    name: 'firstOccupancyYear',
    fontSize: 9,
    index: 0,
    format: 'yy',
    type: 'merge',
  },
  {
    name: 'finalOccupancy',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'finalOccupancyDay',
    fontSize: 9,
    index: 0,
    format: 'do',
    type: 'merge',
  },
  {
    name: 'finalOccupancyMonth',
    fontSize: 9,
    index: 0,
    format: 'MMMM',
    type: 'merge',
  },
  {
    name: 'finalOccupancyYear',
    fontSize: 9,
    index: 0,
    format: 'yy',
    type: 'merge',
  },
  {
    name: 'firmOccupancy',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'firmOccupancyDay',
    fontSize: 9,
    index: 0,
    format: 'do',
    type: 'merge',
  },
  {
    name: 'firmOccupancyMonth',
    fontSize: 9,
    index: 0,
    format: 'MMMM',
    type: 'merge',
  },
  {
    name: 'firmOccupancyYear',
    fontSize: 9,
    index: 0,
    format: 'yy',
    type: 'merge',
  },
  {
    name: 'outsideOccupancy',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'outsideOccupancyDay',
    fontSize: 9,
    index: 0,
    format: 'do',
    type: 'merge',
  },
  {
    name: 'outsideOccupancyMonth',
    fontSize: 9,
    index: 0,
    format: 'MMMM',
    type: 'merge',
  },
  {
    name: 'outsideOccupancyYear',
    fontSize: 9,
    index: 0,
    format: 'yy',
    type: 'merge',
  },
  {
    name: 'noticePeriod',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'noticePeriodDay',
    fontSize: 9,
    index: 0,
    format: 'do',
    type: 'merge',
  },
  {
    name: 'noticePeriodMonth',
    fontSize: 9,
    index: 0,
    format: 'MMMM',
    type: 'merge',
  },
  {
    name: 'noticePeriodYear',
    fontSize: 9,
    index: 0,
    format: 'yy',
    type: 'merge',
  },
  {
    name: 'terminationPeriod',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'terminationPeriodDay',
    fontSize: 9,
    index: 0,
    format: 'do',
    type: 'merge',
  },
  {
    name: 'terminationPeriodMonth',
    fontSize: 9,
    index: 0,
    format: 'MMMM',
    type: 'merge',
  },
  {
    name: 'terminationPeriodYear',
    fontSize: 9,
    index: 0,
    format: 'yy',
    type: 'merge',
  },
  {
    name: 'toP1FName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toP1LName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toP1FullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toP1Sin',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toP1Dob',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toP1Address',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toP1Phone',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toP1Email',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toP2FName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toP2LName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toP2FullName',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toP2Sin',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toP2Dob',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toP2Address',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toP2Phone',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toP2Email',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'oldPurchaserInformation1',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'newPurchaserInformation1',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'oldPurchaserInformation2',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'newPurchaserInformation2',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'oldPurchaserInformation3',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'newPurchaserInformation3',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'oldPurchaserInformation4',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'newPurchaserInformation4',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'oldPurchaserInformation5',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'newPurchaserInformation5',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toSuite',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toUnit',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toLevel',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toTier',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toRental',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toPriceW',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toTotalP',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toTotalPW',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP1',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP1Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP1Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP2',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP2Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP2Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP3',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP3Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP3Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP4',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP4Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP4Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP5',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP5Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP5Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP6',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP6Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP6Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP7',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP7Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP7Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP8',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP8Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP8Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP9',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP9Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP9Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP10',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP10Price',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toOP10Num',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit1',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit1W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit1%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit2',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit2W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit2Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'toDeposit2%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit3',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit3W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit3Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'toDeposit3%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit4',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit4W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit4Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'toDeposit4%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit5',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit5W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit5Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'toDeposit5%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit6',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit6W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit6Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'toDeposit6%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit7',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit7W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit7Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'toDeposit7%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit8',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit8W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit8Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'toDeposit8%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit9',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit9W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit9Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'toDeposit9%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit10',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit10W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit10Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'toDeposit10%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit11',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit11W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit11Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'toDeposit11%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit12',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit12W',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toDeposit12Date',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'toDeposit12%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'to4thLastDepositW',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'to4thLastDeposit',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'to4thLastDepositDate',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'to4thLastDeposit%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'to3rdLastDepositW',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'to3rdLastDeposit',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'to3rdLastDepositDate',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'to3rdLastDeposit%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'to2ndLastDepositW',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'to2ndLastDeposit',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'to2ndLastDepositDate',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'to2ndLastDeposit%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toLastDepositW',
    fontSize: 6,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toLastDeposit',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toLastDepositDate',
    fontSize: 9,
    index: 0,
    type: 'merge',
    format: 'P',
  },
  {
    name: 'toLastDeposit%',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toTotalParkingPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toTotalParkingNum',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toTotalParkingAmount',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toTotalLockerPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toTotalLockerNum',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'toTotalLockerAmount',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'UPG1TPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'UPG1TPriceNetHST',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'UPG1Credit',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'UPG2TPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'UPG2TPriceNetHST',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'UPG2Credit',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'UPG3TPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'UPG3TPriceNetHST',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'UPG3Credit',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'UPG1UPG2Total',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'UPG1UPG2TotalNet',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'UPG1UPG2UPG3Total',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'UPG1UPG2UPG3TotalNet',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'DecorSelection1TPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'DecorSelection1ToTPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'DecorSelection2TPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'DecorSelection2ToTPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'DecorSelection3TPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'DecorSelection3ToTPrice',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'DecorSelectionTotalNetHST',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'DecorSelectionTotal',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
];

export const floorplanMerges = [
  {
    name: 'Suite',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Unit',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
  {
    name: 'Level',
    fontSize: 9,
    index: 0,
    type: 'merge',
  },
];

export const idTypes = [
  "Driver's Licence",
  'Passport',
  'Permanent Resident Card',
  'Photo Card',
  'Citizenship Card',
  'Identity Card',
  'Articles of Incorporation',
];

export const proofTypes = ['Birth Certificate', 'Indian Status', 'Work Permit', 'Passport'];

export const idIssuedBy = [
  'Ontario',
  'Canada',
  'Quebec',
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Prince Edward Island',
  'Saskatchewan',
  'Yukon',
  'International Government',
];

export const createDealSteps = ['Purchaser Info', 'Realtor Info', 'Suite Info', 'APS', 'Review'];

export const noRealtorOptions = ['Friends and Family', 'Public'];

export const endvestor = ['Investor', 'End User', 'Both'];

export const amountType = [
  'Percentage',
  'Fixed',
  'Balance to 1.5%',
  'Balance to 2.5%',
  'Balance to 3%',
  'Balance to 5%',
  'Balance to 7.5%',
  'Balance to 10%',
  'Balance to 15%',
  'Balance to 10% Monthly',
  'Balance to 15% Monthly',
  'Balance to 15% Monthly (36 Months)',
];

export const dateType = [
  'Number of Days Due',
  'Specific Date',
  'Purchaser Signing Date',
  '1st of Every Month after 1st Deposit',
  '1st of Every Month after 2nd Deposit',
  '1st of Every Month after 3rd Deposit',
  'Every Month after 1st Deposit',
  'Every Month after 2nd Deposit',
  'Every Month after 3rd Deposit',
  'Floating Date',
  '90 Days Before Firm Occupancy',
  '120 Days Before Firm Occupancy',
  '180 Days Before Firm Occupancy',
];

export const mergeType = [
  'APS',
  'UnitTransfer',
  'Deposit',
  'Options',
  'PurchaserInformation',
  'AddP2',
  'AddP3',
  'AddP4',
  'AddP2P3',
  'AddP3P4',
  'Coop',
  'Decor',
  'Other',
  'Archive',
];

export const lenders = ['CIBC', 'BMO', 'TD', 'National Bank', 'RBC', 'ScotiaBank'];

export const tags = [
  'Friends and Family',
  'Public',
  'Special Pricing',
  'Free Parking',
  'Free Locker',
  'Special Incentive',
  'Credit Incentive',
  'EV Ready',
];

export const placeholders = [
  '{{Suite}}',
  '{{Unit}}',
  '{{Level}}',
  '{{1P First Name}}',
  '{{1P Last Name}}',
  '{{Address}}',
  '{{City}}',
  '{{Province}}',
  '{{Postal Code}}',
  '{{Country}}',
  '{{Email}}',
  '{{Date of Birth}}',
  '{{Day}}',
  '{{Month}}',
  '{{Year}}',
  '{{Phone Number}}',
  '{{2P First Name}}',
  '{{2P Last Name}}',
  '{{2P Date Of Birth}}',
  '{{2P SIN}}',
  '{{3P First Name}}',
  '{{3P Last Name}}',
  '{{3P Date Of Birth}}',
  '{{3P SIN}}',
  '{{Deposit 1 Numbers}}',
  '{{Deposit 1 Words}}',
  '{{Deposit 2 Numbers}}',
  '{{Deposit 2 Words}}',
  '{{Deposit 3 Numbers}}',
  '{{Deposit 3 Words}}',
  '{{Deposit 4 Numbers}}',
  '{{Deposit 4 Words}}',
  '{{Deposit 5 Numbers}}',
  '{{Deposit 5 Words}}',
  '{{Deposit 6 Numbers}}',
  '{{Deposit 6 Words}}',
  '{{Deposit 7 Numbers}}',
  '{{Deposit 7 Words}}',
  '{{Unit Price}}',
  '{{Total Price}}',
];

export const rangeInfo = [
  {
    min: 0,
    max: 599999,
  },
  {
    min: 600000,
    max: 799999,
  },
  {
    min: 800000,
    max: 999999,
  },
  {
    min: 1000000,
    max: 1199999,
  },
  {
    min: 1200000,
    max: 1399999,
  },
  {
    min: 1400000,
    max: 1599999,
  },
  {
    min: 1600000,
    max: 1799999,
  },
  {
    min: 1800000,
    max: 1999999,
  },
  {
    min: 2000000,
    max: 2199999,
  },
  {
    min: 2200000,
    max: 2399999,
  },
  {
    min: 2400000,
    max: 2599999,
  },
  {
    min: 2600000,
    max: 2799999,
  },
  {
    min: 2800000,
    max: 2999999,
  },
  {
    min: 3000000,
    max: 3199999,
  },
  {
    min: 3200000,
    max: 3399999,
  },
  {
    min: 3400000,
    max: 3599999,
  },
  {
    min: 3600000,
    max: 999999999,
  },
];

export const chequeTypes = [
  {
    value: 'Not Received',
    color: '#dfdfdf',
  },
  {
    value: 'Received',
    color: '#92ff92',
  },
  {
    value: 'Distributed',
    color: '#b4b4f3',
  },
  {
    value: 'Replacement',
    color: '#ffff99',
  },
  {
    value: 'Nsf',
    color: '#ff8888',
  },
  {
    value: 'Cleared',
    color: '#FF8C00',
  },
  {
    value: 'Wire',
    color: '#5eb0f7',
  },
  {
    value: 'Floating',
    color: '#ff99e9',
  },
  {
    value: 'Pad',
    color: '#8cffef',
  },
];

export const dates = ['P', 'PP', 'PPP', 'PPPP', 'M', 'MM', 'MMM', 'MMMM', 'yy', 'yyyy', 'D', 'Do', 'DD'];

export const taskLabels = [
  'Urgent',
  'Bug',
  'Quality of Life Improvement',
  'New Feature',
  'Add Purchaser',
  'Delete Purchaser',
  'Add Option',
  'Delete Option',
  'Cheques',
  'Deposit Structure',
  'Worksheet',
  'Amendment',
  'Unit Transfer',
  'Purchaser Information',
  'Audit',
  'E-Blast',
  'Package',
  'Event Prep',
  'Plan Changes',
  'Matrix Update',
  'Other',
];

export const outstandingChequeTypes: { [name: string]: string } = {
  received: '#92ff92',
  distributed: '#b4b4f3',
  replacement: '#ffff99',
  nsf: '#f9a460',
  notReceived: '#ff8888',
  cleared: '#FF8C00',
  wire: '#5eb0f7',
  floating: '#ff99e9',
  pad: '#8cffef',
};

export const chequeDescriptions: { [name: string]: string } = {
  received: 'We have received the cheque',
  distributed: 'The cheque has been distributed to the lawyers',
  replacement: 'The cheque needs to be replaced',
  nsf: 'The cheque has gone NSF',
  notReceived: 'Not Received',
  cleared: 'Cheque has been successfully deposited',
  wire: 'Provided/Providing wire transfer',
  floating: 'There is no specified due date for deposit',
  pad: 'Pre-Authorized Debit',
};

export const questionTypes = ['dropdown', 'checkbox', 'radio', 'text', 'conditionalDropdown'];

export const daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const displayTypes = ['public', 'authenticated', 'both'];

export const accessTypes = ['platinum', 'vip', 'regular'];

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const commentTypes = ['General', 'Mortgage', 'Deposit', 'Amendment', 'Decor'];

export const holidays = ['05/23/2022', '07/01/2022', '08/01/2022', '09/05/2022', '10/10/2022', '12/25/2022', '12/31/2022'];

export const dashboardTableTitle = ['Sales Summary', 'Inventory Summary', 'Total Unit Summary', 'Total Summary'];

export const mainCategories = [
  'Suite',
  'Kitchen',
  'Dining',
  'Living',
  'Primary Bedroom',
  '2nd Bedroom',
  '3rd Bedroom',
  'Den',
  'Primary Ensuite Bathroom',
  'Ensuite Bathroom',
  '2nd Ensuite Bathroom',
  'Main Bathroom',
  'Bathroom',
  '2nd Bathroom',
  'Powder Room',
  'Terrace',
  'Flooring',
  'Stairs',
  'Foyer',
  'Appliances',
  'Blinds',
  'Closets',
  'Baseboard Trim',
  'Extra',
  'Parking',
  'Miscellaneous',
  'Accessibility',
];

export const subCategories = [
  'Miscellaneous',
  'Kitchen',
  'Bathroom',
  'Ensuite Bathroom',
  'Primary Ensuite Bathroom',
  '2nd Ensuite Bathroom',
  'Primary Bedroom',
  'Powder Room',
  '2nd Bedroom',
  '3rd Bedroom',
  'Dining',
  'Foyer',
  'Living',
  'Den',
  'Flooring',
  'Terrace',
  'Lighting',
  'Receptacle',
  'Switch',
  'Electrical',
  'Wall',
  'Upper Cabinets',
  'Closets',
  'Appliance',
  'Credit',
  'Coordination',
  'Door Handles',
  'Paint',
  'Countertop & Backsplash',
  'Pulls',
  'Faucet',
  'Lower Cabinets',
  'Upper Cabinets',
  'Floor Tile',
  'Wall Tile',
  'Vanity Countertop',
  'Vanity Faucet',
  'Vanity & Medicine Cabinet',
  'Shower Sill',
  'Shower Faucet Set',
];

export const upgradeTypes = ['STD', 'UPG1', 'UPG2', 'UPG3', 'UPG4', 'UPG5', 'ALaCarte'];

export const schemes = ['Light Standard', 'Light Upgrade', 'Dark Standard', 'Dark Upgrade'];

export const revisions = ['Project', 'Suite', 'Address', 'Not Signed', 'No Letterhead', 'Amount', 'Other', 'No MPA Required'];

export const coopTypes = ['Days After Firm', 'Milestone/Fixed', 'Closing', 'Assignment'];

export const checklist = [
  {
    name: 'Review and action all emails and voicemails throughout the day.',
    completed: null,
    user: null,
  },
  {
    name: 'Firm deals when applicable.',
    completed: null,
    user: null,
  },
  {
    name: 'Prepare and schedule any distribution.',
    completed: null,
    user: null,
  },
  {
    name: 'Follow-up for any outstanding items to ensure files are broker complete.',
    completed: null,
    user: null,
  },
  {
    name: 'Prepare and send daily report.',
    completed: null,
    user: null,
  },
];

export const unitTypesColour = (unit: IUnit) => {
  if (unit.unitType === '3BR' || unit.unitType === '3BR(I)' || unit.unitType === '3BR+M' || unit.unitType === '3BR+F') {
    return '#ff8100';
  } else if (unit.unitType === '2BR+D' || unit.unitType === '2BR+D(I)' || unit.unitType === '2BR+M(I)') {
    return '#ffc181';
  } else if (
    unit.unitType === '2BR' ||
    unit.unitType === '2BR(I)' ||
    unit.unitType === '2BR+M' ||
    unit.unitType === '2BR+F' ||
    unit.unitType === 'JR2'
  ) {
    return '#e7b589';
  } else if (unit.unitType === '1BR+D' || unit.unitType === '1BR+D(I)' || unit.unitType === '1BR+M(I)') {
    return '#91f18f';
  } else if (unit.unitType === '1BR' || unit.unitType === '1BR(I)' || unit.unitType === '1BR+M' || unit.unitType === '1BR+F') {
    return '#c9ffc8';
  } else if (unit.unitType === 'Studio' || unit.unitType === 'JR1BR') {
    return '#c4f1fb';
  } else if (unit.unitType === '4BR') {
    return '#35f7c2';
  } else if (unit.unitType === 'TH') {
    return '#35eef7';
  }
};

export const exposures = [
  'North',
  'NorthEast',
  'NorthWest',
  'North (C)',
  'South',
  'SouthEast',
  'SouthWest',
  'South (C)',
  'East',
  'East (C)',
  'West',
  'West (C)',
  'NorthEastWest',
  'NorthSouthWest',
  'NorthSouthEast',
  'SouthEastWest',
];

export const outdoorTypes = ['Juliet', 'Balcony', 'Terrace', 'Courtyard'];

export const randomColors = [
  '#B22222',
  '#008000',
  '#5757e5',
  '#ffa500',
  '#b697cd',
  '#F08080',
  '#9ACD32',
  '#00FFFF',
  '#FFA07A',
  '#9370DB',
  '#A0522D',
  '#DAA520',
  '#DC143C',
  '#7FFF00',
  '#AFEEEE',
  '#FF6347',
  '#DEB887',
  '#BDB76B',
];

export const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: 'rgb(199 199 199)',
  borderStyle: 'dashed',
  backgroundColor: 'rgb(241 241 241)',
  color: '#00142a',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  margin: '20px 0',
  cursor: 'pointer',
};

export const activeStyle = {
  borderColor: '#2196f3',
};

export const acceptStyle = {
  borderColor: '#00e676',
};

export const rejectStyle = {
  borderColor: '#ff1744',
};

export const unitColumns = [
  {
    label: 'Status',
    id: 'status',
  },
  {
    label: 'Number of Suites',
    id: 'count',
  },
  {
    label: 'Size',
    id: 'size',
  },
  {
    label: 'Revenue',
    id: 'revenue',
  },
  {
    label: 'PPSF',
    id: 'ppsf',
  },
  {
    label: 'Revenue Net',
    id: 'revenueNet',
  },
  {
    label: 'PPSF Net',
    id: 'ppsfNet',
  },
];

export const optionColumns = [
  {
    label: 'Option',
    id: 'option',
  },
  {
    label: 'Current Price',
    id: 'currentPrice',
  },
  {
    label: 'Total Options',
    id: 'totalOptions',
  },
  {
    label: 'Available',
    id: 'available',
  },
  {
    label: 'Available Revenue',
    id: 'availableRevenue',
  },
  {
    label: 'Available Revenue Net Hst',
    id: 'availableRevenueNetHst',
  },
  {
    label: 'Pending Options',
    id: 'pendingOptions',
  },
  {
    label: 'Sold Options',
    id: 'soldOptions',
  },
  {
    label: 'Combined Options',
    id: 'combinedOptions',
  },
  {
    label: 'Pending Revenue',
    id: 'pendingRevenue',
  },
  {
    label: 'Sold Revenue',
    id: 'soldRevenue',
  },
  {
    label: 'Combined Revenue',
    id: 'combinedRevenue',
  },
  {
    label: 'Total PPSF',
    id: 'totalPPSF',
  },
  {
    label: 'Total Revenue',
    id: 'totalRevenue',
  },
];

export const totalColumns = [
  {
    label: 'Type',
    id: 'type',
  },
  {
    label: 'Sold Unit Revenue',
    id: 'soldUnitRevenue',
  },
  {
    label: 'Sold Unit Revenue Net HST',
    id: 'soldUnitRevenueNetHst',
  },
  {
    label: 'Sold Options Revenue',
    id: 'soldOptionsRevenue',
  },
  {
    label: 'Overall Sold Revenue',
    id: 'overallSoldRevenue',
  },
  {
    label: 'Overall Sold Revenue Net HST',
    id: 'overallSoldRevenueNetHst',
  },
];

export const adjustmentColumns = [
  {
    label: 'Adjustment',
    id: 'name',
  },
  {
    label: 'Count',
    id: 'count',
  },
  {
    label: 'Amount',
    id: 'amount',
  },
];

export const adjustmentWidths = {
  name: 15,
  count: 15,
  amount: 15,
};

export const pdfAdjustmentWidths = {
  name: 200,
  count: 200,
  amount: 200,
};

export const unitWidths = {
  status: 15,
  count: 15,
  size: 15,
  revenue: 15,
  revenueNet: 15,
  ppsf: 15,
  ppsfNet: 15,
};

export const pdfUnitWidths = {
  status: 200,
  count: 200,
  size: 200,
  revenue: 200,
  revenueNet: 200,
  ppsf: 200,
  ppsfNet: 200,
};

export const optionWidths: any = {
  option: 15,
  currentPrice: 15,
  totalOptions: 15,
  available: 15,
  availableRevenue: 15,
  availableRevenueNetHst: 15,
  pendingOptions: 15,
  soldOptions: 15,
  combinedOptions: 15,
  pendingRevenue: 15,
  soldRevenue: 15,
  combinedRevenue: 15,
  totalPPSF: 15,
  totalRevenue: 30,
  totalRevenueNetHst: 30,
};

export const pdfOptionWidths: any = {
  option: 200,
  currentPrice: 200,
  totalOptions: 200,
  available: 200,
  availableRevenue: 200,
  availableRevenueNetHst: 200,
  pendingOptions: 200,
  soldOptions: 200,
  combinedOptions: 200,
  pendingRevenue: 200,
  soldRevenue: 200,
  combinedRevenue: 200,
  totalPPSF: 200,
  totalRevenue: 200,
  totalRevenueNetHst: 200,
};

export const totalWidths = {
  type: 15,
  soldUnitRevenue: 30,
  soldOptionsRevenue: 30,
  soldUnitRevenueNetHst: 30,
  overallSoldRevenue: 30,
  overallSoldRevenueNetHst: 30,
};

export const pdfTotalWidths = {
  type: 200,
  soldUnitRevenue: 200,
  soldOptionsRevenue: 200,
  soldUnitRevenueNetHst: 200,
  overallSoldRevenue: 200,
  overallSoldRevenueNetHst: 200,
};

export const conditionFields = ['netHstBasePrice', 'unitTypes', 'modelTypes', 'size'];

export const numberConditions = ['Less Than', 'Greater Than'];

export const wordConditions = ['Includes'];

export const packageHeaders = [
  'Model',
  'Suite Type',
  'Floor',
  'View',
  'Bathroom',
  'Interior Size',
  'Exterior Size',
  'Total Size',
  'Starting Price',
  'Starting Price*',
  'Lease Amount',
];

export const sortOrders = ['basePrice', 'modelType', 'size', 'outdoorSize', 'level', 'unitType', 'exposure', 'totalSize'];

export const realtorSummaryUnitTypes = [
  'studio',
  'jrOneBr',
  'oneBr',
  'oneBrInbound',
  'oneBrMedia',
  'oneBrFlex',
  'oneBrDen',
  'oneBrDenInbound',
  'twoBr',
  'twoBrInbound',
  'twoBrMedia',
  'twoBrFlex',
  'twoBrDen',
  'twoBrDenInbound',
  'juniorTwo',
  'threeBr',
  'threeBrInbound',
  'threeBrMedia',
  'threeBrFlex',
  'threeBrDen',
  'th',
];

export const docusignStatus = [
  'Changed',
  'Voided',
  'Created',
  'Deleted',
  'Sent',
  'Delivered',
  'Signed',
  'Expired',
  'Expiring Soon',
  'Completed',
  'Declined',
  'TimedOut',
  'Processing',
];
