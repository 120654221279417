import { useState, Dispatch, SetStateAction, useMemo } from 'react';
import { Box, Paper, Badge } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import { ITask } from '../../types/task';
import { IUser } from '../../types/user';
import CommentIcon from '@mui/icons-material/Comment';
import { IComment } from '../../types/comment';

import { FlexBetween } from '../../commonStyles';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';
import { convertAllDates } from '../../utils/Functions';

const TaskDraggable = (props: ChildProps) => {
  const user = useSelector(selectUser);
  const { task, setModalOpen, setTask, setModalType, setComments, archiveTask } = props;

  const [intervalId, setIntervalId] = useState<any>(null);
  const [timer, setTimer] = useState('');

  const newShade = (col: string, amt: number) => {
    var usePound = false;

    if (col[0] == '#') {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  };

  useMemo(() => {
    if (task.dueDate) {
      setIntervalId(
        setInterval(() => {
          let seconds = Math.floor(((new Date(task.dueDate).valueOf() - new Date().valueOf()) % 60000) / 1000);
          let minutes = Math.floor(((new Date(task.dueDate).valueOf() - new Date().valueOf()) % 3600000) / 60000);
          let hours = Math.floor((new Date(task.dueDate).valueOf() - new Date().valueOf()) / 3600000);
          let days = Math.floor((new Date(task.dueDate).valueOf() - new Date().valueOf()) / 86400000);
          if (hours > 24) {
            hours = Math.ceil(hours / 24);
          }

          let timer = `${days.toString().padStart(2, '0')}D:${hours.toString().padStart(2, '0')}H:${minutes
            .toString()
            .padStart(2, '0')}M:${seconds.toString().padStart(2, '0')}`;

          if (days === 0) {
            timer = `${hours.toString().padStart(2, '0')}H:${minutes.toString().padStart(2, '0')}M:${seconds.toString().padStart(2, '0')}`;
          }

          if (minutes < 0) {
            timer = `Past Due`;
          }

          setTimer(timer);
        }, 1000)
      );
    }
  }, [task]);

  const handleDueDate = () => {
    if (task.status === 'completed' || task.status === 'archived') {
      return (
        <Box sx={{ display: 'inline-block', color: 'green' }}>
          <strong>Completed</strong>
        </Box>
      );
    }
    if (timer === 'Past Due') {
      return (
        <Box sx={{ display: 'inline-block', color: 'red' }}>
          <strong>PAST DUE</strong>
        </Box>
      );
    }
    if (timer) {
      return <Box sx={{ display: 'inline-block' }}>{timer}</Box>;
    } else {
      return <Box sx={{ display: 'inline-block' }}>No Due Date</Box>;
    }
  };

  return (
    <Paper
      elevation={12}
      sx={{
        backgroundColor:
          task.project && task.project.portal && task.project.portal.primaryColor
            ? newShade(task.project.portal.primaryColor, 100)
            : '#fff',
      }}
    >
      <FlexBetween sx={{ p: 2 }}>
        <Box>
          <strong>{task.title}</strong>
        </Box>
        <Box sx={{ alignSelf: 'center', display: 'flex' }}>
          <ArchiveIcon
            sx={{ alignSelf: 'center', cursor: 'pointer' }}
            onClick={() => {
              if (task.status === 'completed') {
                archiveTask(task, 'unarchive');
              } else {
                setModalType('archive');
                setModalOpen(true);
                setTask(task);
              }
            }}
          />
          <EditIcon
            sx={{ alignSelf: 'center', cursor: 'pointer', ml: 1 }}
            onClick={() => {
              setModalOpen(true);
              setModalType('details');
              setTask(task);
            }}
          />
          <Badge
            sx={{ height: '100%', '& .MuiBadge-badge': { fontSize: 11, height: 18, minWidth: 18 } }}
            invisible={task.comments.length > 0 ? false : true}
            color={'primary'}
            badgeContent={task.comments.length}
          >
            <CommentIcon
              onClick={() => {
                setModalType('comments');
                setModalOpen(true);
                setTask(task);
                setComments(task.comments);
              }}
              sx={{ alignSelf: 'center', cursor: 'pointer', ml: 1 }}
            />
          </Badge>
        </Box>
      </FlexBetween>
      <Box sx={{ fontSize: '12px', px: 2, pb: 2 }}>
        {task.project ? (
          <Box>
            <strong>{task.project.name}</strong>
          </Box>
        ) : null}
        {task.labels.length ? (
          <Box sx={{ fontSize: '12px' }}>
            <strong>{task.labels.map((label: string) => label).join(', ')}</strong>
          </Box>
        ) : null}
        <Box>Created: {convertAllDates(task.createdAt, 'PPpp')}</Box>
        <Box>Due In: {handleDueDate()}</Box>
        {task.status === 'archived' && task.reason ? <Box>Reason: {task.reason}</Box> : null}
        {user.type !== 'Manager' ? (
          <Box>
            <Box>{task.to.some((toUser: IUser) => toUser._id === user._id) ? <Box>From: {task.from.fullName}</Box> : null}</Box>
            <Box>{task.from._id === user._id ? <Box>To: {task.to.map((user: IUser) => user.fullName).join(', ')}</Box> : null}</Box>
          </Box>
        ) : (
          <FlexBetween>
            <Box>From: {task.from.fullName}</Box>
            <Box>To: {task.to.map((user: IUser) => user.fullName).join(', ')}</Box>
          </FlexBetween>
        )}
      </Box>
    </Paper>
  );
};

interface ChildProps {
  task: ITask;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  setModalType: Dispatch<SetStateAction<string>>;
  setTask: Dispatch<SetStateAction<ITask | null>>;
  setComments: Dispatch<SetStateAction<IComment[]>>;
  archiveTask: any;
}

export default TaskDraggable;
