import React, { useState } from 'react';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Dropdown from '../common/formControls/Dropdown';
import TextInput from '../common/formControls/TextInput';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { IDealDeposit } from '../../types/CreateDealForm';
import { chequeTypes } from '../../utils/Constants';
import { normalToCamel, camelToTitle } from '../../utils/Functions';

const DealDeposits = (props: ChildProps) => {
  const { deposit, basePrice, handleDepositsArray, disable, depositType } = props;
  const [displayCheque, setDisplayCheque] = useState<number[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(basePrice);

  const handleDisplayCheque = (index: number) => {
    if (displayCheque.includes(index)) {
      setDisplayCheque(displayCheque.filter((number: number) => number !== index));
    } else {
      setDisplayCheque([...displayCheque, index]);
    }
  };

  const handleTextInput = (event: any, numIndex: number, type: string) => {
    let depositArray = deposit.map((depositStructure: IDealDeposit, index: number) => {
      if (numIndex === index) {
        if (event.target.name === 'amount') {
          return {
            ...depositStructure,
            [event.target.name]: parseFloat(event.target.value),
            percentage: ((event.target.value / totalPrice) * 100).toFixed(2),
          };
        } else if (event.target.name === 'percentage') {
          return {
            ...depositStructure,
            [event.target.name]: event.target.value,
            amount: Math.round((totalPrice * event.target.value) / 100),
          };
        } else {
          if (type === 'chequeAmount') {
            return { ...depositStructure, [event.target.name]: parseFloat(event.target.value) };
          }
          return { ...depositStructure, [event.target.name]: event.target.value };
        }
      } else {
        return depositStructure;
      }
    });
    handleDepositsArray(depositArray, depositType);
  };

  const handleDateChange = (date: Date, value: any, type: string, numIndex: number) => {
    let depositArray = deposit.map((deposit: IDealDeposit, index: number) => {
      if (numIndex === index) {
        return { ...deposit, [type]: date };
      } else {
        return deposit;
      }
    });

    handleDepositsArray(depositArray, depositType);
  };

  const handleDelete = (numIndex: number) => {
    let depositArray = deposit.filter((depositStructure: IDealDeposit, index: number) => numIndex !== index);
    handleDepositsArray(depositArray, depositType);
  };

  const handleAdd = (numIndex: number) => {
    let addedDeposit = {
      _id: null,
      amount: deposit[numIndex].amount,
      name: deposit[numIndex].name,
      dueDate: deposit[numIndex].dueDate,
      dateType: deposit[numIndex].dateType,
      accountNumber: '',
      chequeAmount: 0,
      chequeDate: null,
      chequeNumber: '',
      chequeType: '',
      deal: null,
    };
    let depositArray = [...deposit.slice(0, numIndex), deposit[numIndex], addedDeposit];
    handleDepositsArray(depositArray, depositType);
  };

  const handleDropdownInput = (event: React.ChangeEvent<HTMLInputElement>, numIndex: number) => {
    let depositArray = deposit.map((deposit: IDealDeposit, index: number) => {
      if (numIndex === index) {
        if (event.target.value === 'Pad') {
          return { ...deposit, chequeType: normalToCamel(event.target.value), chequeDate: deposit.dueDate, chequeAmount: deposit.amount };
        } else {
          return { ...deposit, chequeType: normalToCamel(event.target.value) };
        }
      } else {
        return deposit;
      }
    });
    handleDepositsArray(depositArray, depositType);
  };

  const tableRowColor = (deposit: IDealDeposit) => {
    let color = '#dfdfdf';
    if (deposit.chequeType === 'received') {
      color = '#92ff92';
    } else if (deposit.chequeType === 'nsf') {
      color = '#ff8888';
    } else if (deposit.chequeType === 'replacement') {
      color = '#ffff99';
    } else if (deposit.chequeType === 'distributed') {
      color = '#b4b4f3';
    } else if (deposit.chequeType === 'cleared') {
      color = '#FF8C00';
    } else if (deposit.chequeType === 'wire') {
      color = '#5eb0f7';
    } else if (deposit.chequeType === 'floating') {
      color = '#ff99e9';
    } else if (deposit.chequeType === 'notReceived') {
      color = '#cc8cff';
    } else if (deposit.chequeType === 'pad') {
      color = '#8cffef';
    }
    return {
      backgroundColor: color,
    };
  };

  return (
    <div>
      <TableContainer sx={{ mt: 2 }} component={Paper}>
        <Table
          sx={{
            '& .MuiFormLabel-root.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.6)',
            },
            '& .MuiInputBase-root.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.6)',
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Deposit Name</strong>
              </TableCell>
              <TableCell align="left">
                <strong>Amount</strong>
              </TableCell>
              <TableCell align="left">
                <strong>Percentage</strong>
              </TableCell>
              <TableCell align="left">
                <strong>Date Due</strong>
              </TableCell>
              {disable ? <TableCell align="left"></TableCell> : null}
              {!disable ? <TableCell align="left"></TableCell> : null}
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deposit.map((depositInfo: IDealDeposit, index: number) => {
              return (
                <React.Fragment key={index}>
                  <TableRow key={index} style={tableRowColor(depositInfo)}>
                    <TableCell component="th" scope="row">
                      <TextInput
                        name={'name'}
                        title={'Deposit Name'}
                        handleTextInput={(e: HTMLInputElement) => handleTextInput(e, index, 'string')}
                        value={depositInfo.name}
                        required={true}
                        disabled={disable}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextInput
                        name={'amount'}
                        type={'number'}
                        title={'Amount'}
                        handleTextInput={(e: HTMLInputElement) => handleTextInput(e, index, 'number')}
                        value={depositInfo.amount}
                        adornment={'$'}
                        required={true}
                        disabled={disable}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextInput
                        name={'percentage'}
                        title={'Percentage'}
                        handleTextInput={(e: HTMLInputElement) => handleTextInput(e, index, 'number')}
                        value={((depositInfo?.amount! / basePrice) * 100).toFixed(2)}
                        adornment={'%'}
                        disabled={true}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label={'Due Date'}
                          value={depositInfo.dueDate}
                          onChange={(date: any, value: any) => handleDateChange(date, value, 'dueDate', index)}
                          renderInput={(params: any) => <TextField {...params} />}
                          disabled={disable}
                        />
                      </LocalizationProvider>
                    </TableCell>
                    <TableCell align="left">
                      <strong></strong>
                    </TableCell>
                    {!disable && index + 1 === deposit.length ? (
                      <TableCell>
                        <AddIcon sx={{ cursor: 'pointer' }} color="secondary" onClick={() => handleAdd(index)} />
                      </TableCell>
                    ) : (
                      <TableCell></TableCell>
                    )}
                    {!disable ? (
                      <TableCell>
                        <CloseIcon sx={{ cursor: 'pointer' }} color="secondary" onClick={() => handleDelete(index)} />
                      </TableCell>
                    ) : null}
                    {disable ? (
                      <TableCell>
                        <AttachMoneyIcon
                          sx={{ cursor: 'pointer' }}
                          color={depositInfo.chequeNumber && depositInfo.chequeAmount && depositInfo.chequeDate ? 'secondary' : 'error'}
                          onClick={() => handleDisplayCheque(index)}
                        />
                      </TableCell>
                    ) : null}
                  </TableRow>
                  {displayCheque.includes(index) ? (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <TextInput
                          name={'chequeNumber'}
                          title={'Cheque Number'}
                          handleTextInput={(e: HTMLInputElement) => handleTextInput(e, index, 'string')}
                          value={depositInfo.chequeNumber}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <TextInput
                          name={'accountNumber'}
                          title={'Account Number'}
                          handleTextInput={(e: HTMLInputElement) => handleTextInput(e, index, 'string')}
                          value={depositInfo.accountNumber}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <TextInput
                          name={'chequeAmount'}
                          title={'Cheque Amount'}
                          type={'number'}
                          handleTextInput={(e: HTMLInputElement) => handleTextInput(e, index, 'chequeAmount')}
                          value={depositInfo.chequeAmount}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={'Cheque Date'}
                            value={depositInfo.chequeDate}
                            onChange={(date: any, e: any) => handleDateChange(date, e, 'chequeDate', index)}
                            renderInput={(params: any) => <TextField {...params} />}
                            minDate={new Date('2020-01-01')}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell align="left">
                        <Dropdown
                          id={'dropdown'}
                          title={'Type'}
                          menuList={chequeTypes.map((chequeType: any) => chequeType.value)}
                          name={'chequeInfo'}
                          handleSelect={(e: any) => handleDropdownInput(e, index)}
                          value={camelToTitle(depositInfo.chequeType!)}
                        />
                      </TableCell>
                    </TableRow>
                  ) : null}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

interface ChildProps {
  basePrice: number;
  deposit: IDealDeposit[];
  handleDepositImage?: any;
  handleDepositsArray?: any;
  disable?: boolean;
  setErrors?: any;
  depositType?: string;
}

export default DealDeposits;
