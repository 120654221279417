import { useState, SetStateAction, Dispatch, useContext } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { FormControl, Autocomplete, TextField, Box } from '@mui/material';
import CustomDialog from '../../../common/CustomDialog';
import { useAppDispatch } from '../../../../app/hooks';
import { showSuccessSnackbar } from '../../../../features/snackbar/snackbarSlice';
import { UnitContext } from '../../../../context/UnitContext';
import { selectProject } from '../../../../features/project/projectSlice';
import { selectUser } from '../../../../features/auth/authSlice';
import { useCreateActivity } from '../../../../features/activity/activityHooks';
import { IRealtorInfo, IDealRealtor } from '../../../../types/CreateDealForm';
import { IDocuments } from '../../../../types/docusign';
import LoadingWrapper from '../../../common/LoadingWrapper';
import { IMerge } from '../../../../types/merge';
import { createPdf } from '../../../../utils/Functions';

const RealtorDialog = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const storeDispatch = useAppDispatch();
  const createActivity = useCreateActivity();
  const { unit, filteredDeal, setFilteredDeal, realtors, realtorLoading, updateDealDocuments } = useContext(UnitContext);
  const { dialogType, setDialogType, dialogOpen, setDialogOpen, realtorIndex, merges } = props;
  const [selectedRealtor, setSelectedRealtor] = useState<IRealtorInfo>({});
  const [selectedMerge, setSelectedMerge] = useState<IMerge | null>(null);

  const [createDocument] = useMutation(CREATEDOCUMENT, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(`Document has been created!`));
      let documentIds = filteredDeal.documents.map((document: IDocuments) => {
        return document._id;
      });
      updateDealDocuments({
        variables: { _id: filteredDeal._id, record: { documents: [...documentIds, data.documentCreateOne._id] } },
      });
      setFilteredDeal({
        ...filteredDeal,
        documents: [...filteredDeal.documents, data.documentCreateOne],
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [addRealtor] = useMutation(ADDREALTOR, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Realtor has been added to the deal'));
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Add Realtor`,
        content: `Realtor has been added to the deal`,
      });
      let dealRealtorIds = filteredDeal.realtor.map((realtor: IDealRealtor) => realtor._id);
      updateDealRealtor({
        variables: { _id: filteredDeal._id, record: { realtor: [...dealRealtorIds, data.dealRealtorCreateOne.record._id] } },
      });
      setFilteredDeal({
        ...filteredDeal,
        realtor: [
          ...filteredDeal.realtor,
          {
            _id: data.dealRealtorCreateOne.record._id,
            firstName: data.dealRealtorCreateOne.record.firstName,
            lastName: data.dealRealtorCreateOne.record.lastName,
            brokerage: data.dealRealtorCreateOne.record.brokerage,
            email: data.dealRealtorCreateOne.record.email,
            streetAddress: data.dealRealtorCreateOne.record.streetAddress,
            city: data.dealRealtorCreateOne.record.city,
            province: data.dealRealtorCreateOne.record.province,
            country: data.dealRealtorCreateOne.record.country,
            postalCode: data.dealRealtorCreateOne.record.postalCode,
            brokeragePhone: data.dealRealtorCreateOne.record.brokeragePhone,
            directPhone: data.dealRealtorCreateOne.record.directPhone,
            brokerageFax: data.dealRealtorCreateOne.record.brokerageFax,
            coopRates: data.dealRealtorCreateOne.record.coopRates,
          },
        ],
      });
      updateAllocation({
        variables: {
          _id: unit._id,
          record: {
            allocation: selectedRealtor._id,
          },
        },
      });
      setSelectedRealtor({});
      setDialogType('');
      setDialogOpen(false);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateDealRealtor] = useMutation(DELETEREALTORDEAL, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [updateAllocation] = useMutation(UPDATEUNIT, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const [deleteRealtor] = useMutation(DELETEREALTOR, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Realtor has been removed from the deal'));
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Remove Realtor`,
        content: `Realtor has been removed from the deal`,
      });
      let dealRealtorIds = filteredDeal.realtor.filter((realtor: IDealRealtor) => realtor._id !== data.dealRealtorRemoveById.record._id);
      updateDealRealtor({
        variables: { _id: filteredDeal._id, record: { realtor: dealRealtorIds.map((realtor: IDealRealtor) => realtor._id) } },
      });
      updateAllocation({
        variables: {
          _id: unit._id,
          record: {
            allocation: null,
          },
        },
      });
      setFilteredDeal({
        ...filteredDeal,
        realtor: dealRealtorIds,
      });
      setDialogType('');
      setDialogOpen(false);
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  const handleCloseSuccess = async () => {
    if (dialogType === 'addRealtor') {
      const { _id, ...newObject } = selectedRealtor;
      newObject.deal = filteredDeal._id;
      newObject.project = project._id;
      newObject.realtor = _id;
      addRealtor({ variables: { record: newObject } });
    } else if (dialogType === 'deleteRealtor') {
      deleteRealtor({ variables: { _id: filteredDeal.realtor[realtorIndex]._id } });
    } else if (dialogType === 'amendment') {
      if (selectedMerge) {
        let pdf = await createPdf(
          selectedMerge,
          project,
          unit,
          filteredDeal.purchasers,
          filteredDeal.deposit,
          filteredDeal.options,
          filteredDeal.realtor[realtorIndex],
          null,
          null,
          null,
          filteredDeal
        );

        let sign = selectedMerge.signFields.map((sign: any) => {
          return {
            index: sign.index,
            key: sign.key,
            pageNumber: sign.pageNumber,
            x: sign.x,
            y: sign.y,
          };
        });

        let mergeName = `${selectedMerge.name} - ${filteredDeal.realtor[realtorIndex].firstName} ${filteredDeal.realtor[realtorIndex].lastName}`;

        const args = {
          status: 'Prepared',
          name: mergeName,
          project: project._id,
          deal: filteredDeal._id,
          signFields: sign,
          // signers,
          isAPS: false,
          type: selectedMerge.type,
          mergeTemplate: selectedMerge ? selectedMerge._id : null,
        };
        setDialogOpen(false);
        createDocument({ variables: { record: args, file: pdf } });
      }
    }
  };

  const dialogContent = () => {
    if (dialogType === 'addRealtor') {
      return realtorLoading ? (
        <LoadingWrapper title="Realtors is loading..." modal={false} />
      ) : (
        <div>
          <FormControl
            sx={{
              width: '100%',
              '& .MuiFormLabel-asterisk': {
                color: 'red',
              },
            }}
          >
            <Autocomplete
              sx={{
                width: '100%',
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }}
              id={'search'}
              disableClearable
              freeSolo={false}
              options={realtors}
              getOptionLabel={(option: any) => `${option.firstName} ${option.lastName} ${option.email}`}
              onChange={(e, values) => setSelectedRealtor(values)}
              renderInput={(params) => (
                <TextField required={false} {...params} size="small" label={'Search By Name or Email'} margin="normal" />
              )}
            />
          </FormControl>
        </div>
      );
    } else if (dialogType === 'amendment') {
      return (
        <Box sx={{ mt: 1 }}>
          <Autocomplete
            options={merges}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            disableClearable={false}
            freeSolo={false}
            value={selectedMerge}
            onChange={(event: any, newValue: any | null) => {
              setSelectedMerge(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Coop Merge Templates" size="medium" />}
          />
        </Box>
      );
    }
  };

  const handleDialogButton = () => {
    if (dialogType === 'addRealtor') {
      return 'Yes, I would like to add this realtor';
    } else if (dialogType === 'deleteRealtor') {
      return 'Yes, delete this realtor';
    } else if (dialogType === 'amendment') {
      return 'Create Amendment';
    }
  };

  const handleDialogTitle = () => {
    if (dialogType === 'addRealtor') {
      return 'Please select a Realtor';
    } else if (dialogType === 'deleteRealtor') {
      return 'Are you sure you would like to remove this realtor?';
    } else if (dialogType === 'amendment') {
      return 'Please select an amendment';
    }
  };

  return (
    <CustomDialog
      handleClose={() => {
        setSelectedMerge(null);
        setDialogOpen(false);
        setDialogType('');
      }}
      handleCloseRemove={() => {
        setSelectedMerge(null);
        setDialogOpen(false);
        setDialogType('');
      }}
      handleCloseSuccess={handleCloseSuccess}
      open={dialogOpen}
      removeButton={'Cancel'}
      successButton={handleDialogButton()!}
      dialogContent={dialogContent()}
      dialogTitle={handleDialogTitle()!}
    />
  );
};

interface ChildProps {
  dialogOpen: boolean;
  dialogType: string;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  setDialogType: Dispatch<SetStateAction<string>>;
  realtorIndex: number;
  merges: IMerge[];
}

const CREATEDOCUMENT = gql`
  mutation documentCreateOne($record: CreateOneDocumentInput, $file: Upload!) {
    documentCreateOne(record: $record, file: $file) {
      _id
      name
      project {
        _id
      }
      type
      getUrl
      status
      isAPS
      dsEnvelopeId
    }
  }
`;

const UPDATEUNIT = gql`
  mutation unitUpdateById($_id: MongoID!, $record: UpdateByIdUnitInput!) {
    unitUpdateById(_id: $_id, record: $record) {
      record {
        _id
        suite
        unit
        level
        modelType
        basePrice
        size
        status
        getUrl
        putUrl
        bathroom
        exposure
        status
        outdoorSize
        outdoorType
        unitType
        originalPrice
        basePrice
        allocation {
          _id
          fullName
        }
        history {
          type
          description
          timestamp
          user {
            _id
            fullName
          }
          _id
        }
      }
    }
  }
`;

const ADDREALTOR = gql`
  mutation dealRealtorCreateOne($record: CreateOneDealRealtorInput!) {
    dealRealtorCreateOne(record: $record) {
      record {
        _id
        firstName
        lastName
        brokerage
        email
        streetAddress
        city
        province
        country
        postalCode
        brokeragePhone
        directPhone
        brokerageFax
        coopRates {
          type
          days
          date
          description
          percentage
          fixedAmount
        }
      }
    }
  }
`;

const DELETEREALTORDEAL = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        realtor {
          _id
        }
      }
    }
  }
`;

const DELETEREALTOR = gql`
  mutation dealRealtorRemoveById($_id: MongoID!) {
    dealRealtorRemoveById(_id: $_id) {
      record {
        _id
      }
    }
  }
`;

export default RealtorDialog;
