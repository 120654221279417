import { useState } from 'react';
import { List, ListItem, Divider, ListItemText, ListItemSecondaryAction, Button, Link } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { IMergeData, IMerge } from '../../types/merge';
import LoadingWrapper from '../common/LoadingWrapper';

const MergeTemplateList = (props: ChildProps) => {
  const { id } = props;
  const [merges, setMerges] = useState<IMerge[]>([]);

  const { loading } = useQuery<IMergeData>(MERGETEMPLATES, {
    variables: { filter: { project: id, OR: [{ type: 'APS' }, { type: 'Other' }] } },
    onCompleted: (data) => {
      setMerges(data.mergeTemplateMany);
    },
    onError: (err) => {
      console.log(err, 'er');
    },
  });

  return (
    <div>
      {loading ? (
        <LoadingWrapper title="Merge Templates are loading..." modal={false} />
      ) : (
        <List component="nav" aria-label="mergeTemplates">
          {merges.length > 0 ? (
            merges.map((merge: IMerge) => {
              return (
                <>
                  <ListItem>
                    <ListItemText primary={`${merge.name}`} />
                    <ListItemSecondaryAction>
                      <Link sx={{ textDecoration: 'none' }} download href={`${merge.getUrl}`} target="_blank" rel="noreferrer">
                        <Button sx={{ mr: 1 }} variant="contained" color="secondary">
                          View
                        </Button>
                      </Link>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </>
              );
            })
          ) : (
            <div>There are currently no merge templates for this project</div>
          )}
        </List>
      )}
    </div>
  );
};

interface ChildProps {
  id: string | undefined;
}

const MERGETEMPLATES = gql`
  query mergeTemplateMany($filter: FilterFindManyMergeTemplateInput) {
    mergeTemplateMany(filter: $filter) {
      _id
      project {
        _id
      }
      name
      mergeFields {
        key
        index
        pageNumber
        x
        y
        fontSize
        format
        wrap
      }
      signFields {
        key
        index
        pageNumber
        x
        y
      }
      default
      type
      getUrl
      putUrl
    }
  }
`;

export default MergeTemplateList;
