import { useState, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import Dropzone, { useDropzone } from 'react-dropzone';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

import { selectProject } from '../../features/project/projectSlice';
import PdfCard from '../common/PdfCard';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '../../utils/Constants';
import { showErrorSnackbar, showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { useAppDispatch } from '../../app/hooks';

const SupplementalDocuments = () => {
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  const [documents, setDocuments] = useState<ISupplementalDocument[]>([]);

  const { isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/gif, image/jpeg, image/png, application/pdf',
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useQuery(DOCUMENTS, {
    variables: { project: project._id },
    onCompleted: (data) => {
      setDocuments(data.getS3Documents);
    },
  });

  const [uploadDocuments] = useMutation(UPLOADDOCUMENTS, {
    variables: { project: project._id },
    onCompleted: (data) => {},
  });

  const [deleteDocument] = useMutation(DELETEDOCUMENT, {
    variables: { project: project._id },
    onCompleted: (data) => {},
  });

  const handleDrop = async (acceptedFiles: any) => {
    if (acceptedFiles.length === 0) {
      storeDispatch(showErrorSnackbar('This file type is not allowed'));
      return;
    }
    const files = acceptedFiles;
    let allFiles: any[] = [];
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        let filePromise = new Promise((resolve) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(files[i]);
          fileReader.onloadend = async () =>
            resolve({
              url: fileReader.result,
              name: files[i].name,
              file: files[i],
            });
        });
        allFiles.push(filePromise);
      }
    }
    Promise.all(allFiles).then((fileContents: any) => {
      uploadDocuments({
        variables: {
          project: project._id,
          documents: fileContents.map((fileContent: any) => {
            return {
              name: fileContent.name,
              file: fileContent.file,
            };
          }),
        },
      });
      storeDispatch(showSuccessSnackbar('Document(s) Uplaoded!'));
      setDocuments([...documents, ...fileContents]);
    });
  };

  const handleDelete = (id: string, name: string) => {
    deleteDocument({
      variables: {
        project: project._id,
        name: name,
      },
    }).then((res) => {
      storeDispatch(showSuccessSnackbar('Document Deleted!'));
      setDocuments(documents.filter((document: ISupplementalDocument) => document.name !== name));
    });
  };

  return (
    <div>
      <h2>Supplemental Documents</h2>
      <Box
        sx={{
          border: '1px solid #000',
          padding: '30px 20px',
          position: 'relative',
          borderRadius: '8px',
          marginBottom: '20px',
        }}
      >
        <Dropzone onDrop={(files) => handleDrop(files)} accept="image/gif, image/jpg, image/jpeg, image/png, application/pdf">
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag and Drop or Upload Images</p>
              </div>
            </section>
          )}
        </Dropzone>
        <Grid container spacing={2}>
          {documents.map((document: ISupplementalDocument, index: number) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <PdfCard title={document.name} file={document.url} id={index} handleDelete={handleDelete} download={true} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
};

const DOCUMENTS = gql`
  query getS3Documents($project: MongoID!) {
    getS3Documents(project: $project) {
      name
      url
    }
  }
`;

const UPLOADDOCUMENTS = gql`
  mutation uploadDocuments($project: MongoID!, $documents: [SupplementalDocument]) {
    uploadDocuments(project: $project, documents: $documents) {
      name
      url
    }
  }
`;

const DELETEDOCUMENT = gql`
  mutation deleteDocument($project: MongoID!, $name: String!) {
    deleteDocument(project: $project, name: $name)
  }
`;

interface ISupplementalDocument {
  name: string;
  url: string;
  file?: any;
}

export default SupplementalDocuments;
