import { useQuery, gql } from '@apollo/client';

import { useAppDispatch } from '../../app/hooks';
import { setMerges } from './mergeSlice';

export const useMergeQuery = (project: string, merges: string[], page: number, perPage: number, search: string = '') => {
  const dispatch = useAppDispatch();

  let query: any = {
    project: project,
    mergeTypes: merges,
    search,
  };
  if (search.length > 1) {
    query = {
      project: project,
      search,
    };
  }

  return useQuery(MERGETEMPLATES, {
    fetchPolicy: 'network-only',
    variables: { filter: query, page: page + 1, perPage: perPage },
    onCompleted: (data) => {
      dispatch(setMerges(data.mergeTemplatePagination));
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });
};

const MERGETEMPLATES = gql`
  query mergeTemplatePagination(
    $filter: FilterFindManyMergeTemplateInput
    $page: Int!
    $perPage: Int!
    $sort: SortFindManyMergeTemplateInput
  ) {
    mergeTemplatePagination(filter: $filter, page: $page, perPage: $perPage, sort: $sort) {
      items {
        _id
        project {
          _id
        }
        name
        mergeFields {
          key
          index
          pageNumber
          x
          y
          fontSize
          format
          wrap
        }
        signFields {
          key
          index
          pageNumber
          x
          y
          name
        }
        default
        totalPages
        type
        getUrl
        putUrl
      }
      count
    }
  }
`;
