import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, Button, Box, Autocomplete, Typography } from '@mui/material';
import { gql, useLazyQuery } from '@apollo/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { IDeal, IRealtorInfo, IRealtorsArray } from '../../../types/CreateDealForm';
import { Container } from '../../../commonStyles';
import LoadingLogo from '../../common/LoadingLogo';
import { useAppDispatch } from '../../../app/hooks';
import { showErrorSnackbar } from '../../../features/snackbar/snackbarSlice';
import { selectUser } from '../../../features/auth/authSlice';
import { numToCurrency, getUnitType, downloadExcel, downloadPdf, camelToTitle, convertAllDates } from '../../../utils/Functions';
import { realtorSummaryUnitTypes } from '../../../utils/Constants';
import { allocationSummaryColumn } from '../../../utils/CustomSettings';
import { selectProject } from '../../../features/project/projectSlice';
import { IUnit } from '../../../types/unit';
import { handleModal } from '../../../features/modal/modalSlice';
import { GlobalModal } from '../../../features/modal/Modal';
import StandardTable from '../../tables/StandardTable';

const Realtor = () => {
  const navigate = useNavigate();
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  const [realtorsSummary, setRealtorsSummary] = useState([]);
  const [dateStart, setDateStart] = useState<Date | number | null>(new Date().setHours(0, 0, 0, 0));
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());
  const [statuses, setStatuses] = useState<string[]>(['F']);
  const [realtors, setRealtors] = useState<IRealtorInfo[]>([]);
  const [realtor, setRealtor] = useState<IRealtorInfo>();
  const [projects, setProjects] = useState<any[]>();
  const [units, setUnits] = useState<IDeal[]>([]);
  const [realtorName, setRealtorName] = useState<string>('');
  const [projectCount, setProjectCount] = useState<IProjectCount[]>([]);

  const [getUnits, { loading: unitLoading }] = useLazyQuery(UNITS, {
    onCompleted: (data: any) => {
      let units = data.getRealtorUnits
        .filter(
          (dealRealtor: any) =>
            dealRealtor.deal &&
            !dealRealtor.deal.cancelled.dateCancelled &&
            !dealRealtor.deal.rescission.dateRescinded &&
            statuses.includes(dealRealtor.deal.unit.status) &&
            new Date(dealRealtor.deal.createdAt).valueOf() > new Date(dateStart!).valueOf() &&
            new Date(dealRealtor.deal.createdAt).valueOf() < new Date(dateEnd!).valueOf()
        )
        .map((dealRealtor: any) => dealRealtor.deal)
        .sort((a: any, b: any) => new Date(b.firmDate).valueOf() - new Date(a.firmDate).valueOf());
      setUnits(units);
      let projectCounts = units.reduce((acc: any, o: any) => ((acc[o.project.name] = (acc[o.project.name] || 0) + 1), acc), {});
      projectCounts = Object.entries(projectCounts).map(([key, value]) => ({ name: key, count: value }));
      setProjectCount(projectCounts);
    },
  });

  const [getRealtors, { loading: realtorLoading }] = useLazyQuery<IRealtorsArray>(REALTORS, {
    onCompleted: (data) => {
      setRealtors(data.realtorMany);
    },
  });

  const [getRealtorDeals, { loading: realtorDealsLoading }] = useLazyQuery(DEALREALTORS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data: any) => {
      setRealtorsSummary(data.getRealtorDeals);
    },
  });

  useEffect(() => {
    getRealtorDeals({
      variables: { projects: [], realtor: realtor ? realtor._id : null, dateStart, dateEnd, statuses },
    });
  }, []);

  const handleSearch = (e: any, value: string) => {
    if (value) {
      getRealtors({ variables: { filter: { search: value } } });
    }
  };

  const handleRealtorSearchInput = (event: any, values: any) => {
    setRealtor(values);
  };

  const handleProjectSearchInput = (event: any, values: any) => {
    if (values) {
      setProjects([values._id]);
    } else {
      setProjects([]);
    }
  };

  const handleStatuses = (event: any, values: any) => {
    if (values) {
      setStatuses(values);
    } else {
      setProjects([]);
    }
  };

  const handleDeals = () => {
    if (!dateStart || !dateEnd) return storeDispatch(showErrorSnackbar('Date Required'));
    getRealtorDeals({
      variables: { projects: projects ? projects : [], realtor: realtor ? realtor._id : null, dateStart, dateEnd, statuses },
    });
  };

  const handleUnitModal = (data: any) => {
    setRealtorName(data._id.fullName);
    getUnits({ variables: { projects: projects ? projects : [], realtor: data._id._id, dateStart, dateEnd, statuses } });
    storeDispatch(handleModal(true));
  };

  const unitModalColumns = useMemo(() => {
    let unitData = [
      {
        Header: 'Suite',
        accessor: (rowData: any) => {
          return (
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                storeDispatch(handleModal(false));
                navigate(`/${rowData.unit.project._id}/dashboard/${rowData.unit._id}`);
              }}
            >
              <strong>{rowData.unit.suite}</strong>
            </Box>
          );
        },
      },
      {
        Header: 'Project',
        accessor: (rowData: any) => <strong>{rowData.unit.project.name}</strong>,
      },
      {
        Header: 'Date Firmed',
        accessor: (rowData: any) => (rowData.firmDate ? <strong>{convertAllDates(rowData.firmDate, 'PP')}</strong> : ''),
      },
      {
        Header: 'Status',
        accessor: (rowData: any) => <strong>{rowData.unit.status}</strong>,
      },
      {
        Header: 'Base Price',
        accessor: (rowData: any) => numToCurrency.format(rowData.unit.basePrice),
      },
      {
        Header: 'Unit Type',
        accessor: (rowData: any) => rowData.unit.unitType,
      },
      {
        Header: 'Exposure',
        accessor: (rowData: any) => rowData.unit.exposure,
      },
      {
        Header: 'Model Type',
        accessor: (rowData: any) => rowData.unit.modelType,
      },
      {
        Header: 'Size',
        accessor: (rowData: any) => rowData.unit.size,
      },
      {
        Header: 'Bathrooms',
        accessor: (rowData: any) => rowData.unit.bathroom,
      },
      {
        Header: 'PPSF',
        accessor: (rowData: any) => numToCurrency.format(rowData.unit.basePrice / rowData.unit.size),
      },
    ];

    return unitData;
  }, [project._id]);

  const columns = useMemo(() => {
    let unitColumns = [
      {
        Header: 'Realtor',
        accessor: (rowData: any) => {
          return (
            <Box onClick={() => handleUnitModal(rowData)}>
              <strong style={{ cursor: 'pointer' }}>{rowData._id.fullName}</strong>
            </Box>
          );
        },
      },
      {
        Header: 'Total Deals (O,S,C,F)',
        accessor: (rowData: any) => `${rowData.count}`,
      },
      {
        Header: 'Total Revenue',
        accessor: (rowData: any) => rowData.totalRevenue,
        Cell: (props: any) => {
          return <Box sx={{ p: 2 }}>{numToCurrency.format(props.value)}</Box>;
        },
      },
      {
        Header: 'PPSF',
        accessor: (rowData: any) => numToCurrency.format(rowData.totalRevenue / rowData.size),
      },
    ];

    let selectedUnitTypes: any[] = [];

    if (realtorsSummary.length) {
      let checkUnitType = realtorSummaryUnitTypes
        .map((unitType: string) => {
          let allocationCheck = realtorSummaryUnitTypes.every((allocation: any) => allocation[unitType] === 0);

          if (allocationCheck) {
            return null;
          } else return unitType;
        })
        .filter((unitType) => unitType !== null);

      selectedUnitTypes = checkUnitType.map((checkUnit: any) => {
        return {
          Header: getUnitType(checkUnit)!,
          accessor: (rowData: any) => {
            return (
              <Box>
                <strong style={{ cursor: 'pointer' }}>{rowData[checkUnit]}</strong>
              </Box>
            );
          },
        };
      });
    }

    let customColumn = allocationSummaryColumn(project?._id);

    return [...unitColumns, ...customColumn, ...selectedUnitTypes];
  }, [realtorsSummary, realtorSummaryUnitTypes]);

  const download = (type: string, data: any) => {
    let checkUnitType = realtorSummaryUnitTypes
      .map((unitType: string) => {
        let allocationCheck = realtorSummaryUnitTypes.every((allocation: any) => allocation[unitType] === 0);

        if (!allocationCheck) return unitType;
      })
      .filter((unitType) => unitType);

    let allData = data.map((data: any) => {
      let dataObject = checkUnitType.reduce((obj: any, item: any) => ((obj[item] = data[item]), obj), {});

      return {
        allocation: data._id.fullName,
        totalAllocations: `${data.count}`,
        totalRevenue: numToCurrency.format(data.totalRevenue),
        ppsf: numToCurrency.format(data.totalRevenue / data.size),
        ...dataObject,
      };
    });

    let excelObject = checkUnitType.reduce((obj: any, item: any) => ((obj[item] = 15), obj), {});
    let pdfObject = checkUnitType.reduce((obj: any, item: any) => ((obj[item] = 200), obj), {});

    let widths = {
      allocation: 15,
      totalAllocations: 15,
      totalRevenue: 15,
      ppsf: 15,
    };

    let pdfWidth = {
      allocation: 200,
      totalAllocations: 200,
      totalRevenue: 200,
      ppsf: 200,
    };

    let excelWidths = { ...widths, ...excelObject };
    let pdfWidths = { ...pdfWidth, ...pdfObject };

    let objectHeaders = checkUnitType.map((data: any) => {
      return {
        label: camelToTitle(data),
        id: data,
      };
    });

    let headers = [
      {
        label: 'Realtor',
        id: 'allocation',
      },
      {
        label: 'Total Deals',
        id: 'totalAllocations',
      },
      {
        label: 'Total Revenue',
        id: 'totalRevenue',
      },
      {
        label: 'PPSF',
        id: 'ppsf',
      },
    ];

    let allHeaders = [...headers, ...objectHeaders];

    let sheetTitle = `${project.name} - Realtor Summary`;

    if (type === 'excel') {
      downloadExcel([allData], [allHeaders], [], [[excelWidths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([allData], [allHeaders], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  return (
    <Container>
      <GlobalModal>
        <Typography sx={{ mb: 2 }} variant={'h5'} gutterBottom>
          {realtorName}'s Units
        </Typography>
        <Box>
          {projectCount.map((project: IProjectCount) => {
            return (
              <Box sx={{ mb: 0.5 }}>
                <strong>{project.name}</strong>: {project.count}
              </Box>
            );
          })}
        </Box>
        <StandardTable data={units} columns={unitModalColumns} loading={unitLoading} />
      </GlobalModal>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={2.5}>
            <DateTimePicker
              label={'Start Date (YYYY/MM/DD)'}
              value={dateStart}
              onChange={(newValue) => {
                setDateStart(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DateTimePicker
              label={'End Date (YYYY/MM/DD)'}
              value={dateEnd}
              onChange={(newValue) => {
                setDateEnd(newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <Autocomplete
              sx={{
                width: '100%',
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }}
              id={'statuses'}
              freeSolo={false}
              multiple
              options={['F', 'C', 'S', 'O']}
              getOptionLabel={(option) => {
                return `${option}`;
              }}
              value={statuses}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={handleStatuses}
              renderInput={(params) => <TextField required={false} {...params} size="medium" label={'Statuses'} />}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              sx={{
                width: '100%',
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }}
              id={'search'}
              freeSolo={false}
              options={user?.projectAccess.map((project: any) => {
                return project.project;
              })}
              getOptionLabel={(option) => {
                return `${option.name}`;
              }}
              value={projects}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={handleProjectSearchInput}
              renderInput={(params) => <TextField required={false} {...params} size="medium" label={'Project'} />}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Autocomplete
              sx={{
                width: '100%',
                '& .MuiFormLabel-asterisk': {
                  color: 'red',
                },
              }}
              id={'search'}
              freeSolo={false}
              options={realtors}
              getOptionLabel={(option) => {
                return `${option.fullName} (${option.email})`;
              }}
              onInputChange={(e, value, reason) => {
                handleSearch(e, value);
              }}
              noOptionsText={realtorLoading ? `Loading...` : 'No Options'}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={handleRealtorSearchInput}
              renderInput={(params) => <TextField required={false} {...params} size="medium" label={'Realtor'} />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button sx={{ height: '100%' }} variant="contained" color="primary" onClick={() => handleDeals()}>
              Search
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
      {realtorDealsLoading ? (
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <LoadingLogo />
        </Box>
      ) : realtorsSummary.length ? (
        <Box>
          <StandardTable
            data={realtorsSummary}
            columns={columns}
            count={realtorsSummary.length}
            loading={realtorDealsLoading}
            download={download}
          />
        </Box>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <em>No Activity Found! Please revise your search dates!</em>
        </Box>
      )}
    </Container>
  );
};

interface IProjectCount {
  name: string;
  count: number;
}

const REALTORS = gql`
  query realtorMany($filter: FilterFindManyRealtorInput) {
    realtorMany(filter: $filter, limit: 10000) {
      _id
      email
      fullName
      firstName
      lastName
      disable
    }
  }
`;

const DEALREALTORS = gql`
  query getRealtorDeals($projects: [MongoID], $realtor: MongoID, $dateStart: Date, $dateEnd: Date, $statuses: [String]) {
    getRealtorDeals(projects: $projects, realtor: $realtor, dateStart: $dateStart, dateEnd: $dateEnd, statuses: $statuses) {
      _id {
        _id
        fullName
      }
      worksheetCount
      count
      totalRevenue
      size
      tripRevenue
      studio
      jrOneBr
      oneBr
      oneBrInbound
      oneBrDen
      oneBrMedia
      oneBrFlex
      oneBrDenInbound
      oneBrMediaInbound
      twoBr
      twoBrInbound
      twoBrDen
      twoBrMedia
      twoBrFlex
      juniorTwo
      twoBrDenInbound
      twoBrMediaInbound
      threeBr
      threeBrInbound
      threeBrDen
      threeBrMedia
      threeBrFlex
      th
    }
  }
`;

const UNITS = gql`
  query getRealtorUnits($projects: [MongoID], $realtor: MongoID, $dateStart: Date, $dateEnd: Date, $statuses: [String]) {
    getRealtorUnits(projects: $projects, realtor: $realtor, dateStart: $dateStart, dateEnd: $dateEnd, statuses: $statuses) {
      _id
      deal {
        firmDate
        createdAt
        cancelled {
          dateCancelled
        }
        rescission {
          dateRescinded
        }
        unit {
          _id
          suite
          status
          project {
            _id
            name
          }
          modelType
          unitType
          bathroom
          size
          outdoorType
          basePrice
          exposure
        }
      }
    }
  }
`;

export default Realtor;
