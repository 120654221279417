import { Link } from 'react-router-dom';
import { Box, Grid, Card, CardMedia } from '@mui/material';
import { IProjectCardDetails } from '../../types/project';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';

const ProjectCard = (props: ChildProps) => {
  const user = useSelector(selectUser);
  const { project } = props;

  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <Link to={`/${project._id}/dashboard`}>
        <Card
          sx={{
            height: 250,
            width: '100%',
            position: 'relative',
            transition: 'transform 0.5s ease-in-out',
            '&:hover': {
              transform: 'scale(1.03)',
            },
          }}
        >
          {project.imageGetUrl ? (
            <CardMedia
              sx={{
                height: '100%',
                width: '100%',
              }}
              image={project.imageGetUrl}
              src={project.imageGetUrl}
              title={project.name}
            />
          ) : null}
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              textAlign: 'center',
              backgroundColor: '#412920',
              color: '#fff',
              padding: '5px',
            }}
          >
            <div style={{ textDecoration: 'underline' }}>{project.name}</div>
            {user.type !== 'Developer' ? (
              <Box
                sx={{
                  color: project.taskCount > 0 ? '#00ff66' : '#fff',
                }}
              >
                Your Tasks: {project.taskCount}
              </Box>
            ) : null}
            <Box
              sx={{
                color: project.acceptedCount > 0 ? '#00ff66' : '#fff',
              }}
            >
              Accepted Worksheets: {project.acceptedCount}
            </Box>
            <Box
              sx={{
                color: project.pendingCount > 0 ? '#00ff66' : '#fff',
              }}
            >
              Pending Worksheets: {project.pendingCount}
            </Box>
          </Box>
        </Card>
      </Link>
    </Grid>
  );
};

interface ChildProps {
  project: IProjectCardDetails;
}

export default ProjectCard;
