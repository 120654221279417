import { useState, SetStateAction, Dispatch } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Button,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  Radio,
  RadioGroup,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { capitalizeFirstLetter } from '../../utils/Functions';
import { IUpgrade, IUpgradeTemplate } from '../../types/colourSelection';
import { FlexBetween, Flex } from '../../commonStyles';
import { useAppDispatch } from '../../app/hooks';
import { handleModal } from '../../features/modal/modalSlice';
import { IDeal } from '../../types/CreateDealForm';
import { uniqueArrayOfObjects, createDecorTablePdf, downloadDocuments } from '../../utils/Functions';
import { selectProject } from '../../features/project/projectSlice';
import { ISupplementalDocs } from './Resources';
import { showErrorSnackbar } from '../../features/snackbar/snackbarSlice';

const ResourceModal = (props: ChildProps) => {
  const project = useSelector(selectProject);
  const storeDispatch = useAppDispatch();
  const { suites, selectedSuites, setSelectedSuites, type, setType, decorNoPrice, setDecorNoPrice, setLoading, supplementalDocs } = props;
  const [upgradeSet, setUpgradeSet] = useState<string>('upgradeSetOne');
  const [unitUpgrades, setUnitUpgrades] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState<ISupplementalDocs>();

  const [getUpgradeUnits] = useLazyQuery(UPGRADEUNITS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setUnitUpgrades(data.unitMany);
    },
  });

  const [getResources] = useLazyQuery(RESOURCES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {},
  });

  const getDocuments = async (downloadAll: boolean) => {
    setLoading(true);
    let deals = [];
    if (decorNoPrice) {
      if (downloadAll) {
        deals = [...unitUpgrades, ...suites];
      } else {
        deals = selectedSuites;
      }
    } else {
      if (downloadAll) {
        deals = suites.map((suite: IDeal) => suite._id);
      } else {
        deals = selectedSuites.map((suite: IDeal) => suite._id);
      }
    }

    if (decorNoPrice) {
      let allDeals = deals.filter((deal: any) => deal[upgradeSet]);
      allDeals = await Promise.all(
        allDeals.map(async (deal: any) => {
          let uniqueMainCategories = [
            ...new Set(
              deal[upgradeSet].upgradeSet.map((item: any) => {
                return {
                  mainCategory: item.mainCategory,
                  subCategory: item.subCategory,
                };
              })
            ),
          ].filter((upgradeTemplate: any) => {
            if (deal[upgradeSet].upgradeSet.some((upgrade: IUpgrade) => upgrade.mainCategory === upgradeTemplate.mainCategory))
              return upgradeTemplate;
          });

          const uniqueCategoryArray = uniqueArrayOfObjects(uniqueMainCategories, ['mainCategory', 'subCategory']);

          let mergedCategoryArray = [...new Set(uniqueCategoryArray.map((item) => item.mainCategory))];

          let selectedUpgradeTemplates = deal[upgradeSet].upgradeSet.map((upgrade: any) => upgrade);

          let categoryData = mergedCategoryArray.map((category: any) => {
            let filtered = selectedUpgradeTemplates.filter(
              (upgradeTemplate: IUpgradeTemplate) => upgradeTemplate && upgradeTemplate.mainCategory === category
            );
            let uniqueSubCategories = [...new Set(filtered.map((item: any) => item.subCategory))];
            let uniqueData = uniqueSubCategories.map((subCategories) => {
              let selectedUpgrade: IUpgrade[] | undefined = deal[upgradeSet].upgradeSet.filter(
                (upgrade: IUpgrade) => upgrade.subCategory === subCategories && upgrade.mainCategory === category
              );
              let selectedSubCategory = selectedUpgradeTemplates.find(
                (upgradeTemplate: IUpgradeTemplate) =>
                  upgradeTemplate.subCategory === subCategories && upgradeTemplate.mainCategory === category
              );

              if (selectedUpgrade?.length) {
                let allUpgrades = selectedUpgrade.map((upgrade: IUpgrade) => {
                  if (upgrade) {
                    return {
                      selected: 'Y',
                      component: subCategories,
                      selection: selectedSubCategory.upgradeTemplate ? selectedSubCategory?.upgradeTemplate.upgradeType : 'UPG1',
                      quantity: upgrade.quantity,
                      description: upgrade.name,
                      price: upgrade.price ? upgrade.price : 'Included',
                    };
                  } else
                    return {
                      selected: '',
                      component: subCategories,
                      selection: '',
                      quantity: '',
                      description: '',
                      price: '',
                    };
                });

                return allUpgrades.flat();
              } else
                return {
                  selected: '',
                  component: subCategories,
                  selection: '',
                  quantity: '',
                  description: '',
                  price: '',
                };
            });

            let upgradeList = uniqueData.flat();

            return {
              mainCategory: category,
              subCategories: upgradeList,
            };
          });

          return {
            suite: deal.unit ? deal.unit.suite : deal.suite,
            pdf: await createDecorTablePdf(
              deal,
              deal.unit ? deal.unit : deal,
              deal[upgradeSet].upgradeSet,
              categoryData,
              'preview',
              project._id,
              true
            ),
          };
        })
      );

      await downloadDocuments(allDeals, `${project.name} - ALL`);
      setSelectedSuites([]);
      setDecorNoPrice(false);
      setType('');
      storeDispatch(handleModal(false));
      setLoading(false);
    } else {
      await getResources({
        variables: {
          project: project._id,
          deals,
          type: type,
        },
      }).then((res) => {
        if (res) {
          setLoading(false);
          setSelectedSuites([]);
          setDecorNoPrice(false);
          setType('');
          storeDispatch(handleModal(false));
        }
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpgradeSet((event.target as HTMLInputElement).value);
    if (event.target.value === 'upgradeSetOne') {
      getUpgradeUnits({ variables: { filter: { project: project._id, upgradesSetOne: true } } });
    } else {
      getUpgradeUnits({ variables: { filter: { project: project._id, upgradesSetTwo: true } } });
    }
  };

  const downloadSupplementalDoc = () => {
    if (selectedDocument) {
      const element = document.createElement('a');
      element.href = selectedDocument?.url!;
      element.target = '_blank';
      element.click();
    } else return storeDispatch(showErrorSnackbar('No Document Selected'));
  };

  return (
    <Box>
      {type === 'condoDocs' ? (
        <Box>
          <Typography variant={'h5'} sx={{ mb: 2 }}>
            Supplemental Documents/Condo Docs
          </Typography>
          <Autocomplete
            options={supplementalDocs!}
            getOptionLabel={(option: any) => option.name}
            disableClearable={false}
            freeSolo={false}
            onChange={(event: any, newValue: any | null) => {
              if (newValue) {
                setSelectedDocument(newValue);
              }
            }}
            renderInput={(params) => <TextField {...params} label="Documents" size="small" />}
          />
          <FlexBetween sx={{ mt: 2 }}>
            <Button onClick={() => storeDispatch(handleModal(false))} variant="contained" color="info">
              Cancel
            </Button>
            <Button sx={{ mr: 1 }} onClick={() => downloadSupplementalDoc()} variant="contained" color="success">
              Download
            </Button>
          </FlexBetween>
        </Box>
      ) : (
        <Box>
          <Typography variant={'h5'}>{capitalizeFirstLetter(type)}</Typography>
          <p>Select Suites to Download</p>
          <Box sx={{ my: 2 }}>
            <Autocomplete
              options={suites}
              getOptionLabel={(option: any) => option.unit.suite}
              disableClearable={false}
              freeSolo={false}
              onChange={(event: any, newValue: any | null) => {
                if (newValue) {
                  setSelectedSuites([...selectedSuites, newValue]);
                }
              }}
              renderInput={(params) => <TextField {...params} label="Suites" size="small" />}
            />
          </Box>
          {type === 'decor' ? (
            <>
              <FormControlLabel
                sx={{ mt: 1 }}
                control={<Checkbox checked={decorNoPrice} onChange={(e: any) => setDecorNoPrice(!decorNoPrice)} />}
                label={'Download with No Price'}
              />
              {decorNoPrice ? (
                <Box>
                  <FormControl>
                    <RadioGroup aria-labelledby="radio-buttons-group" value={upgradeSet} onChange={handleChange} name="radio-buttons-group">
                      <FormControlLabel value="upgradeSetOne" control={<Radio />} label="Upgrade Set One" />
                      <FormControlLabel value="upgradeSetTwo" control={<Radio />} label="Upgrade Set Two" />
                    </RadioGroup>
                  </FormControl>
                </Box>
              ) : null}
            </>
          ) : null}
          <p>
            <strong>Selected Suites</strong>
          </p>
          <Flex sx={{ my: 2, flexWrap: 'wrap' }}>
            {selectedSuites.map((column: any, index: number) => {
              return (
                <Box
                  key={index}
                  sx={{
                    padding: '10px',
                    border: '1px solid #00142a',
                    borderRadius: '8px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    marginRight: '10px',
                    mt: 1,
                  }}
                >
                  <span>
                    {index + 1}. {column.unit.suite}
                  </span>
                  {column.Header !== 'Suite' ? (
                    <CloseIcon
                      sx={{ cursor: 'pointer', ml: 1 }}
                      color="secondary"
                      onClick={() => setSelectedSuites(selectedSuites.filter((columns: any, numIndex: number) => numIndex !== index))}
                    />
                  ) : null}
                </Box>
              );
            })}
          </Flex>
          <FlexBetween>
            <Button onClick={() => storeDispatch(handleModal(false))} variant="contained" color="info">
              Cancel
            </Button>
            <Box>
              <Button
                disabled={!selectedSuites.length}
                sx={{ mr: 1 }}
                onClick={() => getDocuments(false)}
                variant="contained"
                color="success"
              >
                Download Selected
              </Button>
              <Button sx={{ mr: 1 }} onClick={() => getDocuments(true)} variant="contained" color="success">
                Download All
              </Button>
            </Box>
          </FlexBetween>
        </Box>
      )}
    </Box>
  );
};

interface ChildProps {
  suites: any[];
  selectedSuites: any[];
  setSelectedSuites: any;
  type: string;
  setType: Dispatch<SetStateAction<string>>;
  decorNoPrice: boolean;
  setDecorNoPrice: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  supplementalDocs?: ISupplementalDocs[];
}

const RESOURCES = gql`
  query getResources($project: MongoID!, $deals: [MongoID!], $type: String!) {
    getResources(project: $project, deals: $deals, type: $type)
  }
`;

const UPGRADEUNITS = gql`
  query unitMany($filter: FilterFindManyUnitInput) {
    unitMany(filter: $filter, limit: 10000) {
      suite
      project {
        _id
        name
      }
      getUrl
      modelType
      upgradeSetOne {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
      upgradeSetTwo {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
      upgradeSetThree {
        date
        salesRep {
          _id
          fullName
        }
        collectionTemplate {
          _id
          name
          upgradeTemplates {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            schemes
            unitsAllowed
            modelsAllowed
            sessions
            freeUpgrades
            upgradeType
          }
          unitsAllowed
          modelsAllowed
        }
        upgradeSet {
          _id
          name
          price
          mainCategory
          subCategory
          quantity
          upgradeTemplate {
            _id
            name
            price
            mainCategory
            subCategory
            lock
            hide
            unitsAllowed
            modelsAllowed
            freeUpgrades
            upgradeType
            getUrl
          }
        }
      }
    }
  }
`;

export default ResourceModal;
