import { useContext, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  Autocomplete,
  Typography,
} from '@mui/material';
import { UnitContext } from '../../context/UnitContext';
import { IDealAdjustment } from '../../types/CreateDealForm';
import { adjustmentTypes, adjustmentNameTypes } from '../../utils/Constants';
import { camelToTitle, netHST } from '../../utils/Functions';
import { Container, FlexEnd } from '../../commonStyles';
import { showSuccessSnackbar } from '../../features/snackbar/snackbarSlice';
import { useAppDispatch } from '../../app/hooks';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/auth/authSlice';
import { selectProject } from '../../features/project/projectSlice';
import { useCreateActivity } from '../../features/activity/activityHooks';
import { IDeal } from '../../types/CreateDealForm';
import { IProjectAdjustment } from '../../types/project';

const Adjustments = () => {
  const storeDispatch = useAppDispatch();
  const createActivity = useCreateActivity();
  const { filteredDeal, unit, setFilteredDeal } = useContext(UnitContext);
  const user = useSelector(selectUser);
  const project = useSelector(selectProject);
  const [uniqueAdjustments, setUniqueAdjustments] = useState<string[]>([]);
  const [unitAdjustments, setUnitAdjustments] = useState([]);
  const [freeMaintenanceValue, setFreeMaintenanceValue] = useState<string>('');

  const [updateAdjustments] = useMutation(UPDATEADJUSTMENTS, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar('Adjustments have been saved'));
      createActivity({
        project: project._id,
        user: user._id,
        deal: filteredDeal._id,
        title: `Adjustment Updated`,
        content: `Adjustment has been updated`,
      });
    },
    onError: (err) => {
      console.log(err, 'err');
    },
  });

  useQuery(GETDEALS, {
    variables: {
      filter: {
        AND: [{ cancelled: { dateCancelled: null } }, { draft: false }, { rescission: { dateRescinded: null } }],
        project: project._id,
      },
    },
    onCompleted: (data) => {
      let adjustments = [
        ...new Set([
          ...new Set(data.dealMany.flatMap((obj: IDeal) => obj.adjustments.map((o) => o.name))),
          ...project?.adjustments.map((adjustment: any) => adjustment.name),
        ]),
      ];
      // Handle Unit Adjustments
      let unitAdjustments: any = project.adjustments.map((adjustment: IProjectAdjustment) => {
        const conditionType = (conditionField: string) => {
          switch (conditionField) {
            case 'unitTypes':
              if (adjustment.conditions.length) {
                let value = null;
                for (const condition of adjustment.conditions) {
                  if (condition.condition === 'Includes') {
                    if (condition.values.includes(unit.unitType)) {
                      value = condition.amount;
                      break;
                    }
                  }
                }
                return value;
              } else return null;
            case 'modelTypes':
              if (adjustment.conditions.length) {
                let value = null;
                for (const condition of adjustment.conditions) {
                  if (condition.condition === 'Includes') {
                    if (condition.values.includes(unit.modelType)) {
                      value = condition.amount;
                      break;
                    }
                  }
                }
                return value;
              } else return null;
            case 'netHstBasePrice':
              if (adjustment.conditions.length) {
                let value = null;
                for (const condition of adjustment.conditions) {
                  if (condition.condition === 'Less Than') {
                    if (netHST(unit.basePrice) < condition.value) {
                      value = condition.amount;
                      break;
                    }
                  } else if (condition.condition === 'Greater Than') {
                    if (netHST(unit.basePrice) > condition.value) {
                      value = condition.amount;
                      break;
                    }
                  }
                }
                return value;
              } else return null;
            case 'size':
              if (adjustment.conditions.length) {
                let value = null;
                for (const condition of adjustment.conditions) {
                  if (unit.size > condition.value) {
                    value = condition.amount;
                    break;
                  }
                }
                return value;
              } else return null;
            default:
              return 'N/A';
          }
        };

        if (conditionType(adjustment.conditionField) === null) {
          return null;
        } else {
          return {
            name: adjustment.name,
            type: adjustment.type,
            value: conditionType(adjustment.conditionField),
            description: '',
            approval: 'Unit Default',
          };
        }
      });

      setUniqueAdjustments(adjustments);
      setUnitAdjustments(unitAdjustments.filter((adjustment: any) => adjustment !== null));
    },
  });

  const handleAdjustments = (event: any, numIndex: number) => {
    let value = event.target.value;
    if (event.target.name === 'value' || event.target.name === 'percentage') {
      value = parseFloat(event.target.value);
    }
    let newAdjustments = filteredDeal.adjustments.map((adjustment: any, index: number) => {
      if (index === numIndex) {
        return {
          ...adjustment,
          [event.target.name]: value,
        };
      } else return adjustment;
    });

    setFilteredDeal({
      ...filteredDeal,
      adjustments: newAdjustments,
    });
  };

  const handleMaintenanceValue = (event: any, numIndex: number) => {
    if (unit.maintenanceFee) {
      let value = event.target.value;
      let newAdjustments = filteredDeal.adjustments.map((adjustment: any, index: number) => {
        if (index === numIndex) {
          return {
            ...adjustment,
            value: unit.maintenanceFee * parseInt(value, 10),
          };
        } else return adjustment;
      });

      setFilteredDeal({
        ...filteredDeal,
        adjustments: newAdjustments,
      });
    }
  };

  const handleAdjustmentName = (event: any, values: any, numIndex: number) => {
    if (event) {
      let newAdjustments = filteredDeal.adjustments.map((adjustment: any, index: number) => {
        if (index === numIndex) {
          return {
            ...adjustment,
            name: values,
          };
        } else return adjustment;
      });

      setFilteredDeal({
        ...filteredDeal,
        adjustments: newAdjustments,
      });
    }
  };

  const handleAdjustmentText = (event: any, value: any, numIndex: number) => {
    if (event) {
      let newAdjustments = filteredDeal.adjustments.map((adjustment: any, index: number) => {
        if (index === numIndex) {
          return {
            ...adjustment,
            name: value,
          };
        } else return adjustment;
      });

      setFilteredDeal({
        ...filteredDeal,
        adjustments: newAdjustments,
      });
    }
  };

  const addAdjustment = () => {
    setFilteredDeal({
      ...filteredDeal,
      adjustments: [
        ...filteredDeal.adjustments,
        {
          name: '',
          type: '',
          adjustmentType: '',
          value: '',
          description: '',
          approval: '',
        },
      ],
    });
  };

  const removeAdjustment = (numIndex: number) => {
    setFilteredDeal({
      ...filteredDeal,
      adjustments: filteredDeal.adjustments.filter((adjustment: IDealAdjustment, index: number) => numIndex !== index),
    });
  };

  const saveAdjustments = (e: any) => {
    e.preventDefault();
    updateAdjustments({ variables: { _id: filteredDeal._id, record: { adjustments: filteredDeal.adjustments } } });
  };

  return (
    <div>
      <h2>Adjustments</h2>
      <Container sx={{ p: 2 }}>
        <Typography variant="h3">
          <strong>Unit Adjustments (Cannot be edited)</strong>
          {unitAdjustments.length ? (
            unitAdjustments.map((adjustment: IDealAdjustment, index: number) => {
              return (
                <Grid key={index} container spacing={3} sx={{ mt: 1 }}>
                  <Grid item xs={12} md={3} sm={4}>
                    <TextField
                      title={'Name'}
                      label="Name"
                      name={'name'}
                      disabled
                      value={adjustment.name}
                      placeholder={'Name'}
                      sx={{
                        width: '100%',
                        opacity: 0.7,
                        textDecoration:
                          filteredDeal &&
                          filteredDeal.adjustments.some((dealAdjustment: IDealAdjustment) => adjustment.name === dealAdjustment.name)
                            ? 'line-through'
                            : '',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={adjustment.type === 'ChargePerDeposit' ? 1.5 : 3} sm={4}>
                    <TextField
                      title={'Value'}
                      name={'value'}
                      label="Value"
                      type="number"
                      disabled
                      value={adjustment.value}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      required={true}
                      placeholder={'Amount'}
                      sx={{
                        width: '100%',
                        opacity: 0.7,
                        input: {
                          color: adjustment.type === 'Charge' ? 'green' : adjustment.type === 'Credit' ? 'red' : '#000',
                        },
                        textDecoration:
                          filteredDeal &&
                          filteredDeal.adjustments.some((dealAdjustment: IDealAdjustment) => adjustment.name === dealAdjustment.name)
                            ? 'line-through'
                            : '',
                      }}
                    />
                  </Grid>
                  {adjustment.type === 'ChargePerDeposit' ? (
                    <Grid item xs={12} sm={adjustment.type === 'ChargePerDeposit' ? 1.5 : 4}>
                      <TextField
                        title={'Total Value'}
                        name={'totalValue'}
                        label="Total Value"
                        type="number"
                        disabled
                        value={
                          filteredDeal && filteredDeal.deposit.length && adjustment.type === 'ChargePerDeposit'
                            ? adjustment.value * filteredDeal.deposit.length
                            : adjustment.value
                        }
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required={true}
                        placeholder={'Amount'}
                        sx={{
                          width: '100%',
                          opacity: 0.7,
                          input: {
                            color: 'green',
                          },
                          textDecoration:
                            filteredDeal &&
                            filteredDeal.adjustments.some((dealAdjustment: IDealAdjustment) => adjustment.name === dealAdjustment.name)
                              ? 'line-through'
                              : '',
                        }}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={2} sm={4}>
                    <TextField
                      title={'Credit or Charge'}
                      label="Credit or Charge"
                      name={'type'}
                      disabled
                      value={camelToTitle(adjustment.type)}
                      placeholder={'Description'}
                      sx={{
                        width: '100%',
                        opacity: 0.7,
                        textDecoration:
                          filteredDeal &&
                          filteredDeal.adjustments.some((dealAdjustment: IDealAdjustment) => adjustment.name === dealAdjustment.name)
                            ? 'line-through'
                            : '',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} sm={4}>
                    <TextField
                      title={'Description'}
                      label="Description"
                      name={'description'}
                      disabled
                      value={adjustment.description}
                      placeholder={'Description'}
                      sx={{
                        width: '100%',
                        opacity: 0.7,
                        textDecoration:
                          filteredDeal &&
                          filteredDeal.adjustments.some((dealAdjustment: IDealAdjustment) => adjustment.name === dealAdjustment.name)
                            ? 'line-through'
                            : '',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} sm={4}>
                    <TextField
                      title={'Approval'}
                      label="Approval"
                      name={'approval'}
                      disabled
                      value={adjustment.approval}
                      required={true}
                      placeholder={'Adjustment Approval'}
                      sx={{
                        width: '100%',
                        opacity: 0.7,
                        textDecoration:
                          filteredDeal &&
                          filteredDeal.adjustments.some((dealAdjustment: IDealAdjustment) => adjustment.name === dealAdjustment.name)
                            ? 'line-through'
                            : '',
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <Box sx={{ mt: 2 }}>There are currently no adjustments for this unit.</Box>
          )}
        </Typography>
      </Container>
      <Container sx={{ p: 2, mt: 2 }}>
        <Typography variant="h3">
          <strong>Deal Adjustments</strong>
        </Typography>
        {filteredDeal ? (
          <form onSubmit={saveAdjustments}>
            {filteredDeal.adjustments.length ? (
              filteredDeal.adjustments.map((adjustment: IDealAdjustment, index: number) => {
                return (
                  <Grid key={index} container spacing={3} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        id={`${index}`}
                        sx={{ height: '100%' }}
                        options={uniqueAdjustments}
                        getOptionLabel={(option: any) => option}
                        disableClearable={false}
                        freeSolo={true}
                        onInputChange={(e, value, reason) => {
                          handleAdjustmentName(e, value, index);
                        }}
                        value={adjustment.name}
                        onChange={(e, value, reason) => {
                          handleAdjustmentText(e, value, index);
                        }}
                        renderInput={(params) => (
                          <TextField
                            name="name"
                            sx={{
                              '& .MuiInputLabel-shrink': { top: '0 !important' },
                              '& .MuiInputLabel-root': { top: '8px' },
                              '& .MuiOutlinedInput-root': { height: '100%' },
                              height: '100%',
                            }}
                            {...params}
                            label="Name"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        title={'%'}
                        name={'percentage'}
                        label="Percentage"
                        onChange={(e: any) => handleAdjustments(e, index)}
                        value={adjustment.percentage}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        placeholder={'Amount'}
                        sx={{
                          width: '100%',
                          input: {
                            color: adjustment.type === 'Charge' ? 'green' : adjustment.type === 'Credit' ? 'red' : '#000',
                          },
                        }}
                      />
                    </Grid>
                    {adjustment.adjustmentType === 'FreeMaintenance' && filteredDeal ? (
                      <Grid item xs={12} sm={2}>
                        <TextField
                          title={'Number of Months'}
                          name={'freeMaintenanceValue'}
                          label="Number of Months"
                          type="number"
                          fullWidth
                          value={freeMaintenanceValue}
                          onChange={(e: any) => {
                            setFreeMaintenanceValue(e.target.value);
                            handleMaintenanceValue(e, index);
                          }}
                          placeholder={'Amount'}
                        />
                      </Grid>
                    ) : null}
                    <Grid
                      item
                      xs={12}
                      sm={adjustment.type === 'ChargePerDeposit' || adjustment.adjustmentType === 'FreeMaintenance' ? 2 : 4}
                    >
                      <TextField
                        title={'Value'}
                        name={'value'}
                        label="Value"
                        type="number"
                        onChange={(e: any) => handleAdjustments(e, index)}
                        value={adjustment.value}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required={true}
                        placeholder={'Amount'}
                        sx={{
                          width: '100%',
                          input: {
                            color: adjustment.type === 'Charge' ? 'green' : adjustment.type === 'Credit' ? 'red' : '#000',
                          },
                        }}
                      />
                    </Grid>
                    {adjustment.type === 'ChargePerDeposit' ? (
                      <Grid item xs={12} sm={2}>
                        <TextField
                          title={'Total Value'}
                          name={'totalValue'}
                          label="Total Value"
                          type="number"
                          disabled
                          value={
                            filteredDeal && filteredDeal.deposit.length && adjustment.type === 'ChargePerDeposit'
                              ? adjustment.value * filteredDeal.deposit.length
                              : adjustment.value
                          }
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          required={true}
                          placeholder={'Amount'}
                          sx={{
                            width: '100%',
                            opacity: 0.4,
                            input: {
                              color: 'green',
                            },
                          }}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <InputLabel id="id-display-label">Credit or Charge</InputLabel>
                        <Select
                          required
                          value={adjustment.type}
                          label="Credit or Charge"
                          name="type"
                          onChange={(e: any) => handleAdjustments(e, index)}
                        >
                          {adjustmentTypes.map((display: string, index: number) => {
                            return (
                              <MenuItem key={index} value={display}>
                                {camelToTitle(display)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <InputLabel id="id-display-label">Adjustment Type</InputLabel>
                        <Select
                          value={adjustment.adjustmentType}
                          label="Adjustment Type"
                          name="adjustmentType"
                          onChange={(e: any) => handleAdjustments(e, index)}
                        >
                          {adjustmentNameTypes.map((display: string, index: number) => {
                            return (
                              <MenuItem key={index} value={display}>
                                {camelToTitle(display)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        title={'Description'}
                        label="Description"
                        name={'description'}
                        onChange={(e: any) => handleAdjustments(e, index)}
                        value={adjustment.description}
                        placeholder={'Description'}
                        sx={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        title={'Approval'}
                        label="Approval"
                        name={'approval'}
                        onChange={(e: any) => handleAdjustments(e, index)}
                        value={adjustment.approval}
                        required={true}
                        placeholder={'Adjustment Approval'}
                        sx={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                      <Button onClick={() => removeAdjustment(index)} variant="contained" color="error">
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Box sx={{ mt: 2 }}>There are currently no adjustments for this deal.</Box>
            )}
            <FlexEnd sx={{ mt: 2 }}>
              <Button onClick={() => addAdjustment()} variant="contained" color="primary">
                Add Adjustment
              </Button>
              <Button sx={{ ml: 1 }} type="submit" variant="contained" color="success">
                Save Adjustments
              </Button>
            </FlexEnd>
          </form>
        ) : (
          <Box sx={{ mt: 2 }}>There is currently no deal for this unit.</Box>
        )}
      </Container>
    </div>
  );
};

const UPDATEADJUSTMENTS = gql`
  mutation dealUpdateById($_id: MongoID!, $record: UpdateByIdDealInput!) {
    dealUpdateById(_id: $_id, record: $record) {
      record {
        realtor {
          _id
        }
      }
    }
  }
`;

const GETDEALS = gql`
  query dealMany($filter: FilterFindManyDealInput) {
    dealMany(filter: $filter, limit: 10000) {
      adjustments {
        name
      }
    }
  }
`;

export default Adjustments;
